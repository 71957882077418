import { makeStyles } from '@material-ui/core';
import {
	black007,
	border,
	fontGrey,
	fontGrey10,
	fontGrey75,
	FOOTER_HEIGHT,
	mainRed,
	mainRed50,
	MAP_DRAWER_WIDTH,
	NAVBAR_HEIGHT,
	poppins,
	roboto,
	SUBHEADER_HEIGHT,
	white
} from '../../styles/variables';

export const useStyles = makeStyles(() => ({
	drawer: {
		width: MAP_DRAWER_WIDTH,
		flexShrink: 0
	},
	drawerPaper: {
		width: MAP_DRAWER_WIDTH,
		height: `calc(100vh - ${FOOTER_HEIGHT} - ${NAVBAR_HEIGHT} - ${SUBHEADER_HEIGHT})`,
		marginTop: `calc(${NAVBAR_HEIGHT} + ${SUBHEADER_HEIGHT})`
	},
	drawerContainer: {
		overflow: 'auto'
	},
	tabs: {
		backgroundColor: mainRed
	},
	tab: {
		fontWeight: 600,
		textTransform: 'capitalize',
		fontFamily: poppins,
		width: '40%',
		minWidth: '40%',
		letterSpacing: '0.52px',
		color: white
	},
	tabIndicator: {
		display: 'flex',
		justifyContent: 'center',
		width: '20%'
	},
	searchInput: {
		margin: 0,
		backgroundColor: black007,
		color: fontGrey75,
		fontFamily: roboto,
		letterSpacing: '0.45px'
	},
	select: {
		width: '20rem',
		marginLeft: '8rem',
		marginTop: '0.5rem',
		marginBottom: '0.5rem'
	},
	input: {
		padding: '0 2.5rem 0 8rem',
		height: '4rem'
	},
	listItem: {
		padding: '0 2.5rem 0 8rem',
		backgroundColor: white,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		borderBottom: border(fontGrey10),
		'&.Mui-selected': {
			backgroundColor: white,
			boxShadow: `5px 0 20px 0 ${black007}`,
			fontFamily: poppins,
			fontSize: '1.25rem',
			fontWeight: 'bold',
			letterSpacing: '0.6px',
			zIndex: 1
		}
	},
	trackItemSelected: {
		height: '37rem'
	},
	listItemText: {
		height: '4rem',
		display: 'flex',
		alignItems: 'center',
		marginBottom: 0,
		marginTop: 0,
		fontFamily: poppins,
		fontSize: '1.2rem',
		color: fontGrey,
		fontWeight: 500,
		letterSpacing: '0.53px'
	},
	listItemSelected: {
		fontFamily: poppins,
		fontSize: '1.25rem',
		fontWeight: 'bold',
		letterSpacing: '0.6px',
		marginLeft: '-2rem'
	},
	// selecteditem: {
	// 	boxShadow: `5px 0 20px 0 ${black007}`,
	// 	backgroundColor: white,
	// 	fontFamily: poppins,
	// 	fontSize: '1.25rem',
	// 	fontWeight: 600,
	// 	letterSpacing: '0.6px'
	// },
	selectedIcon: {
		color: mainRed,
		marginRight: '0.5rem'
	},
	status: {
		fontFamily: roboto,
		fontSize: '1rem',
		letterSpacing: '0.45px',
		color: fontGrey75,
		fontWeight: 'normal',
		marginTop: '0.7rem',
		marginBottom: '0.7rem'
	},
	ageGroup: {
		fontFamily: roboto,
		fontSize: '1rem',
		letterSpacing: '0.45px',
		color: fontGrey,
		fontWeight: 'normal'
	},
	statusFinished: {
		fontFamily: roboto,
		fontSize: '1rem',
		letterSpacing: '0.45px',
		color: mainRed50,
		fontWeight: 'normal',
		marginTop: '1rem',
		marginBottom: '1rem'
	},
	progressBar: {
		width: '100%',
		height: '0.5rem',
		borderRadius: '4px',
		marginBottom: '1.5rem'
	},
	tablabel: {
		display: 'flex',
		alignItems: 'center'
	},
	tabIcon: {
		height: '1.8rem',
		marginRight: '0.3rem'
	}
}));
