import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import { Button, makeStyles } from '@material-ui/core';
import produce from 'immer';
import Card, { CARD_SIZE, SPACE_BETWEEN_MEMORY_CARDS } from './Card';
import { IMemoryGameData, IMemoryPair } from '../../../../constants/types';
import { shuffle } from '../utils';
import { mainRed, mainRed50, poppins, white } from '../../../../styles/variables';

const useStyles = makeStyles(() => ({
	container: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '1rem',
		flexDirection: 'column'
	},
	root: {
		width: (props: { cardsInRow: number }) =>
			`calc(${props.cardsInRow} * ${CARD_SIZE} + ${
				props.cardsInRow - 1
			} * ${SPACE_BETWEEN_MEMORY_CARDS})`,
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		perspective: '1000px'
	},
	button: {
		marginTop: '2rem',
		width: '21rem',
		height: '3rem',
		fontFamily: poppins,
		fontWeight: 600,
		letterSpacing: '0.45px',
		color: white,
		backgroundColor: mainRed,
		'&:hover': {
			backgroundColor: mainRed50,
			color: white
		}
	}
}));

export interface IMemoryCard {
	value: string;
	id: number;
	closed: boolean;
	complete: boolean;
	index: number;
}

interface IProps {
	gameData: IMemoryGameData;
	gameId: number;
	finishGame: (gameId: number, points: number) => void;
}

const Memory: FunctionComponent<IProps> = ({ gameData, gameId, finishGame }): ReactElement => {
	const cardsInRow = useMemo(() => {
		switch (gameData.number_of_pairs) {
			case 6:
				return 4;
			case 12:
				return 6;
			case 24:
				return 8;
			default:
				return 4;
		}
	}, [gameData.number_of_pairs]);
	const classes = useStyles({ cardsInRow });
	const [cards, setCards] = useState<IMemoryCard[]>([]);
	const [movesCount, setMovesCount] = useState<number>(0);

	// double cards, randomize, assign to cards state
	useEffect(() => {
		setCards(
			(shuffle(gameData.pairs) as IMemoryPair[]).map((item: IMemoryPair, index: number) => {
				return {
					value: item.value,
					id: item.id,
					closed: true,
					complete: false,
					index
				};
			})
		);
	}, [gameData]);

	const handleClick = useCallback((card: IMemoryCard, index: number) => {
		setCards((prevState: IMemoryCard[]) =>
			produce(prevState, draft => {
				draft[index].closed = false;
				return draft;
			})
		);
	}, []);

	useEffect(() => {
		const openedCards = cards.filter((c: IMemoryCard) => !c.closed && !c.complete);
		if (openedCards.length === 2) {
			setMovesCount((prevState: number) => prevState + 1);
			setTimeout(() => {
				if (
					openedCards[0].id === openedCards[1].id &&
					openedCards[0].index !== openedCards[1].index
				) {
					setCards((prevState: IMemoryCard[]) =>
						produce(prevState, draft => {
							draft[openedCards[0].index].complete = true;
							draft[openedCards[1].index].complete = true;
							return draft;
						})
					);
				} else {
					setCards((prevState: IMemoryCard[]) =>
						produce(prevState, draft => {
							draft[openedCards[0].index].closed = true;
							draft[openedCards[1].index].closed = true;
							return draft;
						})
					);
				}
			}, 750);
		}
	}, [cards]);

	const handleGameFinish = useCallback(() => {
		const points = gameData.number_of_pairs * 3 - movesCount;
		finishGame(gameId, points < 0 ? 0 : points);
	}, [finishGame, gameData.number_of_pairs, gameId, movesCount]);

	return (
		<div className={classes.container}>
			<div className={classes.root}>
				{cards.map((card, index) => {
					return (
						<Card
							key={index}
							card={card}
							onClick={() => {
								handleClick(card, index);
							}}
						/>
					);
				})}
			</div>
			{cards.length > 0 && cards.every((card: IMemoryCard) => card.complete) && (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={handleGameFinish}
				>
					Dalej
				</Button>
			)}
		</div>
	);
};

export default memo(Memory);
