import { createContext, Dispatch, SetStateAction } from 'react';

export const UserContext = createContext({} as IUserProvider);

export interface IUser {
	age_group: string;
	authentication_token: string;
	country: string;
	created_at: string;
	email: string;
	id: number;
	nickname: string;
	profile_completed: true;
	updated_at: string;
	role: USER_ROLE;
}

export enum USER_ROLE {
	STUDENT = 'student',
	TEACHER = 'teacher'
}

export interface IUserProvider {
	user: IUser | undefined;
	setUser: Dispatch<SetStateAction<IUser | undefined>>;
}
