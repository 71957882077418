import { useCallback, useContext } from 'react';
import { getTrack, getWaypoint } from '../api';
import { AxiosResponse } from 'axios';
import { ITrack, IWayPointFull } from '../constants/types';
import { SelectionContext } from '../context/SelectionContext';

const useUpdate = (): { updateAll: () => void } => {
	const { selection, selectTrack, selectWaypoint } = useContext(SelectionContext);

	const updateCurrentTrack = useCallback(() => {
		if (selection.selectedTrack) {
			getTrack({ params: { id: selection.selectedTrack.id } })
				.then((resp: AxiosResponse<ITrack>) => {
					selectTrack(resp.data);
				})
				.catch(e => {
					console.log('update track error');
				});
		}
	}, [selectTrack, selection.selectedTrack]);

	const updateCurrentWaypoint = useCallback(() => {
		if (selection.selectedWaypoint) {
			getWaypoint({ params: { id: selection.selectedWaypoint.id } })
				.then((resp: AxiosResponse<IWayPointFull>) => {
					selectWaypoint(resp.data);
				})
				.catch(e => {
					console.log('update waypoint error');
				});
		}
	}, [selectWaypoint, selection.selectedWaypoint]);

	const updateAll = useCallback(() => {
		updateCurrentTrack();
		updateCurrentWaypoint();
	}, [updateCurrentTrack, updateCurrentWaypoint]);

	return { updateAll };
};

export default useUpdate;
