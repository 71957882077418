import {
	GAME,
	IConnectingGameData,
	IEmptyGameData,
	IGame,
	IGroupsGameData,
	IHotOrNotGameData,
	IImageFillGameData,
	IMemoryGameData,
	IOrderingGameData,
	IQuizGameData,
	ITextFillGameData,
	IWordcloudGameData
} from '../../../constants/types';
import {
	Empty,
	Groups,
	HotOrNot,
	ImageFill,
	Memory,
	Order,
	Pairs,
	Quiz,
	TextFill,
	Wordcloud
} from './index';
import React from 'react';

export const renderGame = (game: IGame, finishGame: (gameId: number, points: number) => void) => {
	switch (game.game_type) {
		case GAME.MEMORY:
			return (
				<Memory
					gameData={game.game_data as IMemoryGameData}
					gameId={game.id}
					finishGame={finishGame}
				/>
			);
		case GAME.ORDER:
			return (
				<Order
					gameData={game.game_data as IOrderingGameData}
					gameId={game.id}
					finishGame={finishGame}
				/>
			);
		case GAME.QUIZ:
			return (
				<Quiz
					gameData={game.game_data as IQuizGameData}
					gameId={game.id}
					finishGame={finishGame}
				/>
			);
		case GAME.HOTORNOT:
			return (
				<HotOrNot
					gameData={game.game_data as IHotOrNotGameData}
					gameId={game.id}
					finishGame={finishGame}
				/>
			);
		case GAME.COLLECTIONS:
			return (
				<Groups
					gameData={game.game_data as IGroupsGameData}
					gameId={game.id}
					finishGame={finishGame}
				/>
			);
		case GAME.CONNECTING:
			return (
				<Pairs
					gameData={game.game_data as IConnectingGameData}
					gameId={game.id}
					finishGame={finishGame}
				/>
			);
		case GAME.IMGFILL:
			return (
				<ImageFill
					gameData={game.game_data as IImageFillGameData}
					gameId={game.id}
					finishGame={finishGame}
				/>
			);
		case GAME.TEXTFILL:
			return (
				<TextFill
					gameData={game.game_data as ITextFillGameData}
					gameId={game.id}
					finishGame={finishGame}
				/>
			);
		case GAME.WORDCLOUD:
			return (
				<Wordcloud
					gameData={game.game_data as IWordcloudGameData}
					gameId={game.id}
					finishGame={finishGame}
				/>
			);
		case GAME.EMPTY:
			return (
				<Empty
					gameData={game.game_data as IEmptyGameData}
					gameId={game.id}
					finishGame={finishGame}
				/>
			);
	}
};
