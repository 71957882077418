import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { SelectionContext } from '../../context/SelectionContext';
import TrackDrawer from './TrackDrawer';
import { makeStyles } from '@material-ui/core';
import {
	CITY_DRAWER_HEIGHT,
	FOOTER_HEIGHT,
	NAVBAR_HEIGHT,
	poppins,
	SUBHEADER_HEIGHT,
	white
} from '../../styles/variables';
import { ROUTES } from '../../routes/routes';
import { Link, useHistory } from 'react-router-dom';
import usePortal from 'react-cool-portal';
import TrackCityTooltip, { TOOLTIP_HEIGHT } from './TrackCityTooltip';
import useSessionValidation from '../../hooks/useSessionValidation';
import Loader from '../shared/Loader';
import { getWaypoint, submitScore } from '../../api';
import { AxiosResponse } from 'axios';
import { GAME, IPoi, IWayPoint, IWayPointFull } from '../../constants/types';
import { ArrowBack } from '@material-ui/icons';
import TrackProgress from '../shared/TrackProgress';
import { CITY_NAME } from '../../constants/cities';
import useUpdate from '../../hooks/useUpdate';
import { SubheaderContext } from '../../context/SubheaderContext';
import NewCityDialog from '../game/NewCityDialog';
import TrackFinishedDialog from '../game/TrackFinishedDialog';

const useStyles = makeStyles(() => ({
	root: {
		maxWidth: 'calc(100vw)',
		maxHeight: `calc(100vh - ${FOOTER_HEIGHT} - ${CITY_DRAWER_HEIGHT} - ${SUBHEADER_HEIGHT} - ${NAVBAR_HEIGHT})`,
		position: 'relative',
		top: 0,
		display: 'flex',
		flexDirection: 'column'
	},
	title: {
		textTransform: 'uppercase',
		fontWeight: 'bold',
		fontSize: '2rem',
		letterSpacing: '0.9px'
	},
	imageback: {
		width: 'calc(100vw)',
		maxHeight: `calc(100vh - ${FOOTER_HEIGHT} - ${CITY_DRAWER_HEIGHT} - ${SUBHEADER_HEIGHT} - ${NAVBAR_HEIGHT})`,
		position: 'relative',
		top: 0,
		left: 0
	},
	imagefront: {
		width: 'calc(100vw)',
		maxHeight: `calc(100vh - ${FOOTER_HEIGHT} - ${CITY_DRAWER_HEIGHT} - ${SUBHEADER_HEIGHT} - ${NAVBAR_HEIGHT})`,
		position: 'absolute',
		top: 0,
		left: 0
	},
	backButton: {
		fontFamily: poppins,
		fontWeight: 500,
		textTransform: 'unset',
		letterSpacing: '0.45px',
		color: white
	}
}));

const TOP_OFFSET = 232;
const LEFT_OFFSET = 272;

const BOTTOM_TOP_OFFSET = -34;
const BOTTOM_LEFT_OFFSET = 270;

const Track: FunctionComponent = (): ReactElement => {
	const { loading } = useSessionValidation();
	const [dataLoading, setIsDataLoading] = useState<boolean>(true);
	const { selection, selectWaypoint, selectPoi, unselectPoi } = useContext(SelectionContext);
	const [newCityDialog, setNewCityDialog] = useState<{ visible: boolean; cityName?: string }>({
		visible: false
	});
	const [trackFinishedDialog, setTrackFinishedDialog] = useState<{
		visible: boolean;
		track_completed_data?: {
			name: string;
			badge_image_url: string;
			cities_names: string[];
			user_score: number;
		};
	}>({
		visible: false
	});
	const { updateAll } = useUpdate();
	const history = useHistory();
	const [tooltipCoords, setTooltipCoords] = useState<{ top: number; left: number }>({
		top: 0,
		left: 0
	});
	const [tooltipToBottom, setTooltipToBottom] = useState<boolean>(false);
	const { setSubheader } = useContext(SubheaderContext);

	useEffect(() => {
		updateAll();
	}, []);

	const finishEmptyGame = (gameId: number) => {
		submitScore({ params: { game_id: gameId, points: 0 } })
			.then(
				(
					resp: AxiosResponse<{
						id: number;
						points: number;
						new_waypoint_unlocked: boolean;
						unlocked_waypoint?: IWayPointFull;
						track_completed: boolean;
						track_completed_data?: {
							name: string;
							badge_image_url: string;
							cities_names: string[];
							user_score: number;
						};
					}>
				) => {
					if (resp.data.new_waypoint_unlocked) {
						setNewCityDialog({
							visible: true,
							cityName: resp.data.unlocked_waypoint?.city.name
						});
					}
					if (resp.data.track_completed) {
						setTrackFinishedDialog({
							visible: true,
							track_completed_data: resp.data.track_completed_data
						});
					}
				}
			)
			.catch(e => {
				console.log('game error', e);
			});
	};

	const { Portal, show, hide } = usePortal({
		containerId: 'my-tooltip-root',
		defaultShow: false,
		clickOutsideToHide: true,
		escToHide: true,
		onShow: e => {
			if (selection.selectedPoi) {
				const el = document.getElementById(`poi-${selection.selectedPoi.id}`);
				if (el) {
					if (el.getBoundingClientRect().top < TOOLTIP_HEIGHT + 25) {
						setTooltipToBottom(true);
						setTooltipCoords({
							top: el.getBoundingClientRect().top - BOTTOM_TOP_OFFSET,
							left: el.getBoundingClientRect().left - BOTTOM_LEFT_OFFSET
						});
					} else {
						setTooltipToBottom(false);
						setTooltipCoords({
							top: el.getBoundingClientRect().top - TOP_OFFSET,
							left: el.getBoundingClientRect().left - LEFT_OFFSET
						});
					}

					if (
						selection.selectedPoi.game.game_type === GAME.EMPTY &&
						!selection.selectedPoi.game.already_played
					) {
						finishEmptyGame(selection.selectedPoi.game.id);
					}
				}
			}
		},

		onHide: e => {
			if (selection.selectedPoi && selection.selectedPoi.game.game_type === GAME.EMPTY) {
				updateAll();
			}
		}
	});

	useEffect(() => {
		const { track, city } = { track: selection.selectedTrack, city: selection.selectedCity };
		if (track && city) {
			const waypoint = track.waypoints.find(
				(point: IWayPoint) => point.city.name === city.name
			);
			if (waypoint) {
				getWaypoint({ params: { id: waypoint.id } })
					.then((resp: AxiosResponse<IWayPointFull>) => {
						setIsDataLoading(false);
						selectWaypoint(resp.data);
					})
					.catch(e => {
						setIsDataLoading(false);
					});
			} else {
				setIsDataLoading(false);
			}
		} else {
			history.replace(ROUTES.main);
		}
	}, [history, selection.selectedTrack, selection.selectedCity, selectWaypoint]);

	const closeTooltip = useCallback(() => {
		hide();
		unselectPoi();
	}, [hide, unselectPoi]);

	useEffect(() => {
		hide();
		if (selection.selectedPoi) {
			show();
		}
	}, [selection.selectedPoi, show, hide]);

	// cleanup
	// hide on close, but keep selectedCity
	// clear selection when map is opened again
	useEffect(() => {
		closeTooltip();
		return () => {
			hide();
		};
	}, [closeTooltip, hide]);

	const onPointClick = useCallback(
		(e: React.MouseEvent<SVGGElement, MouseEvent>, poi: IPoi) => {
			if (selection.selectedPoi?.id === poi.id) {
				closeTooltip();
			} else {
				selectPoi(poi);
			}
		},
		[closeTooltip, selectPoi, selection.selectedPoi]
	);

	const onDrawerPoiClick = useCallback(
		(poi: IPoi) => {
			if (selection.selectedPoi?.id === poi.id) {
				closeTooltip();
			} else {
				selectPoi(poi);
			}
		},
		[closeTooltip, selectPoi, selection.selectedPoi]
	);

	const renderPoint = useCallback(
		(poi: IPoi) => {
			if (poi.game.already_played) {
				return (
					<g
						stroke="none"
						strokeWidth="1"
						fill="none"
						fillRule="evenodd"
						style={{
							boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.24)',
							cursor: 'pointer'
						}}
						id={`poi-${poi.id}`}
						onClick={e => onPointClick(e, poi)}
						transform={`matrix(1 0 0 1 ${poi.x_pos} ${poi.y_pos})`}
					>
						<circle fill="#FFFFFF" cx="8" cy="8" r="30" />
						<circle fill="#D13337" cx="8" cy="8" r="24" />
						<polyline
							strokeWidth="4"
							stroke="#FFFFFF"
							strokeLinecap="round"
							strokeLinejoin="round"
							transform="matrix(1 0 0 1 -15 -15)"
							points="14.5454545 24.8557743 20.8023353 31.8197006 32.2959506 14.5454545"
						/>
					</g>
				);
			}
			return (
				<g
					onClick={e => onPointClick(e, poi)}
					stroke="none"
					strokeWidth="1"
					fill="none"
					fillRule="evenodd"
					style={{ boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.24)', cursor: 'pointer' }}
					id={`poi-${poi.id}`}
					transform={`matrix(1 0 0 1 ${poi.x_pos} ${poi.y_pos})`}
				>
					<circle fill="#FFFFFF" cx="8" cy="8" r="30" />
					<circle fill="#1D323E" cx="8" cy="8" r="24" />
					<path
						transform="matrix(1 0 0 1 -15 -15)"
						d="M24.9545455,27.5568182 L24.9204545,26.8409091 C24.8977273,26.3181818 25.0909091,25.8181818 25.5,25.3409091 C25.9090909,24.8636364 26.4204545,24.3863636 27.0340909,23.9090909 C27.6477273,23.4318182 28.2670455,22.9204545 28.8920455,22.375 C29.5170455,21.8295455 30.0397727,21.2215909 30.4602273,20.5511364 C30.8806818,19.8806818 31.0909091,19.125 31.0909091,18.2840909 C31.0909091,16.9886364 30.7670455,15.8409091 30.1193182,14.8409091 C29.4715909,13.8409091 28.5909091,13.0568182 27.4772727,12.4886364 C26.3636364,11.9204545 25.125,11.6363636 23.7613636,11.6363636 C22.4431818,11.6363636 21.2159091,11.8977273 20.0795455,12.4204545 C18.9431818,12.9431818 18.0681818,13.5795455 17.4545455,14.3295455 L17.4545455,14.3295455 L17.4545455,18.0454545 C18.2727273,17 19.1306818,16.2102273 20.0284091,15.6761364 C20.9261364,15.1420455 22.0568182,14.875 23.4204545,14.875 C24.7840909,14.875 25.8238636,15.2386364 26.5397727,15.9659091 C27.2556818,16.6931818 27.6136364,17.6136364 27.6136364,18.7272727 C27.6136364,19.3181818 27.4147727,19.875 27.0170455,20.3977273 C26.6193182,20.9204545 26.1193182,21.4261364 25.5170455,21.9147727 C24.9147727,22.4034091 24.3181818,22.8806818 23.7272727,23.3465909 C23.1363636,23.8125 22.6420455,24.2784091 22.2443182,24.7443182 C21.8465909,25.2102273 21.6477273,25.6931818 21.6477273,26.1931818 L21.6477273,26.1931818 L21.6477273,27.5568182 L24.9545455,27.5568182 Z M23.4545455,35.6022727 C24.1136364,35.6022727 24.6761364,35.3636364 25.1420455,34.8863636 C25.6079545,34.4090909 25.8409091,33.8522727 25.8409091,33.2159091 C25.8409091,32.5568182 25.6079545,31.9943182 25.1420455,31.5284091 C24.6761364,31.0625 24.1136364,30.8295455 23.4545455,30.8295455 C22.7954545,30.8295455 22.2329545,31.0625 21.7670455,31.5284091 C21.3011364,31.9943182 21.0681818,32.5568182 21.0681818,33.2159091 C21.0681818,33.8522727 21.3011364,34.4090909 21.7670455,34.8863636 C22.2329545,35.3636364 22.7954545,35.6022727 23.4545455,35.6022727 Z"
						fill="#FFFFFF"
						fillRule="nonzero"
					/>
				</g>
			);
		},
		[onPointClick]
	);

	const viewBox = useCallback(() => {
		if (!selection.selectedWaypoint) return '0 0 1920 1080';
		if (selection.selectedWaypoint.city.name === CITY_NAME.GDANSK) {
			return '0 0 1920 945';
		}
		if (selection.selectedWaypoint.city.name === CITY_NAME.MALBORK) {
			return '0 0 1920 983';
		}
		return '0 0 1920 1080';
	}, [selection.selectedWaypoint]);

	const classes = useStyles();

	const selectedTrackName = useMemo(() => {
		return selection.selectedTrack?.name || 'Trasa';
	}, [selection.selectedTrack]);

	const selectedCityName = useMemo(() => {
		return selection.selectedCity?.name || 'Miasto';
	}, [selection.selectedCity]);

	useEffect(() => {
		setSubheader({
			left: (
				<Link className={classes.backButton} to={ROUTES.main}>
					<ArrowBack style={{ fontSize: 12, marginRight: '0.25rem' }} />
					Powrót do mapy
				</Link>
			),
			title: selectedTrackName,
			subtitle: selectedCityName,
			right: <TrackProgress />
		});
	}, [classes.backButton, selectedCityName, selectedTrackName, setSubheader]);

	if (loading || dataLoading) return <Loader />;
	if (!selection.selectedWaypoint) return <></>;
	return (
		<>
			<Portal>
				{selection.selectedPoi && (
					<TrackCityTooltip
						toBottom={tooltipToBottom}
						top={tooltipCoords.top}
						left={tooltipCoords.left}
						header={selection.selectedPoi.name || ''}
						content={selection.selectedPoi.tooltip_message || ''}
						poi={selection.selectedPoi}
					/>
				)}
			</Portal>
			<div className={classes.root}>
				<img
					id={'image-back'}
					className={classes.imageback}
					src={selection.selectedWaypoint.city.image_url}
					alt={`${selection.selectedWaypoint.city.name}`}
				/>
				<svg
					className={classes.imagefront}
					version="1.1"
					x="0px"
					y="0px"
					viewBox={viewBox()}
				>
					{selection.selectedWaypoint.pois.map((poi: IPoi) => (
						<React.Fragment key={poi.id}>{renderPoint(poi)}</React.Fragment>
					))}
				</svg>
			</div>
			<TrackDrawer onPoiClick={onDrawerPoiClick} />
			<NewCityDialog
				isOpened={newCityDialog.visible}
				close={() => setNewCityDialog({ visible: false })}
				cityName={newCityDialog.cityName || ''}
			/>
			<TrackFinishedDialog
				isOpened={trackFinishedDialog.visible}
				close={() => setTrackFinishedDialog({ visible: false })}
				track_completed_data={trackFinishedDialog.track_completed_data}
			/>
		</>
	);
};

export default memo(Track);
