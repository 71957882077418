import { IconButton, makeStyles } from '@material-ui/core';
import { border, fontGrey, fontGrey10, fontGrey75, mainRed } from '../../styles/variables';
import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useContext,
	useEffect,
	useState
} from 'react';
import { ArrowForward, Share } from '@material-ui/icons';
import { getTrack, getTracksCompleted } from '../../api';
import { AxiosResponse } from 'axios';
import { ITrack, IWayPoint } from '../../constants/types';
import Loader from '../shared/Loader';
import { ROUTES } from '../../routes/routes';
import { SelectionContext } from '../../context/SelectionContext';
import { useHistory } from 'react-router-dom';
import { FacebookShareButton } from 'react-share';

const useStyles = makeStyles(() => ({
	root: {},
	headers: {
		width: '100%',
		height: '4.25rem',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		color: fontGrey75,
		alignItems: 'center',
		borderBottom: border(fontGrey10)
	},
	hTrack: {
		flex: 3
	},
	hScore: {
		flex: 3
	},
	hCities: {
		flex: 5
	},
	hBadge: {
		flex: 2
	},
	hActions: {
		flex: 1
	},
	tracks: {},
	track: {
		width: '100%',
		height: '8rem',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: fontGrey,
		borderBottom: border(fontGrey10)
	},
	trackName: {
		flex: 3
	},
	score: {
		flex: 3
	},
	cities: {
		flex: 5,
		display: 'flex',
		flexDirection: 'column'
	},
	badge: {
		flex: 2
	},
	badgeIcon: {
		width: '6rem'
	},
	actions: { flex: 1, display: 'flex', alignItems: 'center' },
	iconButton: { padding: '2px' }
}));

const FinishedTracks: FunctionComponent = (): ReactElement => {
	const [dataLoading, setDataLoading] = useState<boolean>(false);
	const [tracks, setTracks] = useState<ITrack[]>([]);
	const { selectTrack, selectCity } = useContext(SelectionContext);
	const history = useHistory();

	useEffect(() => {
		getTracksCompleted()
			.then((resp: AxiosResponse<ITrack[]>) => {
				setDataLoading(false);
				setTracks(resp.data);
			})
			.catch(e => {
				setDataLoading(false);
			});
	}, []);

	const goToTrack = async (track: ITrack) => {
		try {
			const trackResponse: AxiosResponse<ITrack> = await getTrack({
				params: { id: track.id }
			});
			const waypoint: IWayPoint =
				trackResponse.data.waypoints.find((point: IWayPoint) => point.position === 0) ||
				track.waypoints[0];
			selectCity(waypoint.city);
			selectTrack(trackResponse.data);
			history.replace(ROUTES.track);
		} catch {
			console.log('go to track error');
		}
	};

	const classes = useStyles();
	if (dataLoading) return <Loader />;
	return (
		<>
			<div className={classes.headers}>
				<div className={classes.hTrack}>Nazwa trasy</div>
				<div className={classes.hScore}>Zdobyte punkty</div>
				<div className={classes.hCities}>Miasta na trasie</div>
				<div className={classes.hBadge}>Odznaka</div>
				<div className={classes.hActions}>Akcje</div>
			</div>
			<div className={classes.tracks}>
				{tracks.map((track: ITrack) => (
					<div key={track.id} className={classes.track}>
						<div className={classes.trackName}>{track.name}</div>
						<div className={classes.score}>{track.percent_complete}</div>
						<div className={classes.cities}>
							{track.waypoints.map((w: IWayPoint, i: number) => (
								<div key={i}>{w.city.name}</div>
							))}
						</div>
						<div className={classes.badge}>
							<img
								className={classes.badgeIcon}
								src={track.badge_image_url}
								alt="map"
							/>
						</div>
						<div className={classes.actions}>
							<FacebookShareButton
								url={'poznajpolske.orpeg.pl'}
								quote={`Ukończyłem podróż po Polsce trasą ${
									track.name
								}! Odwiedziłem: ${track.waypoints
									.map((w: IWayPoint) => w.city.name)
									.join(', ')}. Wy też spróbujcie :)`}
							>
								<Share
									className={classes.iconButton}
									style={{ color: mainRed, cursor: 'pointer' }}
								/>
							</FacebookShareButton>
							<IconButton
								className={classes.iconButton}
								onClick={() => goToTrack(track)}
							>
								<ArrowForward style={{ color: mainRed }} />
							</IconButton>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default memo(FinishedTracks);
