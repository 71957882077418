import React, {
	ChangeEvent,
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import { Button, Checkbox, MenuItem } from '@material-ui/core';
import { mainRed } from '../../styles/variables';
import { ArrowBack, ArrowForward, Close, HelpOutline } from '@material-ui/icons';
import TextInput from '../shared/TextInput';
import { TeacherContext } from '../../context/TeacherContext';
import {
	ageGroups,
	GAME,
	IAgeGroup,
	IPoisResponse,
	ISetPoiData,
	renderAgeGroup,
	renderGameType,
	TEACHER_VIEW_MODE
} from '../../constants/types';
import { useFormik } from 'formik';
import { createSet, getPois } from '../../api';
import { AxiosResponse } from 'axios';
import SelectInput from '../shared/SelectInput';
import Loader from '../shared/Loader';
import produce from 'immer';
import PoiTooltip from '../shared/tooltip/PoiTooltip';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { SubheaderContext } from '../../context/SubheaderContext';
import NotRatedGame from '../game/NotRatedGame';
import { useStyles } from './NewSet.style';

const NewSet: FunctionComponent = (): ReactElement => {
	const { setViewMode } = useContext(TeacherContext);
	const [trackId, setTrackId] = useState<number | undefined>(undefined);
	const [ageGroup, setAgeGroup] = useState<IAgeGroup | undefined>(undefined);
	const [cityId, setCityId] = useState<number | undefined>(undefined);
	const [gameType, setGameType] = useState<string | undefined>(undefined);
	const [selectedPois, setSelectedPois] = useState<{ id: number; name: string }[]>([]);
	const [poisData, setPoisData] = useState<{ data: IPoisResponse; loading: boolean }>({
		data: {
			age_groups: [],
			cities: [],
			game_types: [],
			tracks: [],
			pois: []
		},
		loading: true
	});
	const [poiGame, setPoiGame] = useState<{ poiId?: number; visible: boolean }>({
		visible: false
	});
	const { setSubheader } = useContext(SubheaderContext);

	const formik = useFormik({
		initialValues: {
			name: ''
		},
		onSubmit: (values: { name: string }) => {
			createSet({
				params: {
					name: values.name,
					attributes: selectedPois.map((p: { id: number; name: string }, i: number) => ({
						poi_id: p.id,
						position: i
					}))
				}
			})
				.then((resp: AxiosResponse) => {
					setViewMode(TEACHER_VIEW_MODE.INDEX);
				})
				.catch(e => {
					console.log('error', e.response);
				});
		}
	});

	useEffect(() => {
		getPois({
			params: {
				age_group: ageGroup,
				track_id: trackId,
				city_id: cityId,
				game_type: gameType
			}
		})
			.then((resp: AxiosResponse<IPoisResponse>) => {
				if (resp.data) {
					setPoisData({ data: resp.data, loading: false });
				} else {
					setPoisData({
						data: {
							age_groups: [],
							cities: [],
							game_types: [],
							tracks: [],
							pois: []
						},
						loading: false
					});
				}
			})
			.catch(e => {
				setPoisData({
					data: {
						age_groups: [],
						cities: [],
						game_types: [],
						tracks: [],
						pois: []
					},
					loading: false
				});
			});
	}, [ageGroup, trackId, cityId, gameType]);

	const clearFilters = useCallback(() => {
		setAgeGroup(undefined);
		setTrackId(undefined);
		setCityId(undefined);
		setGameType(undefined);
	}, []);

	const handleChangeSelection = useCallback(
		(poiId: number, poiName: string) => {
			if (selectedPois.map(p => p.id).includes(poiId)) {
				setSelectedPois((prevState: { id: number; name: string }[]) =>
					produce(prevState, draft => {
						const poiIndex = draft.findIndex(
							(p: { id: number; name: string }) => p.id === poiId
						);
						draft.splice(poiIndex, 1);
						return draft;
					})
				);
			} else {
				setSelectedPois((prevState: { id: number; name: string }[]) =>
					produce(prevState, draft => {
						draft.push({ id: poiId, name: poiName });
						return draft;
					})
				);
			}
		},
		[selectedPois]
	);

	const onDragEnd = (result: DropResult) => {
		const { source, destination } = result;
		if (!destination) return;
		if (source.index === destination.index) return;
		setSelectedPois((prevState: { id: number; name: string }[]) =>
			produce(prevState, draft => {
				const draggedItem = draft[source.index];
				draft.splice(source.index, 1);
				draft.splice(destination.index, 0, draggedItem);
				return draft;
			})
		);
	};

	const playTheGame = (poiId: number) => {
		setPoiGame({ poiId: poiId, visible: true });
	};

	const classes = useStyles();
	useEffect(() => {
		if (!poiGame.visible) {
			setSubheader({
				left: (
					<Button
						variant="text"
						className={classes.backButton}
						onClick={() => setViewMode(TEACHER_VIEW_MODE.INDEX)}
					>
						<ArrowBack style={{ fontSize: 12, marginRight: '0.25rem' }} />
						Wróć do listy zestawów
					</Button>
				),
				title: 'Stwórz nowy zestaw'
			});
		}
	}, [classes.backButton, setSubheader, setViewMode, poiGame.visible]);

	const goBackToSet = useCallback(() => {
		setPoiGame({ visible: false });
	}, []);

	if (poiGame.visible) return <NotRatedGame goBack={goBackToSet} poiId={poiGame.poiId} />;
	return (
		<>
			<div className={classes.subheader}>
				<div className={classes.filterTitle}>Filtrowanie</div>
				<div className={classes.filters}>
					<SelectInput
						name={'city'}
						id={'city'}
						value={cityId ? cityId : ''}
						className={classes.select}
						handleChange={(evt: ChangeEvent<HTMLInputElement>) => {
							setCityId(Number(evt.target.value));
						}}
						placeholder={'miasto'}
						options={poisData.data.cities.map((city: { id: number; name: string }) => {
							return (
								<MenuItem key={city.id} value={city.id}>
									{city.name}
								</MenuItem>
							);
						})}
					/>

					<SelectInput
						name={'age'}
						id={'age'}
						value={ageGroup ? ageGroup : ''}
						className={classes.select}
						handleChange={(evt: ChangeEvent<HTMLInputElement>) => {
							setAgeGroup(evt.target.value as IAgeGroup);
						}}
						placeholder={'grupa wiekowa'}
						options={ageGroups.map((group: { v: string; n: string }) => {
							return (
								<MenuItem key={group.v} value={group.v}>
									{group.n}
								</MenuItem>
							);
						})}
					/>
					<SelectInput
						name={'track'}
						id={'track'}
						value={trackId ? trackId : ''}
						defaultValue={undefined}
						className={classes.select}
						placeholder={'trasa'}
						handleChange={(evt: ChangeEvent<HTMLInputElement>) => {
							setTrackId(Number(evt.target.value));
						}}
						options={poisData.data.tracks.map((track: { id: number; name: string }) => {
							return (
								<MenuItem key={track.id} value={track.id}>
									{track.name}
								</MenuItem>
							);
						})}
					/>
					<SelectInput
						name={'game_type'}
						id={'game_type'}
						value={gameType ? gameType : ''}
						defaultValue={undefined}
						className={classes.select}
						placeholder={'typ gry'}
						handleChange={(evt: ChangeEvent<HTMLInputElement>) => {
							setGameType(evt.target.value);
						}}
						options={poisData.data.game_types.map((game: string) => {
							return (
								<MenuItem key={game} value={game}>
									{renderGameType(game as GAME)}
								</MenuItem>
							);
						})}
					/>
					<Button variant="text" className={classes.clearFilters} onClick={clearFilters}>
						wyczyść filtry
					</Button>
				</div>
			</div>
			<form onSubmit={formik.handleSubmit}>
				<div className={classes.infoBar}>
					<div className={classes.label}>Nazwa zestawu</div>
					<div className={classes.row}>
						<TextInput
							label=""
							name="name"
							type="text"
							onChange={formik.handleChange}
							value={formik.values.name}
							fullWidth={false}
							className={classes.textInput}
							inputClass={classes.input}
							margin="none"
						/>
						<Button type="submit" className={classes.button}>
							Zapisz zestaw
						</Button>
					</div>
					<div className={classes.setsPoisLabel}>Wybrane zagadnienia:</div>
					<DragDropContext onDragEnd={onDragEnd}>
						<Droppable droppableId="droppable" direction="horizontal">
							{provided => (
								<div
									ref={provided.innerRef}
									className={classes.selectedPois}
									{...provided.droppableProps}
								>
									{selectedPois.map(
										(poi: { id: number; name: string }, index: number) => (
											<Draggable
												key={poi.id}
												draggableId={`${poi.name}-p-${poi.id}`}
												index={index}
											>
												{provided => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className={classes.selectedPoi}
													>
														<div className={classes.selectedPoiName}>
															{poi.name}
														</div>
														<Close
															style={{
																color: mainRed,
																cursor: 'pointer'
															}}
															onClick={() => {
																handleChangeSelection(
																	poi.id,
																	poi.name
																);
															}}
														/>
													</div>
												)}
											</Draggable>
										)
									)}
									{provided.placeholder}
								</div>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			</form>
			{poisData.loading ? (
				<Loader />
			) : (
				<>
					<div className={classes.setsLabel}>Wybierz zagadnienia do zestawu</div>
					<div className={classes.content}>
						<div className={classes.headers}>
							<div className={classes.hname}>Nazwa zestawu</div>
							<div className={classes.hcity}>Miasto</div>
							<div className={classes.hage}>Grupa wiekowa</div>
							<div className={classes.htrack}>Trasa</div>
							<div className={classes.htags}>Typ gry</div>
							<div className={classes.hactions}>Akcje</div>
							<div className={classes.hadd}>Dodaj</div>
						</div>
						<div className={classes.details}>
							{poisData.data.pois.map((poi: ISetPoiData) => {
								return (
									<div className={classes.detail} key={poi.id}>
										<div className={classes.name}>
											{poi.name}
											<PoiTooltip
												message={poi.tooltip_message}
												gameType={poi.game_type}
											>
												<HelpOutline
													style={{ fontSize: 14, marginLeft: '0.25rem' }}
													color={'disabled'}
												/>
											</PoiTooltip>
										</div>
										<div className={classes.city}>{poi.city_name}</div>
										<div className={classes.age}>
											{renderAgeGroup(poi.age_group)}
										</div>
										<div className={classes.track}>{poi.track_name}</div>
										<div className={classes.tags}>
											{renderGameType(poi.game_type as GAME)}
										</div>
										<div className={classes.actions}>
											<Button
												variant="text"
												onClick={() => playTheGame(poi.id)}
												className={classes.playButton}
											>
												Zagraj
												<ArrowForward
													style={{ fontSize: 12, marginLeft: '0.25rem' }}
												/>
											</Button>
										</div>
										<div className={classes.add}>
											<Checkbox
												checked={selectedPois
													.map(p => p.id)
													.includes(poi.id)}
												onChange={() =>
													handleChangeSelection(poi.id, poi.name)
												}
												style={{
													color: mainRed
												}}
											/>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				</>
			)}
		</>
	);
};

export default memo(NewSet);
