import { makeStyles } from '@material-ui/core';
import {
	border,
	failRed,
	fontGrey,
	lightBlack,
	mainRed,
	mainRed50,
	poppins,
	roboto,
	successGreen,
	white
} from '../../../../styles/variables';

const BUTTON_HEIGHT = '5rem';
const BUTTON_MARGIN = '2rem';
const CARD_SIZE = '240px';

export const useStyles = makeStyles(() => ({
	container: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '1rem',
		flexDirection: 'column'
	},
	counter: {
		fontFamily: poppins,
		fontSize: '2rem',
		fontWeight: 'bold',
		lineHeight: '1.25',
		letterSpacing: '1.2px',
		color: lightBlack,
		marginTop: '2rem'
	},
	question: {
		fontFamily: roboto,
		fontSize: '1.2rem',
		lineHeight: '1.43',
		letterSpacing: '0.52px',
		marginTop: '3rem',
		color: fontGrey
	},
	questionImg: {
		width: CARD_SIZE,
		height: CARD_SIZE,
		boxSizing: 'border-box',
		marginBottom: '1rem',
		marginTop: '1rem'
	},
	answers: {
		marginTop: '3rem',
		display: 'flex',
		justifyContent: 'space-between'
	},
	textAnswers: {
		flexDirection: 'column',
		height: `calc(3 * ${BUTTON_HEIGHT} + 2 * ${BUTTON_MARGIN})`
	},
	textAnswer: {
		fontFamily: poppins,
		letterSpacing: '0.45px',
		width: '30rem',
		height: '5rem',
		borderRadius: '5px',
		boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.14)',
		border: 'transparent',
		marginRight: '1rem'
	},
	correct: {
		border: border(successGreen, '3px')
	},
	incorrect: {
		border: border(failRed, '3px')
	},
	selected: {
		border: border(fontGrey),
		fontWeight: 'bold'
	},
	button: {
		marginTop: '2rem',
		width: '21rem',
		height: '3rem',
		fontFamily: poppins,
		fontWeight: 600,
		letterSpacing: '0.45px',
		color: white,
		backgroundColor: mainRed,
		'&:hover': {
			backgroundColor: mainRed50,
			color: white
		}
	}
}));
