import React, { FunctionComponent, memo, ReactElement, useCallback, useContext } from 'react';
import { AppBar, Button, Toolbar } from '@material-ui/core';
import { ROUTES } from '../../routes/routes';
import { Link as RouterLink, NavLink, useHistory } from 'react-router-dom';
import { border, mainRed } from '../../styles/variables';
import { ArrowForward } from '@material-ui/icons';
import logo from '../../assets/orpeglogo.png';
import { USER_ROLE, UserContext } from '../../context/UserContext';
import { logout } from '../../api';
import { TOKEN, USER } from '../../constants/session';
import clsx from 'clsx';
import { useStyles } from './Navbar.style';

const Navbar: FunctionComponent = (): ReactElement => {
	const { user, setUser } = useContext(UserContext);

	const history = useHistory();

	const handleLogout = useCallback(() => {
		logout()
			.then(() => {
				setUser(undefined);
				window.sessionStorage.removeItem(TOKEN);
				window.sessionStorage.removeItem(USER);
				history.replace(ROUTES.main);
			})
			.catch(e => console.log(e));
	}, [history, setUser]);

	const classes = useStyles();
	return (
		<>
			<AppBar position="static" color="default" elevation={1} className={classes.appBar}>
				<Toolbar className={classes.toolbar}>
					<div className={clsx(classes.sides, classes.left)}>
						<Button component={RouterLink} to={ROUTES.main} className={classes.logo}>
							<img src={logo} alt={'logo'} height="55" />
						</Button>
					</div>
					<nav>
						{user?.role === USER_ROLE.TEACHER && (
							<NavLink
								exact
								className={classes.navLink}
								to={ROUTES.teacher}
								activeStyle={{
									color: mainRed,
									borderBottom: border(mainRed, '2px')
								}}
							>
								Panel Nauczyciela
							</NavLink>
						)}
						<NavLink
							exact
							className={classes.navLink}
							to={ROUTES.main}
							activeStyle={{ color: mainRed, borderBottom: border(mainRed, '2px') }}
						>
							Mapa
						</NavLink>
						<NavLink
							exact
							className={classes.navLink}
							to={ROUTES.rankings}
							activeStyle={{ color: mainRed, borderBottom: border(mainRed, '2px') }}
						>
							Ranking
						</NavLink>
						<NavLink
							exact
							className={classes.navLink}
							to={ROUTES.sets}
							activeStyle={{
								color: mainRed,
								borderBottom: border(mainRed, '2px')
							}}
						>
							Zestawy
						</NavLink>
						<NavLink
							exact
							className={classes.navLink}
							to={ROUTES.info}
							activeStyle={{ color: mainRed, borderBottom: border(mainRed, '2px') }}
						>
							Informacje o projekcie
						</NavLink>
					</nav>
					<div className={clsx(classes.sides, classes.right)}>
						{user ? (
							<div className={classes.user}>
								<Button
									component={RouterLink}
									to={ROUTES.profile}
									variant="text"
									className={classes.profile}
								>
									{user.role === USER_ROLE.STUDENT ? user.nickname : user.email}
								</Button>
								<Button
									variant="text"
									className={classes.logoutButton}
									onClick={handleLogout}
								>
									wyloguj
								</Button>
							</div>
						) : (
							<Button
								component={RouterLink}
								to={ROUTES.login}
								variant="outlined"
								className={classes.loginButton}
							>
								Zaloguj się
								<ArrowForward style={{ fontSize: 12, marginLeft: '0.25rem' }} />
							</Button>
						)}
					</div>
				</Toolbar>
			</AppBar>
		</>
	);
};

export default memo(Navbar);
