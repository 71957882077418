import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import StudentSet from './Set';
import { SubmitButton } from '../shared/buttons';
import TextInput from '../shared/TextInput';
import { useFormik } from 'formik';
import { getExercises } from '../../api';
import { AxiosResponse } from 'axios';
import { fontGrey75, mainRed, white } from '../../styles/variables';
import { ISetResponse } from '../../constants/types';
import { SubheaderContext } from '../../context/SubheaderContext';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: 'calc(100% - 10rem)'
	},
	info: { textAlign: 'center', color: fontGrey75 },
	paper: {
		width: '25rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: white,
		padding: '5rem 10rem'
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%', // Fix IE 11 issue.
		marginTop: '1rem'
	},
	error: {
		fontSize: '0.8rem',
		color: mainRed,
		width: '100%'
	}
}));

const Sets: FunctionComponent = (): ReactElement => {
	const [error, setError] = useState<string | undefined>(undefined);
	const [set, setSet] = useState<ISetResponse | undefined>(undefined);
	const { setSubheader } = useContext(SubheaderContext);
	useEffect(() => {
		setSubheader({ title: 'Wpisz kod zestawu' });
	}, [setSubheader]);

	const formik = useFormik({
		initialValues: {
			exchange_code: ''
		},
		onSubmit: (values: { exchange_code: string }) => {
			getExercises({
				params: { exchange_code: values.exchange_code }
			})
				.then((resp: AxiosResponse<ISetResponse>) => {
					setSet(resp.data);
				})
				.catch(e => {
					setError('Kod nieprawidłowy');
				});
		}
	});

	const goBack = useCallback(() => {
		setSet(undefined);
	}, []);

	const classes = useStyles();
	if (set) return <StudentSet set={set} goBack={goBack} />;
	return (
		<>
			<div className={classes.root}>
				<Paper className={classes.paper} elevation={3}>
					<div className={classes.info}>
						Aby otrzymać dostęp do zestawu utworzonego przez nauczyciela wpisz
						odpowiedni kod
					</div>

					<form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
						<TextInput
							label="Kod dostępu"
							name={'exchange_code'}
							onChange={formik.handleChange}
							value={formik.values.exchange_code}
							touched={formik.touched.exchange_code}
							error={formik.errors.exchange_code}
							inputProps={{
								maxLength: 7
							}}
						/>
						{error && <div className={classes.error}>{error}</div>}
						<SubmitButton text={'Ok'} />
					</form>
				</Paper>
			</div>
		</>
	);
};

export default memo(Sets);
