import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useMemo,
	useState
} from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './Quiz.style';
import Answer from './Answer';
import { IAnswer, IQuizGameData } from '../../../../constants/types';

interface IProps {
	gameData: IQuizGameData;
	gameId: number;
	finishGame: (gameId: number, points: number) => void;
}

const Quiz: FunctionComponent<IProps> = ({ gameData, gameId, finishGame }): ReactElement => {
	const classes = useStyles();
	const [displayedQuestionIndex, setDisplayedQuestionIndex] = useState<number>(0);
	const [selectedAnswer, setSelectedAnswer] = useState<IAnswer | undefined>(undefined);
	const [showNext, setShowNext] = useState<boolean>(false);
	const [showResult, setShowResult] = useState<boolean>(false);
	const [correctAnswersCount, setCorrectAnswersCount] = useState<number>(0);

	const displayedQuestion = useMemo(() => gameData.questions[displayedQuestionIndex], [
		gameData,
		displayedQuestionIndex
	]);

	const checkSelectedAnswer = useCallback(() => {
		setShowNext(true);
		setShowResult(true);
		if (selectedAnswer?.answer === displayedQuestion.good_answer.answer) {
			setCorrectAnswersCount((prevState: number) => prevState + 1);
		}
	}, [setShowNext, setShowResult, selectedAnswer, displayedQuestion]);

	const showNextQuestion = useCallback(() => {
		setShowNext(false);
		setShowResult(false);
		setSelectedAnswer(undefined);
		setDisplayedQuestionIndex((prev: number) => prev + 1);
	}, [setShowNext, setShowResult, setDisplayedQuestionIndex]);

	const handleGameFinish = useCallback(() => {
		finishGame(gameId, correctAnswersCount);
	}, [finishGame, correctAnswersCount, gameId]);

	const answersType = useMemo(() => displayedQuestion.answers[0].type, [displayedQuestion]);

	return (
		<div className={classes.container}>
			<div className={classes.counter}>
				Pytanie {displayedQuestionIndex + 1}/{gameData.questions.length}
			</div>
			{displayedQuestion.question_image_url && (
				<img
					className={classes.questionImg}
					src={displayedQuestion.question_image_url}
					alt={`question-${displayedQuestionIndex}`}
				/>
			)}
			<div className={classes.question}>{displayedQuestion.question}</div>
			<div
				className={clsx(
					classes.answers,
					answersType === 'text' && classes.textAnswers,
					answersType === 'image' && classes.imageAnswers
				)}
			>
				{displayedQuestion.answers.map((answer: IAnswer, i: number) => (
					<Answer
						key={i}
						isCorrect={displayedQuestion.good_answer.answer === answer.answer}
						answer={answer}
						showResult={showResult}
						selectedAnswer={selectedAnswer}
						setSelectedAnswer={setSelectedAnswer}
					/>
				))}
			</div>
			{displayedQuestionIndex + 1 === gameData.questions.length ? (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={showNext ? handleGameFinish : checkSelectedAnswer}
				>
					{showNext ? 'Zakończ' : 'Sprawdź odpowiedź'}
				</Button>
			) : (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={showNext ? showNextQuestion : checkSelectedAnswer}
				>
					{showNext ? 'Następne pytanie' : 'Sprawdź odpowiedź'}
				</Button>
			)}
		</div>
	);
};

export default memo(Quiz);
