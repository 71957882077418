import React, { memo } from 'react';
import { makeStyles } from '@material-ui/core';
import { fontGrey, roboto } from '../../../styles/variables';

const useStyles = makeStyles({
	content: {
		marginTop: '1rem',
		marginBottom: '0.5rem',
		opacity: 0.5,
		fontFamily: roboto,
		fontSize: '1rem',
		lineHeight: 1.33,
		letterSpacing: '0.45px',
		textAlign: 'center',
		color: fontGrey
	}
});

interface IProps {
	text: string;
}

const Content: React.FunctionComponent<IProps> = ({ text }) => {
	const classes = useStyles();
	return <div className={classes.content}>{text}</div>;
};

export default memo(Content);
