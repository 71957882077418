import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useMemo,
	useState
} from 'react';
import { List, ListItem, ListItemText, TextField } from '@material-ui/core';
import clsx from 'clsx';
import { SelectionContext } from '../../../context/SelectionContext';
import { PlayArrow } from '@material-ui/icons';
import { useStyles } from '../MapDrawer.style';
import { ICity } from '../../../constants/types';
import { DataContext } from '../../../context/DataContext';

const CityList: FunctionComponent = (): ReactElement => {
	const classes = useStyles();
	const { selection, selectCity } = useContext(SelectionContext);
	const { data } = useContext(DataContext);
	const [filter, setFilter] = useState<string>('');

	const filteredCities: ICity[] = useMemo(
		() =>
			data.cities.filter((city: ICity) =>
				city.name.toLowerCase().includes(filter.toLowerCase())
			),
		[filter, data.cities]
	);

	const handleCitySelected = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>, city: ICity) => {
			selectCity(city);
		},
		[selectCity]
	);

	const content = useCallback(
		(cityName: string) => {
			if (cityName === selection.selectedCity?.name)
				return (
					<>
						<PlayArrow className={classes.selectedIcon} />
						{cityName}
					</>
				);
			return cityName;
		},
		[selection, classes.selectedIcon]
	);

	return (
		<>
			<TextField
				className={classes.searchInput}
				margin="normal"
				fullWidth
				id="search"
				placeholder="Wyszukaj miasto..."
				name="search"
				autoFocus
				value={filter}
				onChange={evt => setFilter(evt.target.value)}
				InputProps={{ classes: { root: classes.input } }}
			/>
			<List>
				{filteredCities.map((city: ICity, index: number) => (
					<ListItem
						button
						key={index}
						selected={selection.selectedCity?.name === city.name}
						onClick={event => handleCitySelected(event, city)}
						classes={{
							root: classes.listItem
						}}
					>
						<ListItemText
							className={clsx(
								classes.listItemText,
								city.name === selection.selectedCity?.name &&
									classes.listItemSelected
							)}
							disableTypography
							primary={content(city.name)}
						/>
					</ListItem>
				))}
			</List>
		</>
	);
};

export default memo(CityList);
