import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useMemo,
	useState
} from 'react';
import { Button } from '@material-ui/core';
import { IHotOrNotGameData } from '../../../../constants/types';
import clsx from 'clsx';
import Answer from './Answer';
import { useStyles } from './HotOrNot.style';

interface IProps {
	gameData: IHotOrNotGameData;
	gameId: number;
	finishGame: (gameId: number, points: number) => void;
}

const HotOrNot: FunctionComponent<IProps> = ({ gameData, gameId, finishGame }): ReactElement => {
	const [displayedQuestionIndex, setDisplayedQuestionIndex] = useState<number>(0);
	const [selectedAnswer, setSelectedAnswer] = useState<{ value: boolean | undefined }>({
		value: undefined
	});
	const [showNext, setShowNext] = useState<boolean>(false);
	const [showResult, setShowResult] = useState<boolean>(false);
	const [correctAnswersCount, setCorrectAnswersCount] = useState<number>(0);
	const classes = useStyles();

	const displayedQuestion = useMemo(() => gameData.questions[displayedQuestionIndex], [
		displayedQuestionIndex,
		gameData.questions
	]);

	const checkSelectedAnswer = useCallback(() => {
		setShowNext(true);
		setShowResult(true);
		if (selectedAnswer.value === displayedQuestion.answer) {
			setCorrectAnswersCount((prevState: number) => prevState + 1);
		}
	}, [setShowNext, setShowResult, selectedAnswer, displayedQuestion]);

	const showNextQuestion = useCallback(() => {
		setShowNext(false);
		setShowResult(false);
		setSelectedAnswer({ value: undefined });
		setDisplayedQuestionIndex((prev: number) => prev + 1);
	}, []);

	const handleGameFinish = useCallback(() => {
		finishGame(gameId, correctAnswersCount);
	}, [finishGame, correctAnswersCount, gameId]);

	return (
		<div className={classes.container}>
			<div className={classes.counter}>
				Pytanie {displayedQuestionIndex + 1}/{gameData.questions.length}
			</div>
			{displayedQuestion.question_image_url && (
				<img
					className={classes.questionImg}
					src={displayedQuestion.question_image_url}
					alt={`question-${displayedQuestionIndex}`}
				/>
			)}
			<div className={classes.question}>{displayedQuestion.question}</div>
			<div className={clsx(classes.answers)}>
				<Answer
					isCorrect={displayedQuestion.answer}
					answer={true}
					showResult={showResult}
					selectedAnswer={selectedAnswer}
					setSelectedAnswer={setSelectedAnswer}
				/>
				<Answer
					isCorrect={!displayedQuestion.answer}
					answer={false}
					showResult={showResult}
					selectedAnswer={selectedAnswer}
					setSelectedAnswer={setSelectedAnswer}
				/>
			</div>
			{displayedQuestionIndex + 1 === gameData.questions.length ? (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={showNext ? handleGameFinish : checkSelectedAnswer}
				>
					{showNext ? 'Zakończ' : 'Sprawdź odpowiedź'}
				</Button>
			) : (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={showNext ? showNextQuestion : checkSelectedAnswer}
				>
					{showNext ? 'Następne pytanie' : 'Sprawdź odpowiedź'}
				</Button>
			)}
		</div>
	);
};

export default memo(HotOrNot);
