import React, { FunctionComponent, memo, ReactElement, useContext } from 'react';
import { Drawer, LinearProgress, makeStyles } from '@material-ui/core';
import {
	border,
	CITY_DRAWER_HEIGHT,
	disabledBackground,
	disabledFont,
	fontGrey10,
	fontGrey75,
	FOOTER_HEIGHT,
	mainRed,
	mainRed50,
	poppins,
	roboto,
	white
} from '../../styles/variables';
import { SelectionContext } from '../../context/SelectionContext';
import { IPoi, IWayPoint, IWayPointFull } from '../../constants/types';
import clsx from 'clsx';
import { ArrowForward, Done, Lock } from '@material-ui/icons';
import { getWaypoint } from '../../api';
import { AxiosResponse } from 'axios';
import { waypointStatsAsValue } from '../../utils/waypointStatsAsValue';

const useStyles = makeStyles(() => ({
	drawer: {
		height: CITY_DRAWER_HEIGHT
	},
	drawerPaper: {
		height: CITY_DRAWER_HEIGHT,
		width: '100%',
		marginBottom: FOOTER_HEIGHT
	},
	pois: {
		paddingLeft: '20%',
		paddingRight: '20%',
		height: '5rem',
		marginBottom: '0.5rem',
		display: 'flex',
		width: '60%',
		justifyContent: 'space-between',
		boxShadow: '0 -5px 20px 0 rgba(0, 0, 0, 0.07)',
		alignItems: 'center',
		borderBottom: border(fontGrey10)
	},
	poi: {
		color: fontGrey75,
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 600,
		cursor: 'pointer'
	},
	finishedPoi: {
		color: mainRed
	},
	cities: {
		paddingLeft: '10%',
		paddingRight: '10%',
		backgroundColor: white,
		display: 'flex',
		width: '80%',
		justifyContent: 'space-between'
	},
	cityBox: {
		cursor: 'pointer',
		backgroundColor: mainRed50,
		borderRadius: '3px',
		width: '16rem',
		height: '7rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	selectedWaypoint: {
		backgroundColor: mainRed
	},
	cityName: {
		color: white,
		fontFamily: poppins,
		fontSize: '1.20rem',
		fontWeight: 'bold',
		marginTop: '0.25rem',
		marginBottom: '0.3rem'
	},
	cityPoints: {
		color: white,
		fontFamily: poppins,
		fontSize: '0.8rem',
		fontWeight: 500
	},
	locked: {
		backgroundColor: disabledBackground,
		color: disabledFont
	},
	lockedInfo: {
		fontFamily: roboto,
		fontSize: '0.8rem',
		textAlign: 'center',
		color: disabledFont,
		marginLeft: '1rem',
		marginRight: '1rem'
	},
	visitInfo: {
		fontFamily: poppins,
		fontSize: '0.9em',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: white,
		marginLeft: '1rem',
		marginRight: '1rem'
	},
	progressBar: {
		width: '75%',
		height: '0.5rem',
		borderRadius: '4px',
		marginTop: '0.8rem',
		marginBottom: '1rem'
	},
	progressBarColor: {
		backgroundColor: white
	}
}));

interface IProps {
	onPoiClick: (poi: IPoi) => void;
}

const TrackDrawer: FunctionComponent<IProps> = ({ onPoiClick }): ReactElement => {
	const classes = useStyles();
	const { selection, selectWaypoint, selectCity } = useContext(SelectionContext);

	const isWaypointFinished = (waypoint: IWayPoint): boolean => {
		const parts = waypoint.waypoint_stats.split('/');
		return Number(parts[0]) / Number(parts[1]) === 1;
	};

	const goTo = (waypoint: IWayPoint) => {
		if (!waypoint.locked) {
			getWaypoint({ params: { id: waypoint.id } })
				.then((resp: AxiosResponse<IWayPointFull>) => {
					selectWaypoint(resp.data);
					selectCity(resp.data.city);
				})
				.catch(e => {
					console.log('waypoint error');
				});
		}
	};

	if (!selection.selectedTrack) return <></>;
	if (!selection.selectedWaypoint) return <></>;
	return (
		<Drawer
			className={classes.drawer}
			variant="permanent"
			elevation={5}
			classes={{
				paper: classes.drawerPaper
			}}
			anchor={'bottom'}
		>
			<div className={classes.pois}>
				{selection.selectedWaypoint.pois.map((poi: IPoi) => (
					<div
						key={poi.id}
						className={clsx(
							classes.poi,
							poi.game.already_played && classes.finishedPoi
						)}
						onClick={() => onPoiClick(poi)}
					>
						{poi.name}
					</div>
				))}
			</div>
			<div className={classes.cities}>
				{selection.selectedTrack.waypoints.map((waypoint: IWayPoint) => (
					<div
						key={waypoint.id}
						className={clsx(
							classes.cityBox,
							selection.selectedWaypoint &&
								selection.selectedWaypoint.id === waypoint.id &&
								classes.selectedWaypoint,
							waypoint.locked && classes.locked
						)}
						onClick={() => goTo(waypoint)}
					>
						<div className={clsx(classes.cityName, waypoint.locked && classes.locked)}>
							{waypoint.city.name}
						</div>
						<div
							className={clsx(classes.cityPoints, waypoint.locked && classes.locked)}
						>
							{waypoint.locked ? 'Miasto Zablokowane' : waypoint.waypoint_stats}
						</div>
						{isWaypointFinished(waypoint) && (
							<Done style={{ fontSize: 14, color: white }} />
						)}
						{waypoint.locked && <Lock style={{ fontSize: 12, color: disabledFont }} />}
						{selection.selectedWaypoint &&
							selection.selectedWaypoint.id !== waypoint.id &&
							!isWaypointFinished(waypoint) &&
							!waypoint.locked && (
								<div className={classes.visitInfo}>
									Odwiedź{' '}
									<ArrowForward
										style={{
											fontSize: 12,
											color: white,
											marginLeft: '0.25rem'
										}}
									/>
								</div>
							)}
						{waypoint.locked ? (
							<div className={classes.lockedInfo}>
								Ukończ poprzednie miasta aby uzyskać dostęp
							</div>
						) : (
							<LinearProgress
								variant="determinate"
								value={waypointStatsAsValue(waypoint)}
								classes={{
									root: classes.progressBar,
									barColorPrimary: classes.progressBarColor
								}}
							/>
						)}
					</div>
				))}
			</div>
		</Drawer>
	);
};

export default memo(TrackDrawer);
