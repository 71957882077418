import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useMemo,
	useState
} from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { IWordcloudGameData } from '../../../../constants/types';
import { shuffle } from '../utils';
import {
	border,
	failRed,
	fontGrey,
	fontGrey10,
	lightBlack,
	mainRed,
	mainRed50,
	poppins,
	roboto,
	successGreen,
	white
} from '../../../../styles/variables';
import clsx from 'clsx';
import { Close, Done } from '@material-ui/icons';

interface IProps {
	gameData: IWordcloudGameData;
	gameId: number;
	finishGame: (gameId: number, points: number) => void;
}

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '1rem',
		flexDirection: 'column'
	},
	instruction: {
		color: fontGrey,
		fontSize: '1.2rem',
		fontFamily: roboto,
		marginBottom: '0.5rem'
	},
	allWords: {
		borderRadius: '5px',
		border: border(fontGrey10),
		padding: '3rem',
		display: 'flex',
		justifyContent: 'center',
		maxWidth: '40rem',
		flexWrap: 'wrap'
	},
	word: {
		color: lightBlack,
		fontFamily: poppins,
		fontSize: '1.25rem',
		fontWeight: 'bold',
		padding: '0.5rem 1.5rem 1.5rem 1.5rem',
		cursor: 'pointer',
		'&:hover': {
			color: mainRed50
		}
	},
	selected: {
		color: mainRed
	},
	correct: {
		color: successGreen
	},
	failed: {
		color: failRed
	},
	button: {
		marginTop: '2rem',
		width: '21rem',
		height: '3rem',
		fontFamily: poppins,
		fontWeight: 600,
		letterSpacing: '0.45px',
		color: white,
		backgroundColor: mainRed,
		'&:hover': {
			backgroundColor: mainRed50,
			color: white
		}
	},
	textCorrect: {
		color: successGreen,
		fontFamily: poppins,
		fontWeight: 600,
		fontSize: '1rem',
		padding: '0 1.5rem'
	},
	textIncorrect: {
		color: failRed,
		fontFamily: poppins,
		fontWeight: 600,
		fontSize: '1rem',
		padding: '0 1.5rem'
	},
	textTransparent: {
		color: 'transparent',
		fontFamily: poppins,
		fontWeight: 600,
		fontSize: '1rem'
	},
	resultsContainer: {
		marginTop: '3rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	finished: {
		color: lightBlack,
		fontFamily: poppins,
		fontWeight: 'bold',
		fontSize: '2rem'
	},
	resultpoints: {
		color: lightBlack,
		fontFamily: poppins,
		fontWeight: 'bold',
		fontSize: '3rem'
	},
	results: {
		color: fontGrey,
		fontFamily: roboto,
		fontSize: '1.2rem'
	}
}));

const Wordcloud: FunctionComponent<IProps> = ({ gameData, gameId, finishGame }): ReactElement => {
	const classes = useStyles();
	const [selectedWords, setSelectedWords] = useState<string[]>([]);
	const [finishedGame, setFinishedGame] = useState<boolean>(false);
	const [showResults, setShowResults] = useState<boolean>(false);
	const [points, setPoints] = useState<number>(0);

	const shuffledWords = useMemo(() => shuffle(gameData.all_words) as string[], [
		gameData.all_words
	]);

	const toggle = (word: string) => {
		if (selectedWords.includes(word)) {
			setSelectedWords(prev => prev.filter(w => w !== word));
		} else {
			setSelectedWords(prev => [...prev, word]);
		}
	};

	const checkSelectedAnswer = () => {
		setShowResults(true);
		for (let i = 0; i < selectedWords.length; i++) {
			if (gameData.good_words.includes(selectedWords[i])) {
				setPoints(prev => prev + 1);
			}
		}

		setFinishedGame(true);
	};

	const handleGameFinish = useCallback(() => {
		finishGame(gameId, points);
	}, [finishGame, points, gameId]);

	return (
		<div className={classes.root}>
			<div className={classes.instruction}>Wybierz pasujące</div>
			<div className={classes.instruction}>
				{selectedWords.length}/{gameData.good_words.length}
			</div>
			<div className={classes.allWords}>
				{shuffledWords.map((word: string, i: number) => (
					<div key={i}>
						{showResults &&
							selectedWords.includes(word) &&
							gameData.good_words.includes(word) && (
								<div className={classes.textCorrect}>
									Dobrze
									<Done
										style={{
											color: successGreen,
											fontSize: 12,
											marginRight: '0.25rem'
										}}
									/>
								</div>
							)}
						{showResults &&
							selectedWords.includes(word) &&
							!gameData.good_words.includes(word) && (
								<div className={classes.textIncorrect}>
									Błąd{' '}
									<Close
										style={{
											color: failRed,
											fontSize: 12,
											marginRight: '0.25rem'
										}}
									/>
								</div>
							)}
						{(!showResults || !selectedWords.includes(word)) && (
							<div className={classes.textTransparent}>.</div>
						)}
						<div
							key={i}
							className={clsx(
								classes.word,
								selectedWords.includes(word) && classes.selected,
								showResults &&
									selectedWords.includes(word) &&
									gameData.good_words.includes(word) &&
									classes.correct,
								showResults &&
									selectedWords.includes(word) &&
									!gameData.good_words.includes(word) &&
									classes.failed
							)}
							onClick={() => toggle(word)}
						>
							{word}
						</div>
					</div>
				))}
			</div>
			{finishedGame && (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={handleGameFinish}
				>
					{'Zakończ'}
				</Button>
			)}
			{!finishedGame && (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={checkSelectedAnswer}
				>
					{'Sprawdź'}
				</Button>
			)}
		</div>
	);
};

export default memo(Wordcloud);
