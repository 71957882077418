import React, { FunctionComponent, memo, ReactElement, useCallback, useState } from 'react';
import { Button } from '@material-ui/core';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { shuffle } from '../utils';
import produce from 'immer';
import clsx from 'clsx';
import Card from './Card';
import { IGroupsGameData } from '../../../../constants/types';
import { useStyles } from './Groups.style';

interface IProps {
	gameData: IGroupsGameData;
	gameId: number;
	finishGame: (gameId: number, points: number) => void;
}

export interface IGroupCard {
	id: string;
	value: string;
}

const Groups: FunctionComponent<IProps> = ({ gameData, gameId, finishGame }): ReactElement => {
	const [points, setPoints] = useState<number>(0);
	const [showResult, setShowResult] = useState<boolean>(false);
	const [showNext, setShowNext] = useState<boolean>(false);
	const [round, setRound] = useState<number>(0);
	const [dataSet, setDataSet] = useState<IGroupCard[]>(
		(shuffle([...gameData.all_items]) as string[]).map((item: string, i: number) => ({
			id: `card-${round}-${i}`,
			value: item
		}))
	);
	const [resultSets, setResultSets] = useState<IGroupCard[][]>(
		gameData.tasks[round].collections.map(() => [])
	);

	const handleFinishGame = () => finishGame(gameId, points);

	const checkSelectedAnswer = useCallback(() => {
		setShowNext(true);
		setShowResult(true);
		let roundPoints = 0;
		resultSets.map((set: IGroupCard[], setI: number) => {
			return set.map((item: IGroupCard) => {
				if (gameData.tasks[round].collections[setI].items.includes(item.value)) {
					roundPoints += 1;
				}
			});
		});
		setPoints((prevState: number) => prevState + roundPoints);
	}, [gameData.tasks, resultSets, round]);

	const showNextRound = useCallback(() => {
		setShowNext(false);
		setShowResult(false);
		setRound((prevState: number) => prevState + 1);
		setResultSets(() => gameData.tasks[round + 1].collections.map(() => []));
		setDataSet(() =>
			(shuffle([...gameData.all_items]) as string[]).map((item: string, i: number) => ({
				id: `${round}-${i}`,
				value: item
			}))
		);
	}, [gameData.all_items, gameData.tasks, round]);

	const onDragEnd = (result: DropResult) => {
		const { source, destination } = result;
		if (!destination) return;
		if (destination.droppableId === 'droppable-root') return;
		const destinationSetIndex = destination.droppableId.split('-')[1];
		if (!destinationSetIndex) return;

		if (source.droppableId === 'droppable-root') {
			//	dropping from data set

			// add to result set
			setResultSets((prevState: IGroupCard[][]) =>
				produce(prevState, draft => {
					const draggedItem = dataSet[source.index];
					draft[Number(destinationSetIndex)].push(draggedItem);
					return draft;
				})
			);

			// remove from data set
			setDataSet((prevState: IGroupCard[]) =>
				produce(prevState, draft => {
					draft.splice(source.index, 1);
					return draft;
				})
			);
		} else {
			//	 changing result set
			const sourceSetIndex = source.droppableId.split('-')[1];
			setResultSets((prevState: IGroupCard[][]) =>
				produce(prevState, draft => {
					draft[Number(destinationSetIndex)].push(
						draft[Number(sourceSetIndex)][source.index]
					);
					draft[Number(sourceSetIndex)].splice(source.index, 1);
					return draft;
				})
			);
		}
	};

	const classes = useStyles({ setCount: resultSets.length });
	const getSetClass = (id: number) => {
		switch (id) {
			case 0:
				return classes.set1;
			case 1:
				return classes.set2;
			case 2:
				return classes.set3;
		}
	};
	return (
		<div className={classes.root}>
			<div className={classes.round}>
				Runda {round + 1}/{gameData.tasks.length}
			</div>
			<div>{gameData.tasks[round].task_instruction}</div>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable-root" direction="horizontal">
					{provided => (
						<div
							ref={provided.innerRef}
							className={classes.mainSet}
							{...provided.droppableProps}
						>
							{dataSet.map((card: IGroupCard, index: number) => (
								<Draggable key={card.id} draggableId={card.id} index={index}>
									{provided => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className={classes.card}
										>
											<Card
												card={card}
												showResult={false}
												isCorrect={false}
											/>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
				<div className={classes.sets}>
					{resultSets.map((set: IGroupCard[], i: number) => {
						return (
							<div key={i} className={clsx(classes.set, getSetClass(i))}>
								<div className={classes.label}>
									{gameData.tasks[round].collections[i].name}
								</div>
								<div
									className={classes.counter}
								>{`${set.length}/${gameData.tasks[round].collections[i].items.length}`}</div>
								<Droppable droppableId={`droppable-${i}`} direction="horizontal">
									{provided => (
										<div
											ref={provided.innerRef}
											{...provided.droppableProps}
											className={classes.setItems}
										>
											{set.map((card: IGroupCard, index: number) => (
												<Draggable
													key={card.id}
													draggableId={`set-${i}-${card.id}`}
													index={index}
												>
													{provided => (
														<div
															ref={provided.innerRef}
															{...provided.draggableProps}
															{...provided.dragHandleProps}
															className={classes.card}
														>
															<Card
																card={card}
																showResult={showResult}
																isCorrect={gameData.tasks[
																	round
																].collections[i].items.includes(
																	card.value
																)}
															/>
														</div>
													)}
												</Draggable>
											))}

											{provided.placeholder}
										</div>
									)}
								</Droppable>
							</div>
						);
					})}
				</div>
			</DragDropContext>
			{showNext ? (
				<>
					{gameData.tasks[round + 1] ? (
						<Button
							type="button"
							fullWidth
							variant="outlined"
							className={classes.button}
							onClick={showNextRound}
						>
							{'Następna runda'}
						</Button>
					) : (
						<Button
							type="button"
							fullWidth
							variant="outlined"
							className={classes.button}
							onClick={handleFinishGame}
						>
							{'Zakończ grę'}
						</Button>
					)}
				</>
			) : (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={checkSelectedAnswer}
				>
					{'Sprawdź'}
				</Button>
			)}
		</div>
	);
};

export default memo(Groups);
