export const TOKEN = 'orpeg.token';
export const USER = 'orpeg.user';

export interface IFacebookResponse {
	accessToken: string;
	data_access_expiration_time: number;
	expiresIn: number;
	graphDomain: string;
	id: string;
	name: string;
	signedRequest: string;
	userID: string;
}
