import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import {
	border,
	fontGrey,
	fontGrey10,
	fontGrey75,
	lightGrey,
	mainRed,
	poppins,
	roboto,
	white
} from '../../styles/variables';
import { ArrowBack, ArrowForward, Close, HelpOutline } from '@material-ui/icons';
import DeleteSet from './DeleteSet';
import { TeacherContext } from '../../context/TeacherContext';
import {
	GAME,
	IAgeGroup,
	IExercise,
	ISetResponse,
	renderAgeGroup,
	renderGameType,
	TEACHER_VIEW_MODE
} from '../../constants/types';
import PoiTooltip from '../shared/tooltip/PoiTooltip';
import { getSet } from '../../api';
import { AxiosResponse } from 'axios';
import Loader from '../shared/Loader';
import { PADDING } from '../../constants/sets';
import { SubheaderContext } from '../../context/SubheaderContext';
import NotRatedGame from '../game/NotRatedGame';
import { copyToClipboard } from '../../utils/copyToClipboard';
import copy from './content_copy.svg';

const useStyles = makeStyles(() => ({
	subheader: {
		backgroundColor: lightGrey,
		paddingLeft: PADDING,
		paddingRight: PADDING,
		height: '9rem',
		boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.07)',
		paddingTop: '1rem'
	},
	content: {
		height: `calc(100% - 11.5rem)`,
		paddingLeft: PADDING,
		paddingRight: PADDING,
		backgroundColor: white
	},
	infobar: {
		display: 'flex',
		alignItems: 'center',
		height: '6rem',
		paddingLeft: PADDING,
		paddingRight: PADDING,
		backgroundColor: white
	},
	hinfo: {
		fontSize: '1.2rem',
		color: fontGrey75,
		marginBottom: '0.5rem'
	},
	info: {
		fontSize: '1.2rem',
		color: fontGrey,
		fontFamily: roboto
	},
	infogroup: {
		display: 'flex',
		flexDirection: 'column',
		padding: '1rem 2rem 1rem 1rem'
	},
	setsLabel: {
		paddingLeft: PADDING,
		paddingRight: PADDING,
		backgroundColor: white,
		paddingTop: '2rem',
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 'bold',
		lineHeight: '2.86',
		letterSpacing: '0.52px',
		color: fontGrey
	},
	headers: {
		width: '100%',
		height: '4.25rem',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		color: fontGrey75,
		alignItems: 'center',
		borderBottom: border(fontGrey10)
	},
	hname: { flex: 5 },
	hcity: { flex: 3 },
	hage: { flex: 2 },
	htrack: { flex: 3 },
	htags: { flex: 1 },
	hactions: { flex: 1 },
	details: {
		height: 'calc(100% - 6rem)',
		overflow: 'scroll'
	},
	detail: {
		width: '100%',
		height: '4.25rem',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: fontGrey,
		borderBottom: border(fontGrey10),
		fontFamily: roboto
	},
	name: { flex: 5, display: 'flex' },
	city: { flex: 3 },
	age: { flex: 2 },
	track: { flex: 3 },
	tags: { flex: 1 },
	actions: { flex: 1 },
	deleteButton: {
		fontFamily: poppins,
		color: mainRed
	},
	backButton: {
		fontFamily: poppins,
		fontWeight: 500,
		textTransform: 'unset',
		letterSpacing: '0.45px',
		color: white
	},
	playButton: {
		textTransform: 'unset',
		color: mainRed,
		padding: '1px',
		minWidth: '40px',
		fontSize: '14px'
	},
	copyButton: {
		color: mainRed,
		fontSize: '1rem',
		marginLeft: '0.25rem',
		padding: '4px'
	}
}));

const SetInfo: FunctionComponent = (): ReactElement => {
	const { setsSelection, setViewMode } = useContext(TeacherContext);
	const [deleteModal, openDeleteModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [setResp, setSetResp] = useState<ISetResponse>();
	const [poiGame, setPoiGame] = useState<{ poiId?: number; visible: boolean }>({
		visible: false
	});
	const { setSubheader } = useContext(SubheaderContext);

	useEffect(() => {
		getSet({ params: { id: setsSelection.selectedSet?.id } })
			.then((resp: AxiosResponse<ISetResponse>) => {
				setLoading(false);
				setSetResp(resp.data);
			})
			.catch(e => {
				console.log('set error');
				setLoading(false);
			});
	}, [setsSelection.selectedSet]);

	const playTheGame = (poiId: number) => {
		setPoiGame({ poiId: poiId, visible: true });
	};

	const classes = useStyles();
	useEffect(() => {
		if (!poiGame.visible) {
			setSubheader({
				left: (
					<Button
						variant="text"
						className={classes.backButton}
						onClick={() => setViewMode(TEACHER_VIEW_MODE.INDEX)}
					>
						<ArrowBack style={{ fontSize: 12, marginRight: '0.25rem' }} />
						Wróć do listy zestawów
					</Button>
				),
				title: 'Szczegoły zestawu'
			});
		}
	}, [classes.backButton, setSubheader, setViewMode, poiGame.visible]);

	const goBackToSet = useCallback(() => {
		setPoiGame({ visible: false });
	}, []);

	if (!setsSelection.selectedSet) return <></>;
	if (loading) return <Loader />;
	if (poiGame.visible) return <NotRatedGame goBack={goBackToSet} poiId={poiGame.poiId} />;
	return (
		<>
			<div className={classes.infobar}>
				<div className={classes.infogroup}>
					<div className={classes.hinfo}>Nazwa zestawu</div>
					<div className={classes.info}>{setResp?.name}</div>
				</div>
				<div className={classes.infogroup}>
					<div className={classes.hinfo}>Poziom</div>
					<div className={classes.info}>
						{setResp?.age_groups.map(a => renderAgeGroup(a as IAgeGroup)).join(', ')}
					</div>
				</div>
				<div className={classes.infogroup}>
					<div className={classes.hinfo}>Kod zestawu</div>
					<div className={classes.info}>
						{setResp?.exchange_code}
						<>
							{setResp?.exchange_code && (
								<IconButton
									className={classes.copyButton}
									onClick={() => copyToClipboard(setResp?.exchange_code)}
								>
									<img src={copy} alt={''} />
								</IconButton>
							)}
						</>
					</div>
				</div>
				<Button
					variant="text"
					className={classes.deleteButton}
					onClick={() => openDeleteModal(true)}
				>
					<>Usuń zestaw</>
					<Close />
				</Button>
			</div>

			<div className={classes.setsLabel}>Zagadnienia w zestawie</div>
			<div className={classes.content}>
				<div className={classes.headers}>
					<div className={classes.hname}>Nazwa zestawu</div>
					<div className={classes.hcity}>Miasto</div>
					<div className={classes.hage}>Grupa wiekowa</div>
					<div className={classes.htrack}>Trasa</div>
					<div className={classes.htags}>Typ gry</div>
					<div className={classes.hactions}>Akcje</div>
				</div>
				<div className={classes.details}>
					{setResp?.excercises.map((ex: IExercise) => {
						return (
							<div className={classes.detail} key={ex.id}>
								<div className={classes.name}>
									{ex.poi.name}
									<PoiTooltip
										message={ex.poi.tooltip_message}
										gameType={ex.game.game_type}
									>
										<HelpOutline
											style={{ fontSize: 14, marginLeft: '0.25rem' }}
											color={'disabled'}
										/>
									</PoiTooltip>
								</div>
								<div className={classes.city}>{ex.city.name}</div>
								<div className={classes.age}>
									{renderAgeGroup(ex.track.age_group)}
								</div>
								<div className={classes.track}>{ex.track.name}</div>
								<div className={classes.tags}>
									{renderGameType(ex.game.game_type as GAME)}
								</div>
								<div className={classes.actions}>
									<Button
										variant="text"
										onClick={() => playTheGame(ex.poi.id)}
										className={classes.playButton}
									>
										Zagraj
										<ArrowForward
											style={{ fontSize: 12, marginLeft: '0.25rem' }}
										/>
									</Button>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<DeleteSet isOpened={deleteModal} close={() => openDeleteModal(false)} />
		</>
	);
};

export default memo(SetInfo);
