import React, { FunctionComponent, memo, ReactElement } from 'react';
import { makeStyles, Toolbar } from '@material-ui/core';
import {
	FOOTER_HEIGHT,
	footerFont,
	footerGrey,
	HEADER_PADDING,
	poppins
} from '../../../styles/variables';
import { openInNewTab } from '../../../utils/openInNewTab';

const useStyles = makeStyles(() => ({
	footer: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		backgroundColor: footerGrey,
		color: footerFont,
		fontFamily: poppins,
		height: FOOTER_HEIGHT,
		minHeight: FOOTER_HEIGHT,
		width: '100%',
		fontWeight: 500,
		fontSize: '10px',
		letterSpacing: '0.38px',
		position: 'absolute',
		bottom: 0,
		left: 0,
		paddingLeft: HEADER_PADDING,
		paddingRight: HEADER_PADDING,
		boxSizing: 'border-box'
	},
	link: {
		cursor: 'pointer',
		marginRight: '1rem'
	}
}));

const Footer: FunctionComponent = (): ReactElement => {
	const classes = useStyles();

	return (
		<Toolbar className={classes.footer}>
			<div
				className={classes.link}
				onClick={() => openInNewTab('https://responsivevoice.org/')}
			>
				Voice powered by ResponsiveVoice-NonCommercial
			</div>
			<div>© ORPEG 2020</div>
		</Toolbar>
	);
};

export default memo(Footer);
