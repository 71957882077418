import React, { FunctionComponent, memo, ReactElement, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { IEmptyGameData } from '../../../../constants/types';

interface IProps {
	gameData: IEmptyGameData;
	gameId: number;
	finishGame: (gameId: number, points: number) => void;
}

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '1rem',
		flexDirection: 'column'
	}
}));

const Empty: FunctionComponent<IProps> = ({ gameData, gameId, finishGame }): ReactElement => {
	const classes = useStyles();
	useEffect(() => {
		finishGame(gameId, 0);
	}, [finishGame, gameId]);

	return <div className={classes.root} />;
};

export default memo(Empty);
