import { makeStyles } from '@material-ui/core';
import {
	FOOTER_HEIGHT,
	lineRed,
	mainRed,
	NAVBAR_HEIGHT,
	poppins,
	SUBHEADER_HEIGHT,
	subheaderGrey
} from '../../styles/variables';

export const useStyles = makeStyles(() => ({
	root: {
		width: `calc(100vw - 30rem)`,
		maxHeight: `calc(100vh - ${FOOTER_HEIGHT} - ${NAVBAR_HEIGHT} - ${SUBHEADER_HEIGHT})`,
		position: 'relative',
		top: 0,
		left: '30rem',
		overflow: 'hidden'
	},
	imageback: {
		width: `calc(100vw - 30rem)`,
		maxHeight: `calc(100vh - ${NAVBAR_HEIGHT} - ${SUBHEADER_HEIGHT})`,
		position: 'relative',
		top: '-15px',
		left: 0
	},
	imagefront: {
		width: `calc(100vw - 30rem)`,
		maxHeight: `calc(100vh - ${NAVBAR_HEIGHT} - ${SUBHEADER_HEIGHT})`,
		position: 'absolute',
		top: '-15px',
		left: 0
	},
	point: {
		fill: subheaderGrey,
		cursor: 'pointer'
	},
	outline: { fill: 'none', stroke: subheaderGrey, strokeWidth: '0.5', cursor: 'pointer' },

	selectedPoint: {
		fill: mainRed
	},
	selectedOutline: {
		stroke: mainRed
	},
	line: {
		fill: 'none',
		stroke: lineRed,
		strokeWidth: 4,
		strokeLinecap: 'round',
		strokeDasharray: '10,10'
	},
	mapText: {
		fill: subheaderGrey,
		fontFamily: poppins,
		fontSize: '1.5rem',
		fontWeight: 600,
		cursor: 'pointer'
	},
	textBackground: {
		fill: 'transparent',
		cursor: 'pointer'
	}
}));
