import React, { FunctionComponent, memo, ReactElement } from 'react';
import { makeStyles } from '@material-ui/core';
import { border, failRed, successGreen } from '../../../../styles/variables';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column'
	},
	dot: {
		height: '11px',
		width: '11px',
		backgroundColor: failRed,
		borderRadius: '50%',
		display: 'inline-block'
	},
	line: {
		width: '1px',
		backgroundColor: failRed,
		height: '50px',
		display: 'inline-block'
	},
	correct: {
		border: border(successGreen)
	},
	incorrect: {
		border: border(failRed)
	}
}));

const Separator: FunctionComponent = (): ReactElement => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<span className={classes.dot} />
			<span className={classes.line} />
			<span className={classes.dot} />
		</div>
	);
};

export default memo(Separator);
