import React, { ChangeEvent, FunctionComponent, memo, ReactElement } from 'react';
import { makeStyles, TextField } from '@material-ui/core';
import { mainRed } from '../../styles/variables';

const useStyles = makeStyles(() => ({
	error: {
		fontSize: '0.8rem',
		color: mainRed,
		width: '100%'
	}
}));

interface IProps {
	name: string;
	label?: string;
	onChange?: (
		event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
	) => void;
	className?: string;
	inputClass?: string;
	value: string | number;
	error?: string;
	touched?: boolean;
	disabled?: boolean;
	type?: string;
	fullWidth?: boolean;
	margin?: 'normal' | 'none' | 'dense' | undefined;
	inputProps?: any;
	placeholder?: string;
}

const TextInput: FunctionComponent<IProps> = ({
	name,
	label,
	onChange,
	className = '',
	value,
	error,
	touched,
	disabled = false,
	type = 'text',
	fullWidth = true,
	inputClass = '',
	margin = 'normal',
	placeholder = '',
	inputProps
}): ReactElement => {
	const showError = Boolean(error && touched);
	const classes = useStyles();

	return (
		<>
			<TextField
				autoFocus={false}
				className={className}
				type={type}
				variant="outlined"
				margin={margin}
				fullWidth={fullWidth}
				id={name}
				label={label}
				name={name}
				onChange={onChange}
				value={value}
				placeholder={placeholder}
				disabled={disabled}
				InputProps={{
					...inputProps,
					classes: {
						input: inputClass
					}
				}}
			/>
			{showError && <div className={classes.error}>{error}</div>}
		</>
	);
};

export default memo(TextInput);
