import React, { FunctionComponent, memo, ReactElement, ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import {
	backgroundGrey,
	FOOTER_HEIGHT,
	NAVBAR_HEIGHT,
	SUBHEADER_HEIGHT
} from '../../styles/variables';

interface IProps {
	children: ReactNode;
}

const useStyles = makeStyles(() => ({
	contentContainer: {
		height: `calc(100vh - ${NAVBAR_HEIGHT} - ${FOOTER_HEIGHT} - ${SUBHEADER_HEIGHT})`,
		overflow: 'auto',
		backgroundColor: backgroundGrey,
		position: 'relative'
	}
}));

const ContentContainer: FunctionComponent<IProps> = ({ children }): ReactElement => {
	const classes = useStyles();
	return <div className={classes.contentContainer}>{children}</div>;
};
export default memo(ContentContainer);
