import React, {
	Dispatch,
	FunctionComponent,
	memo,
	ReactElement,
	SetStateAction,
	useCallback,
	useMemo
} from 'react';
import { useStyles } from './Quiz.style';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { IAnswer } from '../../../../constants/types';

interface IProps {
	answer: IAnswer;
	showResult: boolean;
	isCorrect: boolean;
	setSelectedAnswer: Dispatch<SetStateAction<IAnswer | undefined>>;
	selectedAnswer: IAnswer | undefined;
}

const Answer: FunctionComponent<IProps> = ({
	answer,
	showResult,
	isCorrect,
	setSelectedAnswer,
	selectedAnswer
}): ReactElement => {
	const classes = useStyles();

	const selectAnswer = useCallback(
		(answer: IAnswer) => {
			if (showResult) return;
			setSelectedAnswer(answer);
		},
		[setSelectedAnswer, showResult]
	);

	const isSelected = useMemo((): boolean => {
		return Boolean(selectedAnswer && selectedAnswer.answer === answer.answer);
	}, [selectedAnswer, answer]);

	if (answer.type === 'image') {
		return (
			<img
				className={clsx(
					classes.imageAnswer,
					isSelected && !showResult && classes.selected,
					showResult && isCorrect && classes.correct,
					isSelected && showResult && !isCorrect && classes.incorrect
				)}
				onClick={() => selectAnswer(answer)}
				src={answer.answer}
				alt={`ans-${answer.answer.substring(0, 6)}`}
			/>
		);
	}
	return (
		<Button
			type="button"
			fullWidth
			variant="outlined"
			className={clsx(
				classes.textAnswer,
				isSelected && !showResult && classes.selected,
				showResult && isCorrect && classes.correct,
				isSelected && showResult && !isCorrect && classes.incorrect
			)}
			onClick={() => selectAnswer(answer)}
		>
			{answer.answer}
		</Button>
	);
};

export default memo(Answer);
