import { makeStyles } from '@material-ui/core';
import React, { FunctionComponent, memo, ReactElement, useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { fontGrey75, fontGrey, poppins, roboto } from '../../styles/variables';
import Loader from '../shared/Loader';

const useStyles = makeStyles(() => ({
	root: { display: 'flex', justifyContent: 'center' },
	content: { width: '36rem', display: 'flex', flexDirection: 'column', marginTop: '5rem' },
	label: {
		fontFamily: roboto,
		fontSize: '1rem',
		letterSpacing: '0.53px',
		color: fontGrey75
	},
	text: {
		color: fontGrey,
		fontFamily: poppins,
		fontSize: '1.25rem',
		fontWeight: 'bold',
		letterSpacing: '0.6px',
		marginTop: '1rem',
		marginBottom: '1.5rem'
	}
}));

const Info: FunctionComponent = (): ReactElement => {
	const { user } = useContext(UserContext);

	const classes = useStyles();
	if (!user) return <Loader />;
	return (
		<div className={classes.root}>
			<div className={classes.content}>
				<div className={classes.label}>Login</div>
				<div className={classes.text}>{user.nickname || '---'}</div>
				<div className={classes.label}>Email</div>
				<div className={classes.text}>{user.email || '---'}</div>
				<div className={classes.label}>Kraj</div>
				<div className={classes.text}>{user.country || '---'}</div>
				<div className={classes.label}>Grupa wiekowa</div>
				<div className={classes.text}>{user.age_group || '---'}</div>
			</div>
		</div>
	);
};

export default memo(Info);
