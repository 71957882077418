import React, {
	ChangeEvent,
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import { Button, makeStyles, MenuItem } from '@material-ui/core';
import { getAllTracks, getRankings } from '../../api';
import { AxiosResponse } from 'axios';
import {
	ageGroups,
	IAgeGroup,
	ICountry,
	IRanking,
	ITrack,
	renderAgeGroup
} from '../../constants/types';
import Loader from '../shared/Loader';
import {
	border,
	fontGrey,
	fontGrey10,
	fontGrey75,
	lightGrey,
	mainRed,
	poppins,
	roboto,
	white
} from '../../styles/variables';
import SelectInput from '../shared/SelectInput';
import medal from '../../assets/medals/medal.png';
import medal2 from '../../assets/medals/medal2.png';
import medal3 from '../../assets/medals/medal3.png';
import useCountries from '../../hooks/useCountries';
import { SubheaderContext } from '../../context/SubheaderContext';

const PADDING = '20%';

const useStyles = makeStyles(() => ({
	subheader: {
		backgroundColor: lightGrey,
		paddingLeft: PADDING,
		paddingRight: PADDING,
		height: '7rem',
		boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.07)',
		paddingTop: '1rem'
	},
	filterTitle: {
		color: fontGrey,
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 'bold',
		letterSpacing: '0.56px'
	},
	filters: {
		marginTop: '0.5rem',
		display: 'flex',
		justifyContent: 'space-between'
	},
	clearFilters: {
		fontFamily: poppins,
		fontSize: '1rem',
		fontWeight: 600,
		letterSpacing: '0.40px',
		color: mainRed,
		textTransform: 'capitalize'
	},
	select: {
		width: '23%'
	},
	content: {
		height: `calc(100% - 8rem)`,
		paddingLeft: PADDING,
		paddingRight: PADDING,
		backgroundColor: white,
		position: 'relative'
	},
	scores: {
		height: 'calc(100% - 4.25rem)',
		overflow: 'scroll'
	},
	headers: {
		width: '100%',
		height: '4.25rem',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		color: fontGrey75,
		alignItems: 'center',
		borderBottom: border(fontGrey10)
	},
	hposition: {
		flex: 1
	},
	hname: {
		flex: 5
	},
	hage: {
		flex: 2
	},
	hcountry: {
		flex: 3
	},
	htotalScore: {
		flex: 2
	},
	score: {
		width: '100%',
		height: '4.25rem',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: fontGrey,
		borderBottom: border(fontGrey10)
	},
	position: {
		flex: 1,
		display: 'flex',
		justifyContent: 'flex-start'
	},
	name: {
		fontFamily: roboto,
		flex: 5
	},
	age: {
		fontFamily: roboto,
		flex: 2
	},
	country: {
		fontFamily: roboto,
		flex: 3
	},
	totalScore: {
		fontFamily: poppins,
		fontWeight: 'bold',
		flex: 2
	}
}));

const Ranking: FunctionComponent = (): ReactElement => {
	const [scoresLoading, setScoresLoading] = useState<boolean>(true);
	const [tracksLoading, seTracksLoading] = useState<boolean>(true);
	const [scores, setScores] = useState<IRanking[]>([]);
	const [country, setCountry] = useState<string | undefined>(undefined);
	const [tracks, setTracks] = useState<ITrack[]>([]);
	const [trackId, setTrackId] = useState<number | undefined>(undefined);
	const [ageGroup, setAgeGroup] = useState<IAgeGroup | undefined>(undefined);
	const { countries, countriesLoading } = useCountries();
	const { setSubheader } = useContext(SubheaderContext);

	useEffect(() => {
		setSubheader({ title: 'Ranking wyników' });
	}, [setSubheader]);

	useEffect(() => {
		getAllTracks()
			.then((resp: AxiosResponse<ITrack[]>) => {
				seTracksLoading(false);
				setTracks(resp.data);
			})
			.catch(e => {
				seTracksLoading(false);
			});
	}, []);

	useEffect(() => {
		if (!countriesLoading && !tracksLoading) {
			getRankings({
				params: {
					age_group: ageGroup,
					track_id: trackId,
					country: country
				}
			})
				.then((resp: AxiosResponse<{ ranking: IRanking[] }>) => {
					setScoresLoading(false);
					if (resp.data) setScores(resp.data.ranking);
				})
				.catch(e => {
					setScoresLoading(false);
				});
		} else {
			setScoresLoading(false);
		}
	}, [countriesLoading, tracksLoading, ageGroup, trackId, country]);

	const clearFilters = useCallback(() => {
		setAgeGroup(undefined);
		setTrackId(undefined);
		setCountry(undefined);
	}, []);

	const renderPosition = (position: number): ReactElement | number => {
		switch (position) {
			case 1:
				return <img src={medal} alt={'medal1'} height="30" />;
			case 2:
				return <img src={medal2} alt={'medal2'} height="30" />;
			case 3:
				return <img src={medal3} alt={'medal3'} height="30" />;
			default:
				return position;
		}
	};

	const classes = useStyles();
	if (scoresLoading || countriesLoading) return <Loader />;
	return (
		<>
			<div className={classes.subheader}>
				<div className={classes.filterTitle}>Filtrowanie</div>
				<div className={classes.filters}>
					<SelectInput
						name={'country'}
						id={'country'}
						value={country ? country : ''}
						className={classes.select}
						handleChange={(evt: ChangeEvent<HTMLInputElement>) => {
							setCountry(evt.target.value);
						}}
						placeholder={'kraj'}
						options={countries.map((country: ICountry) => {
							return (
								<MenuItem key={country.code} value={country.name_pl}>
									{country.name_pl}
								</MenuItem>
							);
						})}
					/>

					<SelectInput
						name={'age'}
						id={'age'}
						value={ageGroup ? ageGroup : ''}
						className={classes.select}
						handleChange={(evt: ChangeEvent<HTMLInputElement>) => {
							setAgeGroup(evt.target.value as IAgeGroup);
						}}
						placeholder={'grupa wiekowa'}
						options={ageGroups.map((group: { v: string; n: string }) => {
							return (
								<MenuItem key={group.v} value={group.v}>
									{group.n}
								</MenuItem>
							);
						})}
					/>
					<SelectInput
						name={'track'}
						id={'track'}
						value={trackId ? trackId : ''}
						defaultValue={undefined}
						className={classes.select}
						placeholder={'trasa'}
						handleChange={(evt: ChangeEvent<HTMLInputElement>) => {
							setTrackId(Number(evt.target.value));
						}}
						options={tracks.map((track: ITrack) => {
							return (
								<MenuItem key={track.id} value={track.id}>
									{track.name}
								</MenuItem>
							);
						})}
					/>
					<Button variant="text" className={classes.clearFilters} onClick={clearFilters}>
						wyczyść filtry
					</Button>
				</div>
			</div>
			<div className={classes.content}>
				<div className={classes.headers}>
					<div className={classes.hposition}>Miejsce</div>
					<div className={classes.hname}>Nick</div>
					<div className={classes.hage}>Grupa wiekowa</div>
					<div className={classes.hcountry}>Kraj</div>
					<div className={classes.htotalScore}>Liczba punktów</div>
				</div>
				<div className={classes.scores}>
					{scores.map((score: IRanking) => {
						return (
							<div className={classes.score} key={score.id}>
								<div className={classes.position}>
									{renderPosition(Number(score.position))}
								</div>
								<div className={classes.name}>{score.nickname || '---'}</div>
								<div className={classes.age}>{renderAgeGroup(score.age_group)}</div>
								<div className={classes.country}>{score.country}</div>
								<div className={classes.totalScore}>
									{score.total_score || '---'}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default memo(Ranking);
