import * as Yup from 'yup';

export default () =>
	Yup.object().shape({
		password: Yup.string()
			.min(6, 'Hasło musi się składać z conajmniej 6 znaków')
			.required('Hasło jest wymagane'),
		password_confirmation: Yup.string().oneOf(
			[Yup.ref('password'), undefined],
			'Hasła się nie zgadzają'
		)
	});
