import { createContext, ReactElement } from 'react';

export const SubheaderContext = createContext({} as ISubheaderProvider);

export interface ISubheaderContext {
	left?: ReactElement;
	title?: string;
	subtitle?: string;
	right?: ReactElement;
	hidden?: boolean;
}

export interface ISubheaderProvider {
	content: ISubheaderContext;
	setSubheader: (context: ISubheaderContext) => void;
}
