export enum CITY_NAME {
	WARSZAWA = 'Warszawa',
	KRAKOW = 'Kraków',
	POZNAN = 'Poznań',
	GNIEZNO = 'Gniezno',
	TORUN = 'Toruń',
	WISLA = 'Wisła',
	KAZIMIERZ = 'Kazimierz',
	MALBORK = 'Malbork',
	GDANSK = 'Gdańsk',
	LOWICZ = 'Łowicz',
	MYSZYNIEC = 'Myszyniec',
	LIPNICA = 'Lipnica Murowana',
	KATOWICE = 'Katowice',
	ZELAZOWA_WOLA = 'Żelazowa Wola',
	BOLKOW = 'Bolków',
	BELCHATOW = 'Bełchatów',
	ZAKOPANE = 'Zakopane',
	SOLINA = 'Solina',
	KARPACZ = 'Karpacz',
	WROCLAW = 'Wrocław',
	LODZ = 'Łódź',
	BIELSKO_BIALA = 'Bielsko-Biała',
	HEL = 'Hel',
	KADZIDLO = 'Kadzidło',
	WILANOW = 'Wilanów',
	SWIDNICA = 'Świdnica',
	KSIAZ = 'Książ',
	LUBLIN = 'Lublin',
	SOBIBOR = 'Sobibór',
	MAJDANEK = 'Majdanek',
	ZAMOSC = 'Zamość',
	KRZYZTOPOR = 'Krzyżtopór',
	OSWIECIM = 'Oświęcim',
	NIEDZICA = 'Niedzica',
	TREBLINKA = 'Treblinka'
}
