import * as Yup from 'yup';

export default () =>
	Yup.object().shape({
		email: Yup.string().email('Podaj poprawny adres email').required('Email jest wymagany'),
		password: Yup.string().min(6, 'Hasło jest za krótkie').required('Hasło jest wymagane'),
		password_confirmation: Yup.string().oneOf(
			[Yup.ref('password'), undefined],
			'Hasła się nie zgadzają'
		),
		invitation_code: Yup.string().required('kod jest wymagany')
	});
