import { makeStyles } from '@material-ui/core';
import {
	border,
	fontGrey,
	fontGrey10,
	fontGrey75,
	lightGrey,
	mainRed,
	mainRed50,
	poppins,
	roboto,
	white
} from '../../styles/variables';
import { PADDING } from '../../constants/sets';

export const useStyles = makeStyles(() => ({
	subheader: {
		backgroundColor: lightGrey,
		paddingLeft: PADDING,
		paddingRight: PADDING,
		height: '6rem',
		boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.07)',
		paddingTop: '0.25rem'
	},
	label: {
		fontSize: '1rem',
		color: fontGrey75,
		paddingTop: '0.75rem',
		paddingBottom: '0.5rem'
	},
	infoBar: {
		display: 'flex',
		flexDirection: 'column',
		height: '12rem',
		paddingLeft: PADDING,
		paddingRight: PADDING,
		backgroundColor: white
	},
	row: {
		paddingBottom: '0.5rem',
		display: 'flex',
		justifyContent: 'space-between'
	},
	textInput: {
		width: '70%',
		height: '3.6rem',
		margin: 0
	},
	input: {
		padding: '1.2rem'
	},
	button: {
		width: '20%',
		letterSpacing: '0.45px',
		height: '3.6rem',
		fontWeight: 600,
		textTransform: 'capitalize',
		fontFamily: poppins,
		borderRadius: '4px',
		'&:hover': {
			backgroundColor: mainRed50,
			color: white
		},
		backgroundColor: mainRed,
		color: white
	},
	content: {
		height: `calc(100% - 6.25rem - 2.5rem - 11.85rem)`,
		paddingLeft: PADDING,
		paddingRight: PADDING,
		backgroundColor: white
	},
	setsLabel: {
		paddingLeft: PADDING,
		paddingRight: PADDING,
		backgroundColor: white,
		paddingTop: '0.2rem',
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 'bold',
		lineHeight: '1.8',
		letterSpacing: '0.52px',
		color: fontGrey
	},
	hinfo: {
		color: fontGrey75
	},
	info: {
		color: fontGrey,
		fontFamily: roboto
	},
	headers: {
		width: '100%',
		height: '2.25rem',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		color: fontGrey75,
		alignItems: 'center',
		borderBottom: border(fontGrey10)
	},
	hname: { flex: 4 },
	hcity: { flex: 2 },
	hage: { flex: 2 },
	htrack: { flex: 2 },
	htags: { flex: 1 },
	hactions: { flex: 1 },
	hadd: { flex: 1 },
	details: {
		height: 'calc(100% - 2.25rem)',
		overflow: 'scroll'
	},
	detail: {
		width: '100%',
		height: '3.75rem',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: fontGrey,
		borderBottom: border(fontGrey10),
		fontFamily: roboto
	},
	name: { flex: 4, display: 'flex' },
	city: { flex: 2 },
	age: { flex: 2 },
	track: { flex: 2 },
	tags: { flex: 1 },
	actions: { flex: 1 },
	add: { flex: 1 },
	backButton: {
		fontFamily: poppins,
		fontWeight: 500,
		textTransform: 'unset',
		letterSpacing: '0.45px',
		color: white
	},
	filterTitle: {
		color: fontGrey,
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 'bold',
		letterSpacing: '0.56px'
	},
	filters: {
		marginTop: '0.5rem',
		display: 'flex',
		justifyContent: 'space-between'
	},
	clearFilters: {
		fontFamily: poppins,
		fontSize: '1rem',
		fontWeight: 600,
		letterSpacing: '0.40px',
		color: mainRed,
		textTransform: 'capitalize'
	},
	select: {
		width: '20%'
	},
	playButton: {
		textTransform: 'unset',
		color: mainRed,
		padding: '1px',
		minWidth: '40px',
		fontSize: '14px'
	},
	selectedPois: {
		display: 'flex'
	},
	selectedPoi: {
		border: border(mainRed),
		borderRadius: '3px',
		padding: '1rem',
		height: '1rem',
		maxWidth: '12rem',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginRight: '0.25rem'
	},
	selectedPoiName: {
		textOverflow: 'ellipsis',
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		fontFamily: poppins,
		fontSize: '1rem',
		fontWeight: 600,
		color: mainRed,
		maxWidth: '10rem'
	},
	setsPoisLabel: {
		backgroundColor: white,
		paddingTop: '0.25rem',
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 'bold',
		lineHeight: '2',
		letterSpacing: '0.52px',
		color: fontGrey
	}
}));
