import React, { FunctionComponent, memo, ReactElement, useContext, useMemo } from 'react';
import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useStyles } from './GameRulesDialog.style';
import { GAME, IGame, renderGameType } from '../../constants/types';
import { SelectionContext } from '../../context/SelectionContext';

interface IProps {
	isOpened: boolean;
	close: () => void;
	game: IGame;
}

const GameRulesDialog: FunctionComponent<IProps> = ({ isOpened, close, game }): ReactElement => {
	const classes = useStyles();
	const { selection } = useContext(SelectionContext);

	return (
		<Dialog maxWidth={'lg'} open={isOpened} onClose={close} aria-labelledby="rules-modal">
			<DialogTitle className={classes.title} id="rules-modal" disableTypography>
				<div>
					<div className={classes.titleText}>Minigra</div>
					<div className={classes.subTitleText}>{selection.selectedPoi?.name}</div>
				</div>
				<IconButton className={classes.close} onClick={close}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent className={classes.content}>
				<div className={classes.contentRoot}>
					<div className={classes.gameTitle}>{renderGameType(game.game_type)}</div>
					<div className={classes.text}>{game.instructions || ''}</div>
				</div>
				<Button
					variant="contained"
					className={classes.button}
					color={'primary'}
					onClick={close}
				>
					Zagraj
				</Button>
			</DialogContent>
		</Dialog>
	);
};

export default memo(GameRulesDialog);
