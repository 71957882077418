import { makeStyles } from '@material-ui/core';
import {
	fontGrey,
	fontGrey75,
	poppins,
	roboto,
	subheaderGrey,
	white
} from '../../styles/variables';

export const useStyles = makeStyles(() => ({
	title: {
		backgroundColor: subheaderGrey,
		color: white,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	titleText: {
		fontWeight: 900,
		fontSize: '2rem',
		fontFamily: poppins,
		letterSpacing: '0.45px',
		textAlign: 'center',
		textTransform: 'uppercase'
	},
	subTitleText: {
		fontWeight: 'bold',
		fontSize: '1.25rem',
		fontFamily: poppins,
		letterSpacing: '0.06px',
		textAlign: 'center'
	},
	close: {
		color: white,
		fontSize: '1rem',
		position: 'absolute',
		right: '1rem'
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '55rem'
	},
	button: {
		marginTop: '2rem',
		marginBottom: '2rem',
		fontFamily: poppins,
		fontSize: '1rem',
		textTransform: 'capitalize',
		color: white,
		letterSpacing: '0.45px',
		fontWeight: 600,
		width: '20rem',
		height: '3.6rem'
	},
	contentRoot: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
	gameTitle: {
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 'bold',
		lineHeight: 2.67,
		letterSpacing: '0.56px',
		color: fontGrey
	},
	text: {
		textAlign: 'center',
		marginTop: '1rem',
		color: fontGrey75,
		fontFamily: roboto,
		fontSize: '1.2rem',
		lineHeight: 1.4,
		letterSpacing: '0.52px',
		paddingLeft: '10rem',
		paddingRight: '10rem'
	},
	image: {}
}));
