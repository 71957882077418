import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useMemo,
	useState
} from 'react';
import Sets from './Sets';
import SetInfo from './SetInfo';
import NewSet from './NewSet';
import { ITeacherContext, ITeacherProvider, TeacherContext } from '../../context/TeacherContext';
import { ISet, TEACHER_VIEW_MODE } from '../../constants/types';
import useSessionValidation from '../../hooks/useSessionValidation';
import Loader from '../shared/Loader';

const Teacher: FunctionComponent = (): ReactElement => {
	const { loading } = useSessionValidation();
	const [viewMode, setViewMode] = useState<TEACHER_VIEW_MODE>(TEACHER_VIEW_MODE.INDEX);
	const [setsSelection, setSetsSelection] = useState<ITeacherContext>({ viewMode: viewMode });

	const selectSet = useCallback((set: ISet) => {
		setSetsSelection((prevState: ITeacherContext) => ({
			...prevState,
			selectedSet: set
		}));
	}, []);

	const unselectSet = useCallback(() => {
		setSetsSelection((prevState: ITeacherContext) => ({
			...prevState,
			selectedSet: undefined
		}));
	}, []);

	const teacherProviderValue: ITeacherProvider = useMemo(
		() => ({
			setsSelection,
			selectSet,
			unselectSet,
			setViewMode
		}),
		[setsSelection, selectSet, unselectSet, setViewMode]
	);

	if (loading) return <Loader />;
	return (
		<TeacherContext.Provider value={teacherProviderValue}>
			{viewMode === TEACHER_VIEW_MODE.INDEX && <Sets />}
			{viewMode === TEACHER_VIEW_MODE.CREATE && <NewSet />}
			{viewMode === TEACHER_VIEW_MODE.SHOW && <SetInfo />}
		</TeacherContext.Provider>
	);
};

export default memo(Teacher);
