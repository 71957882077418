import React, { FunctionComponent, memo, ReactElement, useContext, useEffect } from 'react';
import { SubheaderContext } from '../../context/SubheaderContext';
import { makeStyles } from '@material-ui/core';
import { fontGrey, poppins } from '../../styles/variables';
import img3 from './3.png';
import img2 from './2.png';
import img1 from './1.png';
import { openInNewTab } from '../../utils/openInNewTab';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100%'
	},
	textRoot: {
		display: 'flex',
		flexDirection: 'column',
		width: '30%'
	},
	title: {
		color: fontGrey,
		fontFamily: poppins,
		fontSize: '2rem',
		fontWeight: 'bold',
		marginBottom: '2rem'
	},
	text: {
		color: fontGrey,
		fontFamily: poppins,
		fontWeight: 500,
		marginBottom: '1.5rem',
		paddingRight: '2rem'
	},
	imagesRoot: { marginLeft: '2rem', width: '300px', height: '300px' },
	img3: {
		width: '220px',
		zIndex: 1,
		position: 'relative',
		top: '-110px'
	},
	img2: { width: '230px', zIndex: 2, position: 'relative', left: '175px', top: '-300px' },
	img1: { width: '230px', zIndex: 3, position: 'relative', top: '-425px' },
	link: {
		color: fontGrey,
		textDecoration: 'underline',
		cursor: 'pointer'
	}
}));

const Info: FunctionComponent = (): ReactElement => {
	const { setSubheader } = useContext(SubheaderContext);
	const classes = useStyles();
	useEffect(() => {
		setSubheader({ title: 'Poznaj polskę' });
	}, [setSubheader]);

	return (
		<div className={classes.root}>
			<div className={classes.textRoot}>
				<div className={classes.title}>
					Projekt „Poznaj Polskę” to interaktywna gra familijna
				</div>
				<div className={classes.text}>
					Adresowana jest do nauczycieli realizujących nauczanie wiedzy o Polsce w
					szkołach polonijnych i polskich poza krajem. Odbiorcami są również uczniowie
					tychże szkół, którzy mieszkając poza Polską poznają jej tradycje, zwyczaje,
					historie i współczesność. Równie ważnym odbiorcą są polscy rodzice i dziadkowie,
					którzy pragną przybliżyć swoim pociechom piękno Ojczyzny.
				</div>
				<div className={classes.text}>
					Gra obejmuje trasy turystyczne dostosowane do wieku uczniów. Ich pokonanie wiąże
					się z odkrywaniem i poznawaniem kolejnych miejsc. Gromadzenie punktów na
					indywidualnym koncie motywuje do odkrywania kolejnych etapów.
				</div>
				<div className={classes.text}>
					Mamy nadzieję, że dzięki grze wirtualna podróż po Polsce stanie się okazją do
					barwnych opowieści i bodźcem do poznawania jej zakątków.
				</div>
				<div className={classes.text}>
					Skontaktuj się z nami, jeśli serwis nie działa prawidłowo:
					<div
						className={classes.link}
						onClick={() => openInNewTab('http://pomoc.orpeg.pl/')}
					>
						http://pomoc.orpeg.pl/
					</div>
				</div>
				<div className={classes.text}>
					Skontaktuj się z nami w każdej innej sprawie:{' '}
					<a className={classes.link} href="mailto:pcn_sekretariat@orpeg.pl">
						pcn_sekretariat@orpeg.pl
					</a>
				</div>
			</div>
			<div className={classes.imagesRoot}>
				<img className={classes.img3} src={img3} alt={''} />
				<img className={classes.img2} src={img2} alt={''} />
				<img className={classes.img1} src={img1} alt={''} />
			</div>
		</div>
	);
};

export default memo(Info);
