import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { SelectionContext } from '../../context/SelectionContext';
import { makeStyles, Toolbar } from '@material-ui/core';
import { ROUTES } from '../../routes/routes';
import { ArrowBack } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { mainRed, poppins, white } from '../../styles/variables';
import useSessionValidation from '../../hooks/useSessionValidation';
import Loader from '../shared/Loader';
import Result from './Result';
import GameDialog from './GameRulesDialog';
import { IGame, IWayPointFull } from '../../constants/types';
import TrackProgress from '../shared/TrackProgress';
import { submitScore } from '../../api';
import { AxiosResponse } from 'axios';
import { renderGame } from './games/renderGame';
import { SubheaderContext } from '../../context/SubheaderContext';
import NewCityDialog from './NewCityDialog';
import TrackFinishedDialog from './TrackFinishedDialog';

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: '10rem',
		paddingRight: '10rem'
	},
	backButton: {
		fontFamily: poppins,
		fontWeight: 500,
		textTransform: 'unset',
		letterSpacing: '0.45px',
		color: white
	},
	gamename: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: mainRed,
		color: white,
		fontFamily: poppins,
		fontSize: '1.25rem',
		fontWeight: 600,
		letterSpacing: '0.52px',
		height: '4rem'
	}
}));

const Game: FunctionComponent = (): ReactElement => {
	const { loading } = useSessionValidation();
	const history = useHistory();
	const [isGameFinished, setGameFinished] = useState<boolean>(false);
	const [showInstruction, setShowInstruction] = useState<boolean>(true);
	const [newCityDialog, setNewCityDialog] = useState<{ visible: boolean; cityName?: string }>({
		visible: false
	});
	const [trackFinishedDialog, setTrackFinishedDialog] = useState<{
		visible: boolean;
		track_completed_data?: {
			name: string;
			badge_image_url: string;
			cities_names: string[];
			user_score: number;
		};
	}>({
		visible: false
	});
	const [gamePoints, setGamePoints] = useState<number>(0);
	const { selection } = useContext(SelectionContext);
	const game: IGame | undefined = selection.selectedPoi && selection.selectedPoi.game;
	const { setSubheader } = useContext(SubheaderContext);
	const classes = useStyles();

	const selectedTrackName = useMemo(() => {
		return selection.selectedTrack?.name || 'Trasa';
	}, [selection.selectedTrack]);

	const selectedCityName = useMemo(() => {
		return selection.selectedCity?.name || 'Miasto';
	}, [selection.selectedCity]);

	useEffect(() => {
		setSubheader({
			left: (
				<Link className={classes.backButton} to={ROUTES.track}>
					<ArrowBack style={{ fontSize: 12, marginRight: '0.25rem' }} />
					Powrót do trasy
				</Link>
			),
			title: selectedTrackName,
			subtitle: selectedCityName,
			right: <TrackProgress />
		});
	}, [classes.backButton, selectedCityName, selectedTrackName, setSubheader]);

	const closeInstructionModal = useCallback(() => {
		setShowInstruction(false);
	}, []);

	useEffect(() => {
		if (!selection.selectedTrack || !game) {
			history.replace(ROUTES.main);
		}
	}, [history, selection.selectedTrack, game]);

	const finishGame = (gameId: number, points: number) => {
		submitScore({ params: { game_id: gameId, points: points } })
			.then(
				(
					resp: AxiosResponse<{
						id: number;
						points: number;
						new_waypoint_unlocked: boolean;
						unlocked_waypoint?: IWayPointFull;
						track_completed: boolean;
						track_completed_data?: {
							name: string;
							badge_image_url: string;
							cities_names: string[];
							user_score: number;
						};
					}>
				) => {
					setGameFinished(true);
					setGamePoints(resp.data.points);
					if (resp.data.new_waypoint_unlocked) {
						setNewCityDialog({
							visible: true,
							cityName: resp.data.unlocked_waypoint?.city.name
						});
					}
					if (resp.data.track_completed) {
						setTrackFinishedDialog({
							visible: true,
							track_completed_data: resp.data.track_completed_data
						});
					}
				}
			)
			.catch(e => {
				console.log('game error', e);
				setGameFinished(true);
			});
	};

	const finishAlreadyPlayedGame = (gameId: number, points: number) => {
		setGameFinished(true);
		setGamePoints(points);
	};

	if (loading) return <Loader />;
	if (!selection.selectedTrack) return <></>;
	if (!game) return <></>;
	return (
		<>
			<GameDialog
				isOpened={Boolean(game.instructions?.length) && showInstruction}
				close={closeInstructionModal}
				game={game}
			/>
			<Toolbar className={classes.gamename}>
				<div>{selection.selectedPoi?.name}</div>
			</Toolbar>
			{isGameFinished ? (
				<Result gamePoints={gamePoints} />
			) : (
				renderGame(game, game.already_played ? finishAlreadyPlayedGame : finishGame)
			)}
			<NewCityDialog
				isOpened={newCityDialog.visible}
				close={() => setNewCityDialog({ visible: false })}
				cityName={newCityDialog.cityName || ''}
			/>
			<TrackFinishedDialog
				isOpened={trackFinishedDialog.visible}
				close={() => setTrackFinishedDialog({ visible: false })}
				track_completed_data={trackFinishedDialog.track_completed_data}
			/>
		</>
	);
};

export default memo(Game);
