import React, { FunctionComponent, memo, ReactElement } from 'react';
import { Button } from '@material-ui/core';
import { useStyles } from './SwitchButton.style';
import clsx from 'clsx';

interface IProps {
	value: boolean;
	leftAction: () => void;
	rightAction: () => void;
	className?: string;
	disabled?: boolean;
	texts: {
		left: string;
		right: string;
	};
}

const SwitchButton: FunctionComponent<IProps> = ({
	value,
	leftAction,
	rightAction,
	className = '',
	disabled = false,
	texts
}): ReactElement => {
	const classes = useStyles();

	// const selectedColor = classes.selectedColor;
	// const deselectedColor = classes.deselectedColor;

	return (
		<div className={clsx(classes.root, className, disabled && classes.disabledButtons)}>
			<Button
				className={clsx(
					classes.button,
					value ? classes.selectedButton : classes.deselectedButton
				)}
				type="button"
				onClick={leftAction}
			>
				{texts.left}
			</Button>
			<Button
				className={clsx(
					classes.button,
					!value ? classes.selectedButton : classes.deselectedButton
				)}
				type="button"
				onClick={rightAction}
			>
				{texts.right}
			</Button>
		</div>
	);
};

export default memo(SwitchButton);
