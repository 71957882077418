import React, { ComponentType } from 'react';
import Info from '../components/info/Info';
import { Map } from '../components/map';
import { Login, SignUp } from '../components/auth';
import Track from '../components/track/Track';
import Game from '../components/game/Game';
import Ranking from '../components/ranking/Ranking';
import Profile from '../components/profile/Profile';
import Teacher from '../components/teacher/Teacher';
import NotRatedGame from '../components/game/NotRatedGame';
import Sets from '../components/sets/Sets';
import PasswordReset from '../components/auth/PasswordReset';

export interface IRoute {
	path: ROUTES;
	exact?: boolean;
	main: ComponentType;
}

export enum ROUTES {
	main = '/',
	track = '/track',
	login = '/login',
	signup = '/signup',
	rankings = '/rankings',
	info = '/info',
	game = '/game',
	setGame = '/setgame',
	profile = '/profile',
	teacher = '/teacher',
	sets = '/sets',
	password_reset = '/passwordreset'
}

// @ts-ignore
export const routes: IRoute[] = [
	{
		path: ROUTES.main,
		exact: true,
		main: () => <Map />
	},
	{
		path: ROUTES.track,
		exact: true,
		main: () => <Track />
	},
	{
		path: ROUTES.game,
		exact: true,
		main: () => <Game />
	},
	{
		path: ROUTES.setGame,
		exact: false,
		main: () => <NotRatedGame />
	},
	{
		path: ROUTES.login,
		exact: true,
		main: () => <Login />
	},
	{
		path: ROUTES.password_reset,
		exact: true,
		main: () => <PasswordReset />
	},
	{
		path: ROUTES.signup,
		exact: true,
		main: () => <SignUp />
	},
	{
		path: ROUTES.info,
		exact: true,
		main: () => <Info />
	},
	{
		path: ROUTES.teacher,
		exact: true,
		main: () => <Teacher />
	},
	{
		path: ROUTES.rankings,
		exact: true,
		main: () => <Ranking />
	},
	{
		path: ROUTES.sets,
		exact: true,
		main: () => <Sets />
	},
	{
		path: ROUTES.profile,
		exact: true,
		main: () => <Profile />
	},
	{
		path: ROUTES.main,
		exact: false,
		main: () => <Map />
	}
];
