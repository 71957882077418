import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useContext,
	useEffect,
	useState
} from 'react';
import { Button, Paper, Typography } from '@material-ui/core';
import { ROUTES } from '../../routes/routes';
import { Link, useHistory } from 'react-router-dom';
import { ArrowForward, Facebook } from '@material-ui/icons';
import { SubmitButton, SwitchButton } from '../shared/buttons';
import { useFormik } from 'formik';
import { IUser, UserContext } from '../../context/UserContext';
import { facebookLogin, ILoginPayload, login, requestPasswordReset } from '../../api';
import { AxiosResponse } from 'axios';
import { IFacebookResponse, TOKEN, USER } from '../../constants/session';
import { useStyles } from './Auth.style';
import loginValidator from './loginValidator';
import TextInput from '../shared/TextInput';
import { SubheaderContext } from '../../context/SubheaderContext';
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import SecondStepSignUpForm from './SecondStepSignUpForm';
import { mainRed } from '../../styles/variables';
import PasswordDialog from './PasswordDialog';

const Login: FunctionComponent = (): ReactElement => {
	const { setUser } = useContext(UserContext);
	const [secondStepDisplayed, setSecondStepDisplayed] = useState<boolean>(false);
	const [userData, setUserData] = useState<IUser | undefined>(undefined);
	const [isStudent, setIsStudent] = useState<boolean>(true);
	const [error, setError] = useState<string | undefined>(undefined);
	const [passwordModal, setPasswordModal] = useState<boolean>(false);
	const history = useHistory();
	const { setSubheader } = useContext(SubheaderContext);
	useEffect(() => {
		setSubheader({
			hidden: true
		});
	}, [setSubheader]);

	const formik = useFormik({
		initialValues: {
			email: '',
			password: ''
		} as ILoginPayload,
		onSubmit: (values: ILoginPayload) => {
			setError(undefined);
			login({ params: values })
				.then((resp: AxiosResponse<{ user: IUser }>) => {
					setUser(resp.data.user);
					window.sessionStorage.setItem(TOKEN, resp.data.user.authentication_token);
					window.sessionStorage.setItem(USER, resp.data.user.email);
					history.replace(ROUTES.main);
				})
				.catch(e => {
					console.log('error', e.response);
					setError(e.response.data.error);
				});
		},
		validationSchema: loginValidator()
	});

	const closePasswordModal = () => setPasswordModal(false);

	const handleResetPassword = () => {
		if (formik.values.email === '') {
			setError('Podaj adres email');
		} else {
			requestPasswordReset({ params: { email: formik.values.email } })
				.then((resp: AxiosResponse<{ user: IUser }>) => {
					setPasswordModal(true);
					setError(undefined);
				})
				.catch(e => {
					console.log('error', e.response);
					if (e.response.data.error) {
						setError(e.response.data.error);
					}
					if (e.response.data.errors) {
						setError(e.response.data.errors);
					}
				});
		}
	};

	const responseFacebook = (response: IFacebookResponse) => {
		if (response.accessToken) {
			facebookLogin({ params: { token: response.accessToken } })
				.then((resp: AxiosResponse<{ user: IUser }>) => {
					if (resp.data.user.age_group && resp.data.user.country) {
						setUser(resp.data.user);
						window.sessionStorage.setItem(TOKEN, resp.data.user.authentication_token);
						window.sessionStorage.setItem(USER, resp.data.user.email);
						history.replace(ROUTES.main);
					} else {
						setUserData(resp.data.user);
						setSecondStepDisplayed(true);
					}
				})
				.catch(e => {
					console.log('error', e.response);
					if (e.response.data.error) {
						setError(e.response.data.error);
					}
					if (e.response.data.errors) {
						setError(e.response.data.errors);
					}
				});
		}
	};

	const classes = useStyles();
	if (secondStepDisplayed)
		return (
			<div className={classes.root}>
				<Paper className={classes.paper} elevation={3}>
					<SecondStepSignUpForm userData={userData as IUser} />
				</Paper>
			</div>
		);
	return (
		<div className={classes.root}>
			<Paper className={classes.paper} elevation={3}>
				<Typography className={classes.header}>Logowanie</Typography>
				<SwitchButton
					value={isStudent}
					leftAction={() => {
						formik.resetForm();
						setIsStudent(true);
					}}
					rightAction={() => {
						formik.resetForm();
						setIsStudent(false);
					}}
					texts={{ left: 'Uczeń', right: 'Nauczyciel' }}
				/>
				<form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
					{error && <div className={classes.error}>{error}</div>}
					<TextInput
						label="Email"
						name="email"
						onChange={formik.handleChange}
						value={formik.values.email}
						touched={formik.touched.email}
						error={formik.errors.email}
					/>
					<TextInput
						label="Hasło"
						name="password"
						type="password"
						onChange={formik.handleChange}
						value={formik.values.password}
						touched={formik.touched.password}
						error={formik.errors.password}
					/>
					<SubmitButton text={'Zaloguj się'} />
					{isStudent && (
						<FacebookLogin
							appId={process.env.REACT_APP_FB}
							callback={responseFacebook}
							render={(renderProps: any) => (
								<Button
									fullWidth
									variant="outlined"
									className={classes.social}
									onClick={renderProps.onClick}
								>
									<Facebook
										style={{
											color: mainRed,
											fontSize: 12,
											marginRight: '0.25rem'
										}}
									/>{' '}
									Facebook
								</Button>
							)}
						/>
					)}
					<Typography className={classes.additionalText}>Nie posiadasz konta?</Typography>
					<Link className={classes.link} to={ROUTES.signup}>
						Zarejestruj się
						<ArrowForward style={{ fontSize: 16, marginLeft: '0.25rem' }} />
					</Link>
					<Typography className={classes.additionalText}>Zapomniałeś hasła?</Typography>
					<Button variant="text" className={classes.link} onClick={handleResetPassword}>
						Zresetuj hasło{' '}
						<ArrowForward style={{ fontSize: 16, marginLeft: '0.25rem' }} />
					</Button>
				</form>
			</Paper>
			<PasswordDialog isOpened={passwordModal} close={closePasswordModal} />
		</div>
	);
};

export default memo(Login);
