import { createContext } from 'react';
import { ICity, IPoi, ITrack, IWayPointFull, VIEW_MODE } from '../constants/types';

export const SelectionContext = createContext({} as ISelectionProvider);

export interface ISelectionContext {
	selectedCity?: ICity;
	selectedTrack?: ITrack;
	selectedWaypoint?: IWayPointFull;
	selectedPoi?: IPoi;
	viewMode: VIEW_MODE;
}

export interface ISelectionProvider {
	selection: ISelectionContext;
	selectCity: (city: ICity) => void;
	selectTrack: (track: ITrack) => void;
	selectWaypoint: (waypoint: IWayPointFull) => void;
	selectPoi: (poi: IPoi) => void;
	unselectCity: () => void;
	unselectPoi: () => void;
	setViewMode: (viewMode: VIEW_MODE) => void;
}
