import { makeStyles } from '@material-ui/core';
import { border, mainRed, mainRed50, poppins, white } from '../../../styles/variables';

export const useStyles = makeStyles(() => ({
	root: {
		border: border(mainRed50),
		borderRadius: '4px',
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		height: '3.6rem',
		padding: '0.25rem'
	},
	button: {
		letterSpacing: '0.45px',
		height: '3.6rem',
		fontWeight: 600,
		textTransform: 'capitalize',
		fontFamily: poppins,
		width: '100%',
		borderRadius: '4px',
		'&:hover': {
			backgroundColor: mainRed50,
			color: white
		}
	},
	selectedButton: {
		backgroundColor: mainRed,
		color: white
	},
	deselectedButton: {
		color: mainRed
	},
	disabledButtons: {}
}));
