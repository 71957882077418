import { useEffect, useState } from 'react';
import { getCountries } from '../api';
import { AxiosResponse } from 'axios';
import { ICountry } from '../constants/types';

const useCountries = (): { countries: ICountry[]; countriesLoading: boolean } => {
	const [countriesLoading, setCountriesLoading] = useState<boolean>(true);
	const [countries, setCountries] = useState<ICountry[]>([]);

	useEffect(() => {
		getCountries()
			.then((resp: AxiosResponse<ICountry[]>) => {
				setCountries(resp.data);
				setCountriesLoading(false);
			})
			.catch(e => {
				setCountriesLoading(false);
			});
	}, []);

	return { countries, countriesLoading };
};

export default useCountries;
