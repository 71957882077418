import React, { memo, ReactElement, ReactNode, useMemo } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { ROUTES } from '../../../routes/routes';
import { Content, RedirectButton } from '../../shared/tooltip';
import { white } from '../../../styles/variables';

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		paddingLeft: '2rem',
		paddingRight: '2rem'
	},
	tooltipRoot: {
		padding: 0,
		width: '210px',
		wordWrap: 'break-word',
		backgroundColor: white,
		boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.24)'
	},
	popper: {
		whiteSpace: 'pre-line'
	},
	arrow: {
		color: white
	}
});

interface IProps {
	children: ReactNode;
	disabled: boolean;
}

const DrawerTooltip: React.FunctionComponent<IProps> = ({ children, disabled }): ReactElement => {
	const classes = useStyles();

	const tooltipContent = useMemo(() => {
		return (
			<div className={classes.root}>
				<Content text={'Aby obejrzeć trasy musisz się zalogować'} />
				<RedirectButton text={'Idź do logowania'} to={ROUTES.login} />
			</div>
		);
	}, [classes.root]);

	return (
		<Tooltip
			title={!disabled ? tooltipContent : ''}
			arrow
			enterDelay={200}
			placement={'top'}
			interactive
			classes={{
				tooltip: classes.tooltipRoot,
				popper: classes.popper,
				arrow: classes.arrow
			}}
		>
			<div>{children}</div>
		</Tooltip>
	);
};

export default memo(DrawerTooltip);
