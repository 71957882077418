import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	makeStyles
} from '@material-ui/core';
import { fontGrey, mainRed, poppins, subheaderGrey, white } from '../../styles/variables';
import React, { FunctionComponent, memo, ReactElement } from 'react';
import { Close } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '../../routes/routes';
import clsx from 'clsx';

interface IProps {
	isOpened: boolean;
	close: () => void;
	track_completed_data?: {
		name: string;
		badge_image_url: string;
		cities_names: string[];
		user_score: number;
	};
}

const useStyles = makeStyles(() => ({
	title: {
		backgroundColor: subheaderGrey,
		color: white,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	titleText: {
		fontWeight: 900,
		fontSize: '2rem',
		fontFamily: poppins,
		letterSpacing: '0.45px',
		textAlign: 'center',
		textTransform: 'uppercase'
	},
	close: {
		color: white,
		fontSize: '1rem',
		position: 'absolute',
		right: '1rem'
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '55rem'
	},
	buttons: {
		display: 'flex'
	},
	button: {
		marginTop: '2rem',
		marginBottom: '4rem',
		marginLeft: '0.25rem',
		fontFamily: poppins,
		fontSize: '1rem',
		textTransform: 'capitalize',
		color: white,
		letterSpacing: '0.45px',
		fontWeight: 600,
		width: '20rem',
		height: '3.6rem'
	},
	contentRoot: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
	mainText: {
		color: fontGrey,
		fontFamily: poppins,
		fontSize: '2.5rem',
		fontWeight: 'bold'
	},
	text: {
		color: fontGrey,
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 'bold',
		lineHeight: 1.4,
		letterSpacing: '0.52px',
		paddingLeft: '5rem',
		paddingRight: '5rem',
		textAlign: 'center'
	},
	textRed: {
		color: mainRed,
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 'bold',
		lineHeight: 1.4,
		letterSpacing: '0.52px',
		marginLeft: '0.25rem'
	},
	red: {
		color: mainRed
	},
	icon: { marginTop: '2rem', marginBottom: '2rem', height: '20rem' }
}));

const TrackFinishedDialog: FunctionComponent<IProps> = ({
	isOpened,
	close,
	track_completed_data
}): ReactElement => {
	const classes = useStyles();

	return (
		<Dialog maxWidth={'lg'} open={isOpened} onClose={close} aria-labelledby="rules-modal">
			<DialogTitle className={classes.title} id="rules-modal" disableTypography>
				<div>
					<div className={classes.titleText}>Trasa ukończona</div>
				</div>
				<IconButton
					component={RouterLink}
					to={ROUTES.track}
					className={classes.close}
					onClick={close}
				>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent className={classes.content}>
				<div className={classes.contentRoot}>
					<img
						className={classes.icon}
						src={track_completed_data?.badge_image_url}
						alt={''}
					/>
					<div className={classes.mainText}>Gratulacje!</div>
					<div className={classes.text}>
						Gratulacje - ukończyłeś trasę
						<span className={classes.textRed}>{track_completed_data?.name}!</span>
					</div>
					<div className={classes.text}>
						Udało Ci się odwiedzić{' '}
						<span className={classes.textRed}>
							{track_completed_data?.cities_names.join(', ')}
						</span>
					</div>
					<div className={classes.text}>
						Zdobyłeś w sumie
						<span className={classes.textRed}>
							{track_completed_data?.user_score} punktów
						</span>
					</div>
				</div>
				<div className={classes.buttons}>
					<Button
						component={RouterLink}
						to={ROUTES.track}
						variant="outlined"
						className={clsx(classes.button, classes.red)}
						color={'primary'}
						onClick={close}
					>
						Wróć do trasy
					</Button>
					<Button
						component={RouterLink}
						to={ROUTES.main}
						variant="contained"
						className={classes.button}
						color={'primary'}
						onClick={close}
					>
						Wróć do mapy
					</Button>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default memo(TrackFinishedDialog);
