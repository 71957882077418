import React, { FunctionComponent, memo, ReactElement } from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { useStyles } from './SubmitButton.style';

interface IProps {
	text: string;
	className?: string;
	disabled?: boolean;
}

const SubmitButton: FunctionComponent<IProps> = ({
	text,
	className = '',
	disabled = false
}): ReactElement => {
	const classes = useStyles();

	return (
		<Button
			type="submit"
			fullWidth
			variant="contained"
			className={clsx(classes.root, className)}
			disabled={disabled}
		>
			{text}
		</Button>
	);
};

export default memo(SubmitButton);
