import { createMuiTheme, ThemeOptions } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles/createMuiTheme';
import { fontGrey, mainRed, roboto, white } from './variables';

// Default options
const options: ThemeOptions = {
	typography: {
		fontFamily: roboto,
		fontWeightRegular: 500
	},
	palette: {
		primary: {
			main: mainRed
		},
		secondary: {
			main: fontGrey
		}
	},
	props: {
		MuiButton: {
			disableElevation: true
		}
	}
};

export const theme: Theme = createMuiTheme({
	...options
});

export const tabsTheme: Theme = createMuiTheme({
	...options,
	overrides: {
		MuiTabs: {
			indicator: {
				display: 'flex',
				justifyContent: 'center',
				width: '20%'
			},
			root: {
				minHeight: '4rem',
				maxHeight: '4rem'
			}
		},
		MuiTab: {
			root: {
				marginRight: 1,
				marginLeft: 1,
				padding: '0 0.5rem',
				lineHeight: '1.25rem',
				opacity: 0.6,
				color: `${white} !important`,
				minHeight: '4rem',
				maxHeight: '4rem',
				backgroundColor: 'transparent',
				'&:hover': {
					backgroundColor: 'transparent'
				},
				'&$selected': {
					color: `${white} !important`,
					opacity: 1,
					borderBottom: 0,
					minHeight: '4rem',
					maxHeight: '4rem'
				}
			},
			wrapper: {
				fontSize: '1rem',
				textTransform: 'capitalize'
			},
			textColorInherit: {
				color: white
			}
		}
	}
});
