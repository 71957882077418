import React, { FunctionComponent, memo, ReactElement } from 'react';
import { makeStyles, Select, OutlinedInput } from '@material-ui/core';
import clsx from 'clsx';
import { fontGrey, mainRed, white } from '../../styles/variables';

export const useStyles = makeStyles(() => ({
	root: {
		color: fontGrey,
		backgroundColor: white,
		padding: '1rem 1rem',
		display: 'flex',
		alignItems: 'center'
	},
	iconClass: {
		color: mainRed,
		top: '50%',
		transform: 'translateY(-50%)'
	},
	input: {
		flexWrap: 'wrap',
		minHeight: '1.25rem',
		'& ::before': {
			content: (props: { placeholder: string }) => `"${props.placeholder}"`,
			position: 'absolute',
			left: '.875rem',
			top: '50%',
			transform: 'translateY(-50%)',
			color: fontGrey,
			fontWeight: 500
		}
	},
	error: {
		fontSize: '0.8rem',
		color: mainRed,
		width: '100%'
	}
}));

interface IProps {
	id?: string;
	name: string;
	handleChange: any;
	value: string | number | undefined;
	customRootClass?: string;
	customInputClass?: string;
	className?: string;
	renderValue?: (event: any) => JSX.Element | JSX.Element[];
	options: ReactElement[] | any[];
	error?: string;
	placeholder?: string;
	touched?: boolean;
	disabled?: boolean;
	multiple?: boolean;
	defaultValue?: any;
}

const SelectInput: FunctionComponent<IProps> = ({
	id,
	name,
	handleChange,
	value,
	options,
	customRootClass = '',
	customInputClass = '',
	className = '',
	renderValue,
	error,
	placeholder = '',
	touched,
	disabled = false,
	multiple = false,
	defaultValue
}): ReactElement => {
	const showError = Boolean(error && touched);
	const classes = useStyles({ placeholder });

	return (
		<>
			<Select
				multiple={multiple}
				disabled={disabled}
				value={value}
				defaultValue={defaultValue}
				onChange={handleChange}
				className={className}
				classes={{
					root: clsx(classes.root, customRootClass),
					icon: classes.iconClass
				}}
				variant={'outlined'}
				id={id}
				input={
					<OutlinedInput
						inputProps={{ className: clsx(classes.input, customInputClass) }}
						name={name}
						id={name}
						labelWidth={0}
					/>
				}
				name={name}
				inputProps={{
					id: name,
					name
				}}
				renderValue={renderValue}
				error={!!(error && touched)}
			>
				{options}
			</Select>
			{showError && <div className={classes.error}>{error}</div>}
		</>
	);
};

export default memo(SelectInput);
