import React, {
	ChangeEvent,
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useMemo,
	useState
} from 'react';
import { Drawer, Tab, Tabs, ThemeProvider } from '@material-ui/core';
import { UserContext } from '../../context/UserContext';
import { tabsTheme } from '../../styles/theme';
import TrackList from './drawer/TrackList';
import CityList from './drawer/CityList';
import { SelectionContext } from '../../context/SelectionContext';
import { useStyles } from './MapDrawer.style';
import DrawerTooltip from './drawer/DrawerTooltip';
import { VIEW_MODE } from '../../constants/types';
import track from './drawer/Trasy.svg';
import cities from './drawer/Miasta.svg';

const MapDrawer: FunctionComponent = (): ReactElement => {
	const classes = useStyles();
	const { user } = useContext(UserContext);
	const { selection, setViewMode } = useContext(SelectionContext);
	const [tab, setTab] = useState<number>(selection.viewMode === VIEW_MODE.TRACK ? 1 : 0);

	const tracksTabDisabled: boolean = useMemo(() => !user, [user]);

	const handleChangeTab = useCallback(
		(event: ChangeEvent<{}>, value: number) => {
			setViewMode(value ? VIEW_MODE.TRACK : VIEW_MODE.CITY);
			setTab(value);
		},
		[setViewMode]
	);

	return (
		<Drawer
			className={classes.drawer}
			variant="permanent"
			elevation={5}
			classes={{
				paper: classes.drawerPaper
			}}
		>
			<ThemeProvider theme={tabsTheme}>
				<Tabs
					value={tab}
					onChange={handleChangeTab}
					indicatorColor="primary"
					textColor="primary"
					aria-label="tabs"
					classes={{
						root: classes.tabs,
						indicator: classes.tabIndicator
					}}
					centered
				>
					<Tab
						disableRipple
						label={
							<div className={classes.tablabel}>
								<img className={classes.tabIcon} src={cities} alt={''} />
								<div>Miasta</div>
							</div>
						}
						classes={{
							root: classes.tab
						}}
					/>
					<Tab
						disableRipple
						style={{ pointerEvents: 'auto' }}
						disabled={tracksTabDisabled}
						label={
							<DrawerTooltip disabled={!tracksTabDisabled}>
								<div className={classes.tablabel}>
									<img className={classes.tabIcon} src={track} alt={''} />
									<div>Trasy</div>
								</div>
							</DrawerTooltip>
						}
						classes={{
							root: classes.tab
						}}
					/>
				</Tabs>
			</ThemeProvider>
			<div className={classes.drawerContainer}>
				{tab === 0 && <CityList />}
				{tab === 1 && <TrackList />}
			</div>
		</Drawer>
	);
};

export default memo(MapDrawer);
