import MainMap from './MainMap';
import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useContext,
	useEffect,
	useState
} from 'react';
import useSessionValidation from '../../hooks/useSessionValidation';
import Loader from '../shared/Loader';
import MapDrawer from './MapDrawer';
import { getCities, getTracks } from '../../api';
import { AxiosResponse } from 'axios';
import { ICity, ITrack } from '../../constants/types';
import { DataContext } from '../../context/DataContext';
import { UserContext } from '../../context/UserContext';
import { SubheaderContext } from '../../context/SubheaderContext';

const Map: FunctionComponent = (): ReactElement => {
	const { loading } = useSessionValidation();
	const { user } = useContext(UserContext);
	const [citiesLoading, setCitiesLoading] = useState<boolean>(true);
	const [tracksLoading, setTracksLoading] = useState<boolean>(true);
	const { data, updateCities, updateTracks } = useContext(DataContext);
	const { setSubheader } = useContext(SubheaderContext);
	useEffect(() => {
		setSubheader({
			title: 'Wybierzmy się w podróż po polsce'
		});
	}, [setSubheader]);

	useEffect(() => {
		if (citiesLoading) {
			getCities()
				.then((resp: AxiosResponse<ICity[]>) => {
					setCitiesLoading(false);
					if (resp.data.length) updateCities(resp.data);
				})
				.catch(e => {
					setCitiesLoading(false);
				});
		}
	}, [data.cities, updateCities, citiesLoading]);

	useEffect(() => {
		if (user && !loading && tracksLoading) {
			getTracks()
				.then((resp: AxiosResponse<ITrack[]>) => {
					setTracksLoading(false);
					updateTracks(resp.data);
				})
				.catch(e => {
					setTracksLoading(false);
				});
		}
	}, [data.tracks, updateTracks, user, loading, tracksLoading]);

	if (loading) return <Loader />;
	return (
		<>
			<MapDrawer />
			<MainMap cities={data.cities} />
		</>
	);
};

export default memo(Map);
