import { fade, makeStyles } from '@material-ui/core';
import { CARD_SIZE } from './Card';
import {
	border,
	fontGrey,
	fontGrey10,
	lightBlack,
	mainRed,
	mainRed50,
	poppins,
	roboto,
	white
} from '../../../../styles/variables';

const SET_COLOR_1 = '#b02dcc';
const SET_COLOR_2 = '#13a5d9';
const SET_COLOR_3 = '#f89d3b';

export const useStyles = makeStyles(() => ({
	root: {
		padding: '1rem 3rem ',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center'
	},
	card: {
		width: CARD_SIZE,
		userSelect: 'none',
		height: CARD_SIZE,
		marginRight: '20px', // important value for smooth dnd
		textAlign: 'center'
	},
	mainSet: {
		display: 'flex',
		width: '100%',
		height: '12rem',
		padding: '1rem',
		borderRadius: '5px',
		border: border(fontGrey10),
		boxSizing: 'border-box',
		justifyContent: 'center',
		alignItems: 'center'
	},
	sets: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
		paddingTop: '1rem'
	},
	set: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: (props: { setCount: number }) => `calc(100% / ${props.setCount} - 1rem)`,
		height: '15rem',
		borderRadius: '5px'
	},
	setItems: {
		boxSizing: 'border-box',
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		flexWrap: 'wrap',
		width: '100%',
		height: '20rem',
		padding: '1rem'
	},
	set1: {
		color: SET_COLOR_1,
		border: border(fade(SET_COLOR_1, 0.4)),
		backgroundColor: fade(SET_COLOR_1, 0.1)
	},
	set2: {
		color: SET_COLOR_2,
		border: border(fade(SET_COLOR_2, 0.4)),
		backgroundColor: fade(SET_COLOR_2, 0.1)
	},
	set3: {
		color: SET_COLOR_3,
		border: border(fade(SET_COLOR_3, 0.4)),
		backgroundColor: fade(SET_COLOR_3, 0.1)
	},
	label: {
		marginTop: '1rem',
		width: `calc(100% - 6rem)`,
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 'bold',
		textAlign: 'center'
	},
	counter: {
		marginTop: '0.5rem',
		width: '5rem',
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 'bold',
		opacity: 0.5
	},
	round: {
		fontFamily: poppins,
		fontSize: '2.5rem',
		fontWeight: 'bold',
		lineHeight: 1.25,
		letterSpacing: '1.2px',
		color: fontGrey
	},
	info: {
		fontFamily: roboto,
		fontSize: '1.2rem',
		lineHeight: 1.43,
		letterSpacing: '0.52px',
		color: fontGrey
	},
	button: {
		marginTop: '2rem',
		width: '21rem',
		height: '3rem',
		fontFamily: poppins,
		fontWeight: 600,
		letterSpacing: '0.45px',
		color: white,
		backgroundColor: mainRed,
		'&:hover': {
			backgroundColor: mainRed50,
			color: white
		}
	},
	points: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		marginTop: '3.3rem'
	},
	pointsLabel: {
		fontFamily: roboto,
		fontSize: '1.2rem',
		lineHeight: '1.25',
		letterSpacing: '0.5px',
		color: fontGrey
	},
	pointsValue: {
		marginTop: '0.8rem',
		fontFamily: poppins,
		fontSize: '2rem',
		fontWeight: 'bold',
		color: lightBlack
	}
}));
