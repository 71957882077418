import React, { FunctionComponent, memo, ReactElement } from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	makeStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import group from './group-2.svg';
import { fontGrey, poppins, subheaderGrey, white } from '../../styles/variables';

interface IProps {
	isOpened: boolean;
	close: () => void;
	cityName: string;
}

const useStyles = makeStyles(() => ({
	title: {
		backgroundColor: subheaderGrey,
		color: white,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	titleText: {
		fontWeight: 900,
		fontSize: '2rem',
		fontFamily: poppins,
		letterSpacing: '0.45px',
		textAlign: 'center',
		textTransform: 'uppercase'
	},
	close: {
		color: white,
		fontSize: '1rem',
		position: 'absolute',
		right: '1rem'
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '55rem'
	},
	button: {
		marginTop: '2rem',
		marginBottom: '4rem',
		fontFamily: poppins,
		fontSize: '1rem',
		textTransform: 'capitalize',
		color: white,
		letterSpacing: '0.45px',
		fontWeight: 600,
		width: '20rem',
		height: '3.6rem'
	},
	contentRoot: { display: 'flex', flexDirection: 'column', alignItems: 'center' },
	cityName: {
		color: fontGrey,
		fontFamily: poppins,
		fontSize: '2.5rem',
		fontWeight: 'bold'
	},
	text: {
		color: fontGrey,
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 'bold',
		lineHeight: 1.4,
		letterSpacing: '0.52px',
		paddingLeft: '10rem',
		paddingRight: '10rem'
	},
	icon: { marginTop: '2rem', marginBottom: '2rem' }
}));

const NewCityDialog: FunctionComponent<IProps> = ({ isOpened, close, cityName }): ReactElement => {
	const classes = useStyles();

	return (
		<Dialog maxWidth={'lg'} open={isOpened} onClose={close} aria-labelledby="rules-modal">
			<DialogTitle className={classes.title} id="rules-modal" disableTypography>
				<div>
					<div className={classes.titleText}>Miasto Odblokowane</div>
				</div>
				<IconButton className={classes.close} onClick={close}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent className={classes.content}>
				<div className={classes.contentRoot}>
					<img className={classes.icon} src={group} alt={''} />
					<div className={classes.cityName}>{cityName}</div>
					<div className={classes.text}>Gratulacje!</div>
					<div className={classes.text}>
						Udało Ci się odblokować kolejne miasto na trasie!
					</div>
				</div>
				<Button
					variant="contained"
					className={classes.button}
					color={'primary'}
					onClick={close}
				>
					Świetnie
				</Button>
			</DialogContent>
		</Dialog>
	);
};

export default memo(NewCityDialog);
