import React, { memo } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '../../../routes/routes';
import { poppins } from '../../../styles/variables';
import clsx from 'clsx';

const useStyles = makeStyles({
	button: {
		marginTop: '0',
		marginBottom: '0.5rem',
		fontWeight: 600,
		fontSize: '1rem',
		fontFamily: poppins,
		letterSpacing: '0.45px',
		textAlign: 'center',
		textTransform: 'none'
	}
});

interface IProps {
	text: string;
	to: ROUTES;
	className?: string;
}

const RedirectButton: React.FunctionComponent<IProps> = ({ text, to, className }) => {
	const classes = useStyles();
	return (
		<Button
			component={RouterLink}
			to={to}
			className={clsx(classes.button, className)}
			color="primary"
		>
			{text} <ArrowForward style={{ fontSize: 12, marginLeft: '0.25rem' }} />
		</Button>
	);
};

export default memo(RedirectButton);
