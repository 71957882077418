import { makeStyles } from '@material-ui/core';
import { mainRed, poppins, white } from '../../../styles/variables';

export const useStyles = makeStyles({
	root: {
		backgroundColor: mainRed,
		color: white,
		letterSpacing: '0.45px',
		height: '3.6rem',
		fontWeight: 600,
		textTransform: 'capitalize',
		fontFamily: poppins,
		marginTop: '0.5rem',
		marginBottom: '0.5rem'
	}
});
