import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { GAME, IExercise, IGame, ISetResponse, renderGameType } from '../../constants/types';
import { ArrowBack, ArrowForward, HelpOutline } from '@material-ui/icons';
import PoiTooltip from '../shared/tooltip/PoiTooltip';
import {
	border,
	fontGrey,
	fontGrey10,
	fontGrey75,
	mainRed,
	poppins,
	roboto,
	white
} from '../../styles/variables';
import { PADDING } from '../../constants/sets';
import { getExercises } from '../../api';
import { AxiosResponse } from 'axios';
import Loader from '../shared/Loader';
import { SubheaderContext } from '../../context/SubheaderContext';
import NotRatedGame from '../game/NotRatedGame';

const useStyles = makeStyles(() => ({
	content: {
		height: `calc(100% - 11.5rem)`,
		paddingLeft: PADDING,
		paddingRight: PADDING,
		backgroundColor: white
	},
	backButton: {
		fontFamily: poppins,
		fontWeight: 500,
		textTransform: 'capitalize',
		letterSpacing: '0.45px',
		color: white
	},
	playButton: {
		textTransform: 'unset',
		color: mainRed,
		padding: '1px',
		minWidth: '40px',
		fontSize: '14px'
	},
	infobar: {
		display: 'flex',
		alignItems: 'center',
		height: '6rem',
		paddingLeft: PADDING,
		paddingRight: PADDING,
		backgroundColor: white
	},
	hinfo: {
		fontSize: '1.2rem',
		color: fontGrey75,
		marginBottom: '0.5rem'
	},
	info: {
		fontSize: '1.2rem',
		color: fontGrey,
		fontFamily: roboto
	},
	infogroup: {
		display: 'flex',
		flexDirection: 'column'
	},
	setsLabel: {
		paddingLeft: PADDING,
		paddingRight: PADDING,
		backgroundColor: white,
		paddingTop: '2rem',
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 'bold',
		lineHeight: '2.86',
		letterSpacing: '0.52px',
		color: fontGrey
	},
	headers: {
		width: '100%',
		height: '4.25rem',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		color: fontGrey75,
		alignItems: 'center',
		borderBottom: border(fontGrey10)
	},
	hname: { flex: 5 },
	hcity: { flex: 3 },
	htrack: { flex: 3 },
	htags: { flex: 1 },
	hactions: { flex: 1 },
	details: {
		height: 'calc(100% - 2 * 4.25rem)',
		overflow: 'scroll'
	},
	detail: {
		width: '100%',
		height: '4.25rem',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: fontGrey,
		borderBottom: border(fontGrey10),
		fontFamily: roboto
	},
	name: { flex: 5, display: 'flex' },
	city: { flex: 3 },

	track: { flex: 3 },
	tags: { flex: 1 },
	actions: { flex: 1 }
}));

interface IProps {
	set: ISetResponse;
	goBack: () => void;
}

const StudentSet: FunctionComponent<IProps> = ({ set, goBack }): ReactElement => {
	const classes = useStyles();
	const [loading, setLoading] = useState<boolean>(true);
	const [poiGame, setPoiGame] = useState<{ game?: IGame; visible: boolean }>({
		visible: false
	});
	const [setData, setSetData] = useState<ISetResponse>(set);
	const [allowedGames, setAllowedGames] = useState<number[]>([]);
	const { setSubheader } = useContext(SubheaderContext);

	const handleGoBack = useCallback(() => {
		goBack();
	}, [goBack]);

	useEffect(() => {
		if (!poiGame.visible) {
			setSubheader({
				left: (
					<Button variant="text" className={classes.backButton} onClick={handleGoBack}>
						<ArrowBack style={{ fontSize: 12, marginRight: '0.25rem' }} />
						Wpisz inny kod
					</Button>
				),
				title: `Zestaw: ${setData.exchange_code}`
			});
		}
	}, [classes.backButton, handleGoBack, poiGame.visible, setData.exchange_code, setSubheader]);

	useEffect(() => {
		getExercises({
			params: { exchange_code: set.exchange_code }
		})
			.then((resp: AxiosResponse<ISetResponse>) => {
				setSetData(resp.data);
				setAllowedGames([resp.data.excercises[0].id]);
				setLoading(false);
			})
			.catch(e => {
				console.log('set error');
				setLoading(false);
			});
	}, [set.exchange_code]);

	const playTheGame = (game: IGame, exIdx: number) => {
		if (setData.excercises[exIdx + 1]) {
			setAllowedGames(prev => [...prev, setData.excercises[exIdx + 1].id]);
		}
		setPoiGame({ game: game, visible: true });
	};

	const goBackToSet = useCallback(() => {
		setPoiGame({ visible: false });
	}, []);

	if (loading) return <Loader />;
	if (poiGame.visible) return <NotRatedGame goBack={goBackToSet} game={poiGame.game} />;
	return (
		<>
			<div className={classes.infobar}>
				<div className={classes.infogroup}>
					<div className={classes.hinfo}>Nazwa zestawu</div>
					<div className={classes.info}>{setData.name}</div>
				</div>
			</div>

			<div className={classes.setsLabel}>Zagadnienia w zestawie</div>
			<div className={classes.content}>
				<div className={classes.headers}>
					<div className={classes.hname}>Nazwa zestawu</div>
					<div className={classes.hcity}>Miasto</div>
					<div className={classes.htrack}>Trasa</div>
					<div className={classes.htags}>Typ gry</div>
					<div className={classes.hactions}>Akcje</div>
				</div>
				<div className={classes.details}>
					{setData.excercises.map((ex: IExercise, idx: number) => {
						return (
							<div className={classes.detail} key={ex.id}>
								<div className={classes.name}>
									{ex.poi.name}
									<PoiTooltip
										message={ex.poi.tooltip_message}
										gameType={ex.game.game_type}
									>
										<HelpOutline
											style={{ fontSize: 14, marginLeft: '0.25rem' }}
											color={'disabled'}
										/>
									</PoiTooltip>
								</div>
								<div className={classes.city}>{ex.city.name}</div>
								<div className={classes.track}>{ex.track.name}</div>
								<div className={classes.tags}>
									{renderGameType(ex.game.game_type as GAME)}
								</div>
								<div className={classes.actions}>
									<Button
										variant="text"
										onClick={() => playTheGame(ex.game, idx)}
										className={classes.playButton}
										disabled={!allowedGames.includes(ex.id)}
									>
										Zagraj
										<ArrowForward
											style={{ fontSize: 12, marginLeft: '0.25rem' }}
										/>
									</Button>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default memo(StudentSet);
