import React, {
	Dispatch,
	FunctionComponent,
	memo,
	ReactElement,
	SetStateAction,
	useCallback,
	useMemo
} from 'react';
import clsx from 'clsx';
import { Button } from '@material-ui/core';
import { useStyles } from './HotOrNot.style';

interface IProps {
	answer: boolean;
	showResult: boolean;
	isCorrect: boolean;
	setSelectedAnswer: Dispatch<SetStateAction<{ value: boolean | undefined }>>;
	selectedAnswer: { value: boolean | undefined };
}

const Answer: FunctionComponent<IProps> = ({
	answer,
	showResult,
	isCorrect,
	setSelectedAnswer,
	selectedAnswer
}): ReactElement => {
	const classes = useStyles();

	const selectAnswer = useCallback(
		(answer: boolean) => {
			if (showResult) return;
			setSelectedAnswer({ value: answer });
		},
		[setSelectedAnswer, showResult]
	);

	const isSelected = useMemo((): boolean => {
		return selectedAnswer.value === answer;
	}, [selectedAnswer, answer]);

	return (
		<Button
			type="button"
			fullWidth
			variant="outlined"
			className={clsx(
				classes.textAnswer,
				isSelected && !showResult && classes.selected,
				showResult && isCorrect && classes.correct,
				isSelected && showResult && !isCorrect && classes.incorrect
			)}
			onClick={() => selectAnswer(answer)}
		>
			{answer ? 'Prawda' : 'Fałsz'}
		</Button>
	);
};

export default memo(Answer);
