import React, { FunctionComponent, memo, ReactElement } from 'react';
import { IMemoryCard } from './Memory';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { cardBackgroundBlue, cardBackgroundCircle, white } from '../../../../styles/variables';
import { getRandomInt } from '../utils';

export const CARD_SIZE = '120px';
export const SPACE_BETWEEN_MEMORY_CARDS = '10px';

const useStyles = makeStyles(() => ({
	card: {
		width: CARD_SIZE,
		userSelect: 'none',
		height: CARD_SIZE,
		padding: '10px',
		boxSizing: 'border-box',
		textAlign: 'center',
		marginBottom: '15px',
		transition: '0.6s',
		transformStyle: 'preserve-3d',
		position: 'relative'
	},
	img: {
		width: CARD_SIZE,
		height: CARD_SIZE
	},
	circle: {
		width: '100%',
		height: '100%',
		borderRadius: '50%',
		backgroundImage: cardBackgroundCircle
	},
	cardContent: {
		backfaceVisibility: 'hidden',
		position: 'absolute',
		top: '0',
		left: '0',
		width: '100%',
		height: '100%',
		borderRadius: '10px',
		transition: '0.6s'
	},
	front: {
		fontSize: '50px',
		lineHeight: '120px',
		cursor: 'pointer',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: cardBackgroundBlue
	},
	back: {
		transform: 'rotateY(180deg)',
		lineHeight: '110px',
		background: white
	},
	opened: {
		transform: 'rotateY(180deg)'
	},
	matched: {
		transform: 'rotateY(180deg)'
	},
	matchedBack: {
		boxShadow: '0 0 0 2px rgba(#000,.05) inset',
		MozBoxShadow: '0 0 0 2px rgba(#000,.05) inset',
		animation: 'selected .8s 0s ease 1',
		animationFillMode: 'both',
		opacity: '0.3'
	}
}));

interface IProps {
	card: IMemoryCard;
	onClick: () => void;
}

const Card: FunctionComponent<IProps> = ({ card, onClick }): ReactElement => {
	const classes = useStyles();
	return (
		<div
			className={clsx(
				classes.card,
				!card.closed && classes.opened,
				card.complete && classes.matched
			)}
			onClick={onClick}
		>
			<div className={clsx(classes.cardContent, classes.front)}>
				{!card.complete && <div className={classes.circle} />}
			</div>
			<div
				className={clsx(
					classes.cardContent,
					classes.back,
					card.complete && classes.matchedBack
				)}
			>
				<img
					className={classes.img}
					src={card.value}
					alt={`card-${card.id}-${getRandomInt(20)}`}
				/>
			</div>
		</div>
	);
};

export default memo(Card);
