import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import clsx from 'clsx';
import usePortal from 'react-cool-portal';
import MapTooltip from './MapTooltip';
import { SelectionContext } from '../../context/SelectionContext';
import mainMapORPEG from '../../assets/mapkaORPEG.svg';
import { useStyles } from './MainMap.style';
import { ICity, VIEW_MODE } from '../../constants/types';
import { CITY_NAME } from '../../constants/cities';

const TOP_OFFSET = 306;
const LEFT_OFFSET = 156;

const BOTTOM_TOP_OFFSET = -26;
const BOTTOM_LEFT_OFFSET = 51;

interface IProps {
	cities: ICity[];
}

const MainMap: FunctionComponent<IProps> = ({ cities }): ReactElement => {
	const classes = useStyles();
	const { selection, selectCity, unselectCity } = useContext(SelectionContext);
	const [tooltipCoords, setTooltipCoords] = useState<{ top: number; left: number }>({
		top: 0,
		left: 0
	});
	const { Portal, show, hide } = usePortal({
		containerId: 'map-portal-root',
		defaultShow: false,
		clickOutsideToHide: false,
		escToHide: true,
		onShow: e => {
			if (selection.selectedCity) {
				const el = document.getElementById(selection.selectedCity.name);

				if (el) {
					if (
						[
							CITY_NAME.HEL,
							CITY_NAME.GDANSK,
							CITY_NAME.MALBORK,
							CITY_NAME.MYSZYNIEC,
							CITY_NAME.KADZIDLO
						].includes(selection.selectedCity.name as CITY_NAME)
					) {
						setTooltipCoords({
							top: el.getBoundingClientRect().top - BOTTOM_TOP_OFFSET,
							left: el.getBoundingClientRect().left - BOTTOM_LEFT_OFFSET
						});
					} else {
						setTooltipCoords({
							top: el.getBoundingClientRect().top - TOP_OFFSET,
							left: el.getBoundingClientRect().left - LEFT_OFFSET
						});
					}
				}
			}
		},

		onHide: e => {}
	});

	const closeTooltip = useCallback(() => {
		hide();
		unselectCity();
	}, [hide, unselectCity]);

	useEffect(() => {
		hide();
		if (selection.selectedCity) {
			show();
		}
	}, [selection.selectedCity, show, hide]);

	// cleanup
	// hide on close, but keep selectedCity
	// clear selection when map is opened again
	// clear selection when viewMode has changed
	useEffect(() => {
		closeTooltip();
		return () => {
			hide();
		};
	}, [closeTooltip, hide, selection.viewMode]);

	const onCityClick = useCallback(
		(e: React.MouseEvent<SVGGElement, MouseEvent>, city: ICity) => {
			if (selection.selectedCity?.name === city.name) {
				closeTooltip();
			} else {
				selectCity(city);
			}
		},
		[selectCity, closeTooltip, selection.selectedCity]
	);

	const renderCity = useCallback(
		(city: ICity) => {
			return (
				<>
					<g
						onClick={e => {
							onCityClick(e, city);
						}}
					>
						<circle
							id={city.name}
							className={clsx(
								classes.point,
								selection.selectedCity?.name === city.name && classes.selectedPoint
							)}
							cx={city.x}
							cy={city.y}
							r="4"
						/>
						<circle
							className={clsx(
								classes.outline,
								selection.selectedCity?.name === city.name &&
									classes.selectedOutline
							)}
							cx={city.x}
							cy={city.y}
							r="6.5"
						/>
					</g>
					<rect
						x={city.text_x}
						y={city.text_y - 12}
						width="50"
						height="16"
						className={classes.textBackground}
						onClick={e => {
							onCityClick(e, city);
						}}
					/>
					<text
						transform={`matrix(1 0 0 1 ${city.text_x} ${city.text_y})`}
						className={classes.mapText}
						onClick={e => {
							onCityClick(e, city);
						}}
					>
						{city.name}
					</text>
				</>
			);
		},
		[
			classes.mapText,
			classes.outline,
			classes.point,
			classes.selectedOutline,
			classes.selectedPoint,
			classes.textBackground,
			selection,
			onCityClick
		]
	);

	const renderLine = useCallback(
		(start: ICity, end: ICity): ReactElement => {
			const { startX, startY, endX, endY } = {
				startX: Number(start.x),
				startY: Number(start.y),
				endX: Number(end.x),
				endY: Number(end.y)
			};
			const path = `M ${startX} ${startY} q ${startX + 30 - (startX + endX) / 2} ${
				endY + 30 - (startY + endY) / 2
			} ${endX - startX} ${endY - startY}`;
			return <path className={classes.line} d={path} />;
		},
		[classes.line]
	);

	const renderSelectedTrack = useCallback((): ReactElement => {
		let paths: ReactElement[] = [];
		if (selection.selectedTrack && selection.selectedTrack.waypoints.length > 0) {
			for (let i = 0; i < selection.selectedTrack.waypoints.length - 1; i++) {
				paths = [
					...paths,
					renderLine(
						selection.selectedTrack.waypoints[i].city,
						selection.selectedTrack.waypoints[i + 1].city
					)
				];
			}
		}
		return (
			<>
				{paths.map((path: ReactElement, i: number) => (
					<React.Fragment key={i}>{path}</React.Fragment>
				))}
			</>
		);
	}, [selection, renderLine]);

	return (
		<>
			<Portal>
				{selection.selectedCity && (
					<MapTooltip
						top={tooltipCoords.top}
						left={tooltipCoords.left}
						selectedCity={selection.selectedCity}
						hide={closeTooltip}
					/>
				)}
			</Portal>
			<div className={classes.root} id={'map-root'}>
				<img className={classes.imageback} src={mainMapORPEG} alt="map" />
				<svg
					className={classes.imagefront}
					version="1.1"
					x="0px"
					y="0px"
					viewBox="0 0 860.3 768"
				>
					<g>
						{cities.map((city: ICity) => (
							<React.Fragment key={city.id}>{renderCity(city)}</React.Fragment>
						))}
						{selection.viewMode === VIEW_MODE.TRACK && renderSelectedTrack()}
					</g>
				</svg>
			</div>
		</>
	);
};

export default memo(MainMap);
