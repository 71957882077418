import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { Button, IconButton, makeStyles } from '@material-ui/core';
import {
	border,
	fontGrey,
	fontGrey10,
	fontGrey75,
	mainRed,
	mainRed50,
	poppins,
	roboto,
	white
} from '../../styles/variables';
import { ArrowForward, Close } from '@material-ui/icons';
import DeleteSet from './DeleteSet';
import { getSets } from '../../api';
import { AxiosResponse } from 'axios';
import { ISet, renderAgeGroup, TEACHER_VIEW_MODE } from '../../constants/types';
import Loader from '../shared/Loader';
import { TeacherContext } from '../../context/TeacherContext';
import { PADDING } from '../../constants/sets';
import { SubheaderContext } from '../../context/SubheaderContext';
import { copyToClipboard } from '../../utils/copyToClipboard';
import copy from './content_copy.svg';
import TextInput from '../shared/TextInput';

const useStyles = makeStyles(() => ({
	subheader: {
		paddingLeft: PADDING,
		paddingRight: PADDING,
		height: '9rem',
		boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.07)',
		paddingTop: '1rem'
	},
	content: {
		height: `calc(100% - 6rem)`,
		paddingLeft: PADDING,
		paddingRight: PADDING,
		backgroundColor: white
	},
	bar: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center',
		height: '6rem',
		paddingLeft: PADDING,
		paddingRight: PADDING,
		backgroundColor: white
	},
	button: {
		width: '30%',
		letterSpacing: '0.45px',
		height: '3.6rem',
		fontWeight: 600,
		textTransform: 'unset',
		fontFamily: poppins,
		borderRadius: '4px',
		'&:hover': {
			backgroundColor: mainRed50,
			color: white
		},
		backgroundColor: mainRed,
		color: white
	},
	sets: {
		height: 'calc(100% - 4.25rem)',
		overflow: 'scroll'
	},
	headers: {
		width: '100%',
		height: '4.25rem',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		color: fontGrey75,
		alignItems: 'center',
		borderBottom: border(fontGrey10)
	},
	hsetname: {
		flex: 4
	},
	hcode: {
		flex: 2
	},
	hage: {
		flex: 2
	},
	hactions: {
		flex: 1
	},
	spaceColumn: {
		flex: 5
	},
	set: {
		width: '100%',
		height: '4.25rem',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: fontGrey,
		borderBottom: border(fontGrey10),
		fontFamily: roboto
	},
	setname: {
		flex: 4,
		cursor: 'pointer'
	},
	code: {
		flex: 2
	},
	age: {
		flex: 2
	},
	actions: {
		display: 'flex',
		flex: 1
	},
	copyButton: {
		color: mainRed,
		fontSize: '1rem',
		marginLeft: '0.25rem',
		padding: '4px'
	},
	textInput: {
		width: '20rem',
		height: '3.6rem',
		margin: '0 0.5rem 0 0'
	},
	input: {
		padding: '1.2rem'
	}
}));

const Sets: FunctionComponent = (): ReactElement => {
	const { setViewMode, selectSet } = useContext(TeacherContext);
	const [deleteModal, openDeleteModal] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(true);
	const [sendRequest, setSendRequest] = useState<boolean>(true);
	const [sets, setSets] = useState<ISet[]>([]);
	const [filter, setFilter] = useState<string>('');

	const filteredSets: ISet[] = useMemo(
		() => sets.filter((set: ISet) => set.name.toLowerCase().includes(filter.toLowerCase())),
		[sets, filter]
	);

	const { setSubheader } = useContext(SubheaderContext);
	useEffect(() => {
		setSubheader({
			title: 'Zestawy zadań'
		});
	}, [setSubheader]);

	const closeModal = useCallback(() => {
		openDeleteModal(false);
		setFilter('');
		setSendRequest(true);
	}, []);

	useEffect(() => {
		if (sendRequest) {
			getSets()
				.then(
					(
						resp: AxiosResponse<{
							excercise_sets: ISet[];
						}>
					) => {
						setLoading(false);
						setSendRequest(false);
						setSets(resp.data.excercise_sets);
					}
				)
				.catch(e => {
					console.log('sets error');
					setLoading(false);
					setSendRequest(false);
				});
		}
	}, [sendRequest]);

	const classes = useStyles();

	if (loading) return <Loader />;
	return (
		<>
			<div className={classes.bar}>
				<TextInput
					label=""
					name="filter"
					type="text"
					value={filter}
					onChange={evt => setFilter(evt.target.value)}
					fullWidth={false}
					className={classes.textInput}
					inputClass={classes.input}
					margin="none"
					placeholder={'Wyszukaj zestaw'}
				/>
				<Button
					className={classes.button}
					onClick={() => setViewMode(TEACHER_VIEW_MODE.CREATE)}
				>
					+ Dodaj nowy zestaw dla uczniów
				</Button>
			</div>

			<div className={classes.content}>
				<div className={classes.headers}>
					<div className={classes.hsetname}>Nazwa zestawu</div>
					<div className={classes.hage}>Grupa wiekowa</div>
					<div className={classes.hcode}>Kod zestawu</div>
					<div className={classes.spaceColumn} />
					<div className={classes.hactions}>Akcje</div>
				</div>
				<div className={classes.sets}>
					{filteredSets.map((set: ISet) => {
						return (
							<div className={classes.set} key={set.name}>
								<div
									className={classes.setname}
									onClick={() => {
										selectSet(set);
										setViewMode(TEACHER_VIEW_MODE.SHOW);
									}}
								>
									{set.name}
								</div>
								<div className={classes.age}>
									{set.age_groups
										.map((g: string) => renderAgeGroup(g))
										.join(', ')}
								</div>
								<div className={classes.code}>
									{set.exchange_code}{' '}
									<IconButton
										className={classes.copyButton}
										onClick={() => copyToClipboard(set.exchange_code)}
									>
										<img src={copy} alt={''} />
									</IconButton>
								</div>
								<div className={classes.spaceColumn} />
								<div className={classes.actions}>
									<Close
										style={{
											color: mainRed,
											marginRight: '0.5rem',
											cursor: 'pointer'
										}}
										onClick={() => {
											selectSet(set);
											openDeleteModal(true);
										}}
									/>
									<ArrowForward
										style={{ color: mainRed, cursor: 'pointer' }}
										onClick={() => {
											selectSet(set);
											setViewMode(TEACHER_VIEW_MODE.SHOW);
										}}
									/>
								</div>
							</div>
						);
					})}
				</div>
			</div>
			<DeleteSet isOpened={deleteModal} close={closeModal} />
		</>
	);
};

export default memo(Sets);
