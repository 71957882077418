import { makeStyles } from '@material-ui/core';
import { fontGrey, mainRed, poppins, roboto, white } from '../../styles/variables';

export const useStyles = makeStyles({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%'
	},
	paper: {
		width: '38rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: white,
		paddingLeft: '10rem',
		paddingRight: '10rem',
		paddingBottom: '1rem'
	},
	header: {
		marginTop: '4rem',
		marginBottom: '1.5rem',
		fontFamily: poppins,
		color: fontGrey,
		textTransform: 'uppercase',
		fontWeight: 'bold',
		fontSize: '2rem'
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '100%', // Fix IE 11 issue.
		marginTop: '1rem'
	},
	submit: {
		backgroundColor: mainRed,
		color: white,
		letterSpacing: '0.45px',
		height: '3.6rem',
		fontWeight: 600,
		textTransform: 'capitalize',
		fontFamily: poppins,
		marginBottom: '0.5rem'
	},
	social: {
		backgroundColor: white,
		color: mainRed,
		borderColor: mainRed,
		letterSpacing: '0.45px',
		height: '3.6rem',
		fontWeight: 600,
		textTransform: 'capitalize',
		fontFamily: poppins,
		marginBottom: '0.5rem',
		marginTop: '1rem'
	},
	additionalText: {
		marginTop: '1rem',
		fontFamily: roboto,
		color: fontGrey,
		opacity: 0.5
	},
	link: {
		marginTop: '0.5rem',
		color: mainRed,
		fontFamily: poppins,
		fontWeight: 'bold',
		fontSize: '1rem',
		textDecoration: 'none',
		display: 'flex',
		alignItems: 'center',
		textTransform: 'unset'
	},
	select: {
		marginBottom: '0.75rem',
		width: '100%'
	},
	selectInput: {
		minHeight: '1.75rem !important'
	},
	error: {
		fontSize: '0.8rem',
		color: mainRed,
		width: '100%'
	},
	orpeg: {
		display: 'flex',
		paddingTop: '0.2rem'
	},
	orpegInfo: {
		cursor: 'pointer',
		color: fontGrey,
		textDecoration: 'underline'
	}
});
