import React, { memo, useCallback, useContext, useState } from 'react';
import { ROUTES } from '../../routes/routes';
import { CITY_NAME } from '../../constants/cities';
import { ICity, ITrack, IWayPoint } from '../../constants/types';
import { Link as RouterLink } from 'react-router-dom';
import { ArrowForward, Close, PlayCircleOutline, VolumeUpOutlined } from '@material-ui/icons';
import { Button, IconButton } from '@material-ui/core';
import VideoDialog from './VideoDialog';
import { UserContext } from '../../context/UserContext';
import { DataContext } from '../../context/DataContext';
import { SelectionContext } from '../../context/SelectionContext';
import clsx from 'clsx';
import { useStyles } from './MapTooltip.style';
import { speakText } from '../../utils/speak';

interface IProps {
	top: number;
	left: number;
	selectedCity: ICity;
	hide: () => void;
}

const MapTooltip: React.FunctionComponent<IProps> = ({ top, left, selectedCity, hide }) => {
	const { user } = useContext(UserContext);
	const { data } = useContext(DataContext);
	const { selectTrack, selectCity } = useContext(SelectionContext);
	const [openedModal, setOpenedModal] = useState<boolean>(false);

	const openModal = useCallback(() => {
		setOpenedModal(true);
	}, []);

	const closeModal = useCallback(() => {
		setOpenedModal(false);
	}, []);

	const findTracksByCity = useCallback(
		(city: ICity): ITrack[] => {
			return data.tracks.filter((track: ITrack) =>
				track.waypoints.some((waypoint: IWayPoint) => waypoint.city.name === city.name)
			);
		},
		[data.tracks]
	);

	const handleSelectTrack = (track: ITrack) => {
		const waypoint: IWayPoint =
			track.waypoints.find((point: IWayPoint) => point.position === 0) || track.waypoints[0];
		selectCity(waypoint.city);
		selectTrack(track);
	};

	const classes = useStyles({ top, left });
	return (
		<>
			<div
				className={clsx(
					classes.root,
					[
						CITY_NAME.HEL,
						CITY_NAME.GDANSK,
						CITY_NAME.MALBORK,
						CITY_NAME.MYSZYNIEC,
						CITY_NAME.KADZIDLO
					].includes(selectedCity.name as CITY_NAME)
						? classes.toBottom
						: classes.toTop
				)}
			>
				<img className={classes.logo} src={selectedCity.emblem_url} alt="logo" />
				<div className={classes.textContainer}>
					<IconButton className={classes.closeButton} onClick={hide}>
						<Close />
					</IconButton>
					<div className={classes.header}>
						{selectedCity.name}
						<IconButton
							className={classes.soundButton}
							onClick={() => speakText(selectedCity.description)}
						>
							<VolumeUpOutlined />
						</IconButton>
					</div>
					<div className={classes.description}>{selectedCity.description}</div>
					{!user ? (
						<Button
							component={RouterLink}
							to={ROUTES.login}
							className={classes.button}
							color="primary"
							variant={'outlined'}
						>
							{'Zaloguj się'}
							<ArrowForward style={{ fontSize: 12, marginLeft: '0.25rem' }} />
						</Button>
					) : (
						<div className={classes.trackButtons}>
							{findTracksByCity(selectedCity).map((track: ITrack) => (
								<Button
									key={track.name}
									component={RouterLink}
									to={ROUTES.track}
									className={classes.trackButton}
									color="primary"
									variant={'outlined'}
									onClick={() => handleSelectTrack(track)}
								>
									{track.name}
									<ArrowForward style={{ fontSize: 12, marginLeft: '0.25rem' }} />
								</Button>
							))}
						</div>
					)}
				</div>
				<div className={classes.bottomButton} onClick={openModal}>
					Zobacz film promocyjny
					<PlayCircleOutline style={{ fontSize: 24 }} />
				</div>
			</div>
			<VideoDialog isOpened={openedModal} close={closeModal} city={selectedCity} />
		</>
	);
};

export default memo(MapTooltip);
