import { Button, fade, makeStyles } from '@material-ui/core';
import { mainRed, poppins, white } from '../../styles/variables';
import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useContext,
	useEffect,
	useState
} from 'react';
import clsx from 'clsx';
import Info from './Info';
import OngoingTracks from './OngingTracks';
import FinishedTracks from './FinishedTracks';
import useSessionValidation from '../../hooks/useSessionValidation';
import Loader from '../shared/Loader';
import { SubheaderContext } from '../../context/SubheaderContext';

const PADDING = '20%';
const SUBHEADER = '4rem';

const useStyles = makeStyles(() => ({
	subheader: {
		backgroundColor: mainRed,
		display: 'flex',
		justifyContent: 'center',
		height: SUBHEADER,
		paddingTop: '1rem'
	},
	profileButton: {
		textTransform: 'unset',
		fontSize: '1.2rem',
		fontWeight: 'bold',
		fontFamily: poppins,
		color: fade(white, 0.6),
		lineHeight: 2.86,
		letterSpacing: '0.52px'
	},
	selected: {
		color: white
	},
	content: {
		height: `calc(100% - ${SUBHEADER} - 1rem)`,
		paddingLeft: PADDING,
		paddingRight: PADDING,
		backgroundColor: white
	}
}));

const Profile: FunctionComponent = (): ReactElement => {
	const { loading } = useSessionValidation();
	const [selectedButton, setSelectedButton] = useState<number>(0);
	const { setSubheader } = useContext(SubheaderContext);
	useEffect(() => {
		setSubheader({ title: 'Mój Profil' });
	}, [setSubheader]);

	const classes = useStyles();
	if (loading) return <Loader />;
	return (
		<>
			<div className={classes.subheader}>
				<Button
					variant="text"
					className={clsx(
						classes.profileButton,
						selectedButton === 0 && classes.selected
					)}
					onClick={() => setSelectedButton(0)}
				>
					Dane podstawowe
				</Button>
				<Button
					variant="text"
					className={clsx(
						classes.profileButton,
						selectedButton === 1 && classes.selected
					)}
					onClick={() => setSelectedButton(1)}
				>
					Ukończone trasy
				</Button>
				<Button
					variant="text"
					className={clsx(
						classes.profileButton,
						selectedButton === 2 && classes.selected
					)}
					onClick={() => setSelectedButton(2)}
				>
					Trasy w toku
				</Button>
			</div>
			<div className={classes.content}>
				{selectedButton === 0 && <Info />}
				{selectedButton === 1 && <FinishedTracks />}
				{selectedButton === 2 && <OngoingTracks />}
			</div>
		</>
	);
};

export default memo(Profile);
