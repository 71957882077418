import { IconButton, LinearProgress, makeStyles } from '@material-ui/core';
import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useContext,
	useEffect,
	useState
} from 'react';
import { border, fontGrey, fontGrey10, fontGrey75, mainRed } from '../../styles/variables';
import { ArrowForward } from '@material-ui/icons';
import { ITrack, IWayPoint } from '../../constants/types';
import { getTrack, getTracksInProgress } from '../../api';
import { AxiosResponse } from 'axios';
import Loader from '../shared/Loader';
import { SelectionContext } from '../../context/SelectionContext';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../routes/routes';

const useStyles = makeStyles(() => ({
	root: {},
	headers: {
		width: '100%',
		height: '4.25rem',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		color: fontGrey75,
		alignItems: 'center',
		borderBottom: border(fontGrey10)
	},
	hTrack: {
		flex: 3
	},
	hScore: {
		flex: 7
	},
	hActions: {
		flex: 1
	},
	tracks: {},
	track: {
		width: '100%',
		height: '4.25rem',
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'space-between',
		color: fontGrey,
		borderBottom: border(fontGrey10)
	},
	trackName: {
		flex: 3,
		display: 'flex',
		justifyContent: 'flex-start'
	},
	score: {
		display: 'flex',
		alignItems: 'center',
		flex: 7
	},
	actions: { flex: 1 },
	progressBar: {
		width: '12rem',
		height: '0.5rem',
		borderRadius: '4px',
		marginRight: '0.25rem'
	}
}));

const OngoingTracks: FunctionComponent = (): ReactElement => {
	const [dataLoading, setDataLoading] = useState<boolean>(false);
	const [tracks, setTracks] = useState<ITrack[]>([]);
	const { selectTrack, selectCity } = useContext(SelectionContext);
	const history = useHistory();

	useEffect(() => {
		getTracksInProgress()
			.then((resp: AxiosResponse<ITrack[]>) => {
				setDataLoading(false);
				setTracks(resp.data);
			})
			.catch(e => {
				setDataLoading(false);
			});
	}, []);

	const goToTrack = async (track: ITrack) => {
		try {
			const trackResponse: AxiosResponse<ITrack> = await getTrack({
				params: { id: track.id }
			});
			const waypoint: IWayPoint =
				trackResponse.data.waypoints.find((point: IWayPoint) => point.position === 0) ||
				track.waypoints[0];
			selectCity(waypoint.city);
			selectTrack(trackResponse.data);
			history.replace(ROUTES.track);
		} catch {
			console.log('go to track error');
		}
	};

	const classes = useStyles();
	if (dataLoading) return <Loader />;
	return (
		<>
			<div className={classes.headers}>
				<div className={classes.hTrack}>Nazwa trasy</div>
				<div className={classes.hScore}>Poziom ukończenia</div>
				<div className={classes.hActions}>Akcje</div>
			</div>
			<div className={classes.tracks}>
				{tracks.map((track: ITrack) => (
					<div key={track.id} className={classes.track}>
						<div className={classes.trackName}>{track.name}</div>
						<div className={classes.score}>
							<LinearProgress
								variant="determinate"
								value={track.percent_complete}
								classes={{ root: classes.progressBar }}
							/>
							{track.percent_complete}%
						</div>
						<div className={classes.actions}>
							<IconButton onClick={() => goToTrack(track)}>
								<ArrowForward style={{ color: mainRed }} />
							</IconButton>
						</div>
					</div>
				))}
			</div>
		</>
	);
};

export default memo(OngoingTracks);
