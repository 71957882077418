import React, { FunctionComponent, memo, ReactElement, useContext } from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	makeStyles
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { fontGrey, poppins, subheaderGrey, white } from '../../styles/variables';
import { TeacherContext } from '../../context/TeacherContext';
import { removeSet } from '../../api';
import { TEACHER_VIEW_MODE } from '../../constants/types';

export const useStyles = makeStyles(() => ({
	title: {
		backgroundColor: subheaderGrey,
		color: white,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	titleText: {
		fontWeight: 900,
		fontSize: '2rem',
		fontFamily: poppins,
		letterSpacing: '0.45px',
		textAlign: 'center',
		textTransform: 'uppercase'
	},
	close: {
		color: white,
		fontSize: '1rem',
		position: 'absolute',
		right: '1rem'
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '55rem'
	},
	button: {
		marginTop: '2rem',
		marginBottom: '4rem',
		fontFamily: poppins,
		fontSize: '1rem',
		textTransform: 'capitalize',
		color: white,
		letterSpacing: '0.45px',
		fontWeight: 600,
		width: '20rem',
		height: '3.6rem'
	},
	contentRoot: {
		width: '60%',
		marginTop: '1.5rem',
		marginBottom: '1.5rem',
		fontFamily: poppins,
		fontSize: '1.3rem',
		fontWeight: 'bold',
		lineHeight: '1.67',
		letterSpacing: '0.56px',
		color: fontGrey
	}
}));

interface IProps {
	isOpened: boolean;
	close: () => void;
}

const DeleteSet: FunctionComponent<IProps> = ({ isOpened, close }): ReactElement => {
	const { setsSelection, setViewMode, unselectSet } = useContext(TeacherContext);
	const classes = useStyles();
	const handleDelete = () => {
		if (setsSelection.selectedSet) {
			removeSet({ params: { id: setsSelection.selectedSet.id } })
				.then(() => {
					unselectSet();
					close();
					setViewMode(TEACHER_VIEW_MODE.INDEX);
				})
				.catch(() => {
					close();
				});
		}
	};
	return (
		<Dialog maxWidth={'lg'} open={isOpened} onClose={close}>
			<DialogTitle className={classes.title} id="pass-modal" disableTypography>
				<div>
					<div className={classes.titleText}>Usuń zestaw</div>
				</div>
				<IconButton className={classes.close} onClick={close}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent className={classes.content}>
				<div className={classes.contentRoot}>
					Czy na pewno chcesz usunąć zestaw „{setsSelection.selectedSet?.name}”? Tej akcji
					nie można cofnąć.
				</div>
				<Button
					variant="contained"
					className={classes.button}
					color={'primary'}
					onClick={handleDelete}
				>
					Usuń zestaw
				</Button>
			</DialogContent>
		</Dialog>
	);
};

export default memo(DeleteSet);
