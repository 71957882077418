// given array will be modified
import { IMemoryPair } from '../../../constants/types';
import { IPairCard } from './pairs/Pairs';

export const shuffle = (
	array: string[] | IMemoryPair[] | IPairCard[]
): string[] | IMemoryPair[] | IPairCard[] => {
	let currentIndex = array.length,
		temporaryValue,
		randomIndex;
	while (0 !== currentIndex) {
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex -= 1;
		temporaryValue = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temporaryValue;
	}
	return array;
};

export const getRandomInt = (max: number) => {
	return Math.floor(Math.random() * Math.floor(max));
};
