import React, { FunctionComponent, ReactElement, useCallback, useMemo, useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navbar from './components/navbar/Navbar';
import { IRoute, routes } from './routes/routes';
import Footer from './components/shared/footer/Footer';
import ContentContainer from './components/shared/ContentContainer';
import { ThemeProvider } from '@material-ui/core';
import { IUser, IUserProvider, UserContext } from './context/UserContext';
import {
	ISelectionContext,
	ISelectionProvider,
	SelectionContext
} from './context/SelectionContext';
import { theme } from './styles/theme';
import { ICity, IPoi, ITrack, IWayPointFull, VIEW_MODE } from './constants/types';
import { DataContext, IDataContext, IDataProvider } from './context/DataContext';
import {
	ISubheaderContext,
	ISubheaderProvider,
	SubheaderContext
} from './context/SubheaderContext';
import Subheader from './components/shared/Subheader';

const App: FunctionComponent = (): ReactElement => {
	const [user, setUser] = useState<IUser | undefined>();
	const [selection, setMapSelection] = useState<ISelectionContext>({ viewMode: VIEW_MODE.CITY });
	const [data, setData] = useState<IDataContext>({ cities: [], tracks: [] });
	const [content, setContent] = useState<ISubheaderContext>({});

	const setSubheader = useCallback((context: ISubheaderContext) => {
		setContent(context);
	}, []);

	const updateCities = useCallback((cities: ICity[]) => {
		setData((prevState: IDataContext) => ({
			...prevState,
			cities
		}));
	}, []);

	const updateTracks = useCallback((tracks: ITrack[]) => {
		setData((prevState: IDataContext) => ({
			...prevState,
			tracks
		}));
	}, []);

	const selectCity = useCallback((city: ICity) => {
		setMapSelection((prevState: ISelectionContext) => ({
			...prevState,
			selectedCity: city
		}));
	}, []);

	const unselectCity = useCallback(() => {
		setMapSelection((prevState: ISelectionContext) => ({
			...prevState,
			selectedCity: undefined
		}));
	}, []);

	const selectTrack = useCallback((track: ITrack) => {
		setMapSelection((prevState: ISelectionContext) => ({
			...prevState,
			selectedTrack: track
		}));
	}, []);

	const selectWaypoint = useCallback((waypoint: IWayPointFull) => {
		setMapSelection((prevState: ISelectionContext) => ({
			...prevState,
			selectedWaypoint: waypoint
		}));
	}, []);

	const selectPoi = useCallback((poi: IPoi) => {
		setMapSelection((prevState: ISelectionContext) => ({
			...prevState,
			selectedPoi: poi
		}));
	}, []);

	const unselectPoi = useCallback(() => {
		setMapSelection((prevState: ISelectionContext) => ({
			...prevState,
			selectedPoi: undefined
		}));
	}, []);

	const setViewMode = useCallback((viewmode: VIEW_MODE) => {
		setMapSelection((prevState: ISelectionContext) => ({
			...prevState,
			viewMode: viewmode
		}));
	}, []);

	const dataProviderValue: IDataProvider = useMemo(() => ({ data, updateCities, updateTracks }), [
		data,
		updateCities,
		updateTracks
	]);
	const userProviderValue: IUserProvider = useMemo(() => ({ user, setUser }), [user, setUser]);
	const selectionProviderValue: ISelectionProvider = useMemo(
		() => ({
			selection,
			selectCity,
			selectTrack,
			selectWaypoint,
			selectPoi,
			setViewMode,
			unselectCity,
			unselectPoi
		}),
		[
			selection,
			selectCity,
			selectTrack,
			setViewMode,
			selectPoi,
			selectWaypoint,
			unselectCity,
			unselectPoi
		]
	);

	const subheaderProviderValue: ISubheaderProvider = useMemo(
		() => ({
			content,
			setSubheader
		}),
		[content, setSubheader]
	);

	return (
		<ThemeProvider theme={theme}>
			<UserContext.Provider value={userProviderValue}>
				<DataContext.Provider value={dataProviderValue}>
					<SelectionContext.Provider value={selectionProviderValue}>
						<SubheaderContext.Provider value={subheaderProviderValue}>
							<BrowserRouter>
								<Navbar />
								{!subheaderProviderValue.content.hidden && (
									<Subheader
										left={subheaderProviderValue.content.left}
										title={subheaderProviderValue.content.title || ''}
										right={subheaderProviderValue.content.right}
										subtitle={subheaderProviderValue.content.subtitle}
									/>
								)}

								<ContentContainer>
									<Switch>
										{routes.map((route: IRoute, index: number) => (
											<Route
												key={index}
												exact={route.exact}
												path={route.path}
												component={route.main}
											/>
										))}
									</Switch>
								</ContentContainer>
								<Footer />
							</BrowserRouter>
						</SubheaderContext.Provider>
					</SelectionContext.Provider>
				</DataContext.Provider>
			</UserContext.Provider>
		</ThemeProvider>
	);
};

export default App;
