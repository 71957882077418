import { Checkbox } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import { useFormik } from 'formik';
import React, { FunctionComponent, memo, ReactElement, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IRegisterPayload, registerTeacher } from '../../api';
import { TOKEN, USER } from '../../constants/session';
import { IUser } from '../../context/UserContext';
import { ROUTES } from '../../routes/routes';
import { openInNewTab } from '../../utils/openInNewTab';
import { SubmitButton } from '../shared/buttons';
import TextInput from '../shared/TextInput';
import { useStyles } from './Auth.style';
import teacherSignUpValidtor from './teacherSignUpValidtor';

const TeacherSignUpForm: FunctionComponent = (): ReactElement => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const [error, setError] = useState<string | undefined>(undefined);
	const [orpeg, setOrpeg] = useState<boolean>(false);
	const param = useMemo(() => new URLSearchParams(location.search), [location.search]);

	const handleToggleCheckbox = () => {
		setOrpeg(prev => !prev);
	};

	const formik = useFormik({
		initialValues: {
			email: param.get('email') || '',
			password: '',
			password_confirmation: '',
			invitation_code: param.get('invitation_code') || ''
		} as IRegisterPayload,
		onSubmit: (values: IRegisterPayload) => {
			setError(undefined);
			registerTeacher({ params: values })
				.then((resp: AxiosResponse<{ user: IUser }>) => {
					window.sessionStorage.setItem(TOKEN, resp.data.user.authentication_token);
					window.sessionStorage.setItem(USER, resp.data.user.email);
					history.replace(ROUTES.main);
				})
				.catch(e => {
					console.log('error', e.response);
					if (e.response.data.error) {
						setError(e.response.data.error);
					}
					if (e.response.data.errors) {
						setError(e.response.data.errors);
					}
				});
		},
		validationSchema: teacherSignUpValidtor()
	});

	return (
		<form className={classes.form} onSubmit={formik.handleSubmit}>
			{error && <div className={classes.error}>{error}</div>}
			<TextInput
				label="E-mail (na który otrzymano kod)"
				name="email"
				onChange={formik.handleChange}
				value={formik.values.email}
				touched={formik.touched.email}
				error={formik.errors.email}
			/>
			<TextInput
				label="Hasło"
				name="password"
				type="password"
				onChange={formik.handleChange}
				value={formik.values.password}
				touched={formik.touched.password}
				error={formik.errors.password}
			/>
			<TextInput
				label="Powtórz Hasło"
				name="password_confirmation"
				type="password"
				onChange={formik.handleChange}
				value={formik.values.password_confirmation}
				touched={formik.touched.password_confirmation}
				error={formik.errors.password_confirmation}
			/>
			<a className={classes.orpegInfo} href="mailto:pcn_sekretariat@orpeg.pl">
				W celu uzyskania kodu skontaktuj się - pcn_sekretariat@orpeg.pl
			</a>
			<TextInput
				label="Kod weryfikacyjny"
				name="invitation_code"
				onChange={formik.handleChange}
				value={formik.values.invitation_code || ''}
				touched={formik.touched.invitation_code}
				error={formik.errors.invitation_code}
			/>
			<div className={classes.orpeg}>
				<Checkbox checked={orpeg} onChange={handleToggleCheckbox} color="primary" />
				<div
					className={classes.orpegInfo}
					onClick={() =>
						openInNewTab('https://www.orpeg.pl/o-nas/ochrona-danych-osobowych/')
					}
				>
					Oświadczam, że zapoznałem się z informacją o administratorze oraz zasadach
					przetwarzania danych osobowych
				</div>
			</div>
			<SubmitButton text={'Zarejestruj się'} disabled={!orpeg} />
		</form>
	);
};

export default memo(TeacherSignUpForm);
