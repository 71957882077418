import axios, { AxiosPromise, AxiosRequestConfig } from 'axios';
import { TOKEN, USER } from './constants/session';
import { IAgeGroup, ISetPoi } from './constants/types';
import { USER_ROLE } from './context/UserContext';

axios.defaults.baseURL = process.env.REACT_APP_PROXY;

const addAuthorizationHeader = (request: AxiosRequestConfig): void => {
	const authToken = window.sessionStorage.getItem(TOKEN);
	const userEmail = window.sessionStorage.getItem(USER);
	if (authToken && userEmail) {
		request.headers['X-User-Email'] = userEmail;
		request.headers['X-User-Token'] = authToken;
	}
};

axios.interceptors.request.use((request: AxiosRequestConfig) => {
	addAuthorizationHeader(request);
	return request;
});

enum ENDPOINT {
	LOGIN = '/users/sign_in',
	LOGOUT = '/users/sign_out',
	USERS = '/users',
	UPDATE_PROFILE = '/api/v1/update_profile',
	PROFILE = '/api/v1/profile',
	COUNTRIES = '/api/v1/countries',
	RANKINGS = '/api/v1/rankings',
	TRACKS = '/api/v1/tracks',
	ALL_TRACKS = '/api/v1/all_tracks',
	TRACKS_COMPLETED = '/api/v1//tracks/profile/completed',
	TRACKS_IN_PROGRESS = '/api/v1//tracks/profile/in_progress',
	CITIES = '/api/v1/cities',
	WAYPOINTS = '/api/v1/waypoints',
	SCORES = '/api/v1/scores',
	SETS = '/api/v1/teacher/excercise_sets',
	POIS = '/api/v1/teacher/pois',
	EXERCISES = '/api/v1/play_excercise_set',
	FACEBOOK = '/auth/facebook',
	PASSWORD = '/users/password'
}

interface IRequest {
	params: IApiPayloads;
}

export type IApiPayloads =
	| ILoginPayload
	| IRegisterPayload
	| IGetObjectPayload
	| ISubmitScorePayload
	| IUpdatePasswordPayload
	| IUpdateProfilePayload
	| IGetRankingsPayload
	| ICreateSetPayload
	| IGetPoisPayload
	| IPassResetRequestPayload
	| IResetPasswordPayload
	| {};

export interface ILoginPayload {
	email: string;
	password: string;
}

export const login = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: ENDPOINT.LOGIN,
		method: 'POST',
		data: {
			user: {
				email: (params as ILoginPayload).email,
				password: (params as ILoginPayload).password
			}
		}
	});
};

export const logout = (): AxiosPromise => {
	return axios.request({ url: ENDPOINT.LOGOUT, method: 'DELETE' });
};

export interface IRegisterPayload {
	email: string;
	nickname?: string;
	password: string;
	password_confirmation: string;
	invitation_code?: string;
}

export const register = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: ENDPOINT.USERS,
		method: 'POST',
		data: {
			user: {
				email: (params as IRegisterPayload).email,
				nickname: (params as IRegisterPayload).nickname,
				password: (params as IRegisterPayload).password,
				password_confirmation: (params as IRegisterPayload).password_confirmation,
				role: USER_ROLE.STUDENT
			}
		}
	});
};

export interface IPassResetRequestPayload {
	email: string;
}

export const requestPasswordReset = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: ENDPOINT.PASSWORD,
		method: 'POST',
		data: {
			user: {
				email: (params as IPassResetRequestPayload).email
			}
		}
	});
};

export interface IResetPasswordPayload {
	reset_password_token: string;
	password: string;
	password_confirmation: string;
}

export const resetPassword = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: ENDPOINT.PASSWORD,
		method: 'PUT',
		data: {
			user: {
				reset_password_token: (params as IResetPasswordPayload).reset_password_token,
				password: (params as IResetPasswordPayload).password,
				password_confirmation: (params as IResetPasswordPayload).password_confirmation
			}
		}
	});
};

export interface IFacebookPayload {
	token: string;
}

export const facebookLogin = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: ENDPOINT.FACEBOOK,
		method: 'POST',
		data: {
			access_token: (params as IFacebookPayload).token
		}
	});
};

export const registerTeacher = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: ENDPOINT.USERS,
		method: 'POST',
		data: {
			user: {
				email: (params as IRegisterPayload).email,
				invitation_code: (params as IRegisterPayload).invitation_code,
				password: (params as IRegisterPayload).password,
				password_confirmation: (params as IRegisterPayload).password_confirmation,
				role: USER_ROLE.TEACHER
			}
		}
	});
};

export interface IUpdateProfilePayload {
	country: string;
	nickname: string;
	age_group: IAgeGroup;
	email: string;
}

export interface IUpdatePasswordPayload {
	password: string;
	password_confirmation: string;
}

export const updateProfile = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: ENDPOINT.UPDATE_PROFILE,
		method: 'PUT',
		data: {
			user: {
				country: (params as IUpdateProfilePayload).country,
				nickname: (params as IUpdateProfilePayload).nickname,
				age_group: (params as IUpdateProfilePayload).age_group
			}
		}
	});
};

export interface IGetObjectPayload {
	id: number;
}

export const getUser = (): AxiosPromise => {
	return axios.request({ url: ENDPOINT.PROFILE, method: 'GET' });
};

export interface IGetRankingsPayload {
	age_group: string;
	per_page: number;
	page: number;
	country: string;
	track_id: number;
}

export const getRankings = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: ENDPOINT.RANKINGS,
		method: 'GET',
		params: params as IGetRankingsPayload
	});
};

export const getCountries = (): AxiosPromise => {
	return axios.request({ url: ENDPOINT.COUNTRIES, method: 'GET' });
};

export const getCities = (): AxiosPromise => {
	return axios.request({ url: ENDPOINT.CITIES, method: 'GET' });
};

export const getCity = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: `${ENDPOINT.CITIES}/${(params as IGetObjectPayload).id}`,
		method: 'GET'
	});
};

export const getTracks = (): AxiosPromise => {
	return axios.request({ url: ENDPOINT.TRACKS, method: 'GET' });
};

export const getAllTracks = (): AxiosPromise => {
	return axios.request({ url: ENDPOINT.ALL_TRACKS, method: 'GET' });
};

export const getTracksCompleted = (): AxiosPromise => {
	return axios.request({ url: ENDPOINT.TRACKS_COMPLETED, method: 'GET' });
};

export const getTracksInProgress = (): AxiosPromise => {
	return axios.request({ url: ENDPOINT.TRACKS_IN_PROGRESS, method: 'GET' });
};

export const getTrack = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: `${ENDPOINT.TRACKS}/${(params as IGetObjectPayload).id}`,
		method: 'GET'
	});
};

export const getWaypoint = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: `${ENDPOINT.WAYPOINTS}/${(params as IGetObjectPayload).id}`,
		method: 'GET'
	});
};

export interface ISubmitScorePayload {
	game_id: number;
	points: number;
}

export const submitScore = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: ENDPOINT.SCORES,
		method: 'POST',
		data: {
			score: {
				game_id: (params as ISubmitScorePayload).game_id,
				points: (params as ISubmitScorePayload).points
			}
		}
	});
};

export const getSets = (): AxiosPromise => {
	return axios.request({ url: ENDPOINT.SETS, method: 'GET' });
};

export interface IGetSetPayload {
	per_page: number;
	page: number;
}

export const getSet = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: `${ENDPOINT.SETS}/${(params as IGetObjectPayload).id}`,
		method: 'GET',
		params: params as IGetSetPayload
	});
};

export interface ICreateSetPayload {
	name: string;
	attributes: ISetPoi[];
}

export const createSet = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: ENDPOINT.SETS,
		method: 'POST',
		data: {
			excercise_set: {
				name: (params as ICreateSetPayload).name,
				excercises_attributes: (params as ICreateSetPayload).attributes
			}
		}
	});
};

export const removeSet = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: `${ENDPOINT.SETS}/${(params as IGetObjectPayload).id}`,
		method: 'DELETE'
	});
};

export interface IGetPoisPayload {
	age_group: string;
	city: string;
	per_page: number;
	page: number;
	track: string;
	game_type: string;
}

export const getPois = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: ENDPOINT.POIS,
		method: 'GET',
		params: params as IGetPoisPayload
	});
};

export const getPoi = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: `${ENDPOINT.POIS}/${(params as IGetObjectPayload).id}`,
		method: 'GET'
	});
};

export interface IGetExercisesPayload {
	exchange_code: string;
	per_page: number;
	page: number;
}

export const getExercises = ({ params }: IRequest): AxiosPromise => {
	return axios.request({
		url: ENDPOINT.EXERCISES,
		method: 'GET',
		params: params as IGetExercisesPayload
	});
};
