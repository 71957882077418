import React, { FunctionComponent, memo, ReactElement } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import { poppins, subheaderGrey, white } from '../../styles/variables';
import { Close } from '@material-ui/icons';
import ReactPlayer from 'react-player/lazy';
import { ICity } from '../../constants/types';

const useStyles = makeStyles(() => ({
	title: {
		backgroundColor: subheaderGrey,
		color: white,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	titleText: {
		flexGrow: 99,
		fontWeight: 900,
		fontSize: '2rem',
		fontFamily: poppins,
		letterSpacing: '0.45px',
		textAlign: 'center',
		textTransform: 'uppercase'
	},
	close: {
		flexGrow: 1,
		color: white,
		fontSize: '1rem'
	}
}));

interface IProps {
	isOpened: boolean;
	close: () => void;
	city: ICity;
}

const VideoDialog: FunctionComponent<IProps> = ({ isOpened, close, city }): ReactElement => {
	const classes = useStyles();

	return (
		<Dialog maxWidth={'lg'} open={isOpened} onClose={close} aria-labelledby="video-modal">
			<DialogTitle className={classes.title} id="video-modal" disableTypography>
				<div className={classes.titleText}>{city.name}</div>
				<IconButton className={classes.close} onClick={close}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<ReactPlayer url={city.video_url} height={'40rem'} width={'68rem'} controls />
			</DialogContent>
		</Dialog>
	);
};

export default memo(VideoDialog);
