import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { IUser, UserContext } from '../../context/UserContext';
import { useHistory, useLocation } from 'react-router-dom';
import { SubheaderContext } from '../../context/SubheaderContext';
import { useFormik } from 'formik';
import { IResetPasswordPayload, resetPassword } from '../../api';
import { AxiosResponse } from 'axios';
import { TOKEN, USER } from '../../constants/session';
import { ROUTES } from '../../routes/routes';
import { useStyles } from './Auth.style';
import { Paper, Typography } from '@material-ui/core';
import { SubmitButton } from '../shared/buttons';
import TextInput from '../shared/TextInput';
import resetValidator from './resetValidator';

const PasswordReset: FunctionComponent = (): ReactElement => {
	const { setUser } = useContext(UserContext);
	const [error, setError] = useState<string | undefined>(undefined);
	const history = useHistory();
	const location = useLocation();
	const param = useMemo(() => new URLSearchParams(location.search), [location.search]);
	const { setSubheader } = useContext(SubheaderContext);
	useEffect(() => {
		setSubheader({
			hidden: true
		});
	}, [setSubheader]);

	const formik = useFormik({
		initialValues: {
			reset_password_token: param.get('reset_password_token'),
			password: '',
			password_confirmation: ''
		} as IResetPasswordPayload,
		onSubmit: (values: IResetPasswordPayload) => {
			setError(undefined);
			resetPassword({ params: values })
				.then((resp: AxiosResponse<{ user: IUser }>) => {
					setUser(resp.data.user);
					window.sessionStorage.setItem(TOKEN, resp.data.user.authentication_token);
					window.sessionStorage.setItem(USER, resp.data.user.email);
					history.replace(ROUTES.main);
				})
				.catch(e => {
					console.log('error', e.response);
					if (e.response.data.error) {
						setError(e.response.data.error);
					}
					if (e.response.data.errors) {
						setError(e.response.data.errors);
					}
				});
		},
		validationSchema: resetValidator()
	});

	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Paper className={classes.paper} elevation={3}>
				<Typography className={classes.header}>Zmień Hasło</Typography>
				<form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
					{error && <div className={classes.error}>{error}</div>}
					<TextInput
						label="Wpisz nowe hasło"
						name="password"
						type="password"
						onChange={formik.handleChange}
						value={formik.values.password}
						touched={formik.touched.password}
						error={formik.errors.password}
					/>
					<TextInput
						label="Wpisz nowe hasło ponownie"
						name="password_confirmation"
						type="password"
						onChange={formik.handleChange}
						value={formik.values.password_confirmation}
						touched={formik.touched.password_confirmation}
						error={formik.errors.password_confirmation}
					/>
					<SubmitButton text={'Zmień hasło'} />
				</form>
			</Paper>
		</div>
	);
};

export default memo(PasswordReset);
