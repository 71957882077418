import { Button, makeStyles } from '@material-ui/core';
import { fontGrey75, lightBlack, mainRed, poppins, roboto, white } from '../../styles/variables';
import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useEffect,
	useState
} from 'react';
import { IGame, ITeacherPoi } from '../../constants/types';
import { renderGame } from './games/renderGame';
import { getPoi } from '../../api';
import { AxiosResponse } from 'axios';
import Loader from '../shared/Loader';
import GameDialog from './GameRulesDialog';
import { SubheaderContext } from '../../context/SubheaderContext';
import { ArrowBack } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: '10rem',
		paddingRight: '10rem'
	},
	backButton: {
		fontFamily: poppins,
		fontWeight: 500,
		textTransform: 'unset',
		letterSpacing: '0.45px',
		color: white
	},
	gameName: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundColor: mainRed,
		color: white,
		fontFamily: poppins,
		fontSize: '1.25rem',
		fontWeight: 600,
		letterSpacing: '0.52px',
		height: '4rem'
	},
	results: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		marginTop: '5.4rem'
	},
	title: {
		fontFamily: poppins,
		fontSize: '2rem',
		fontWeight: 'bold',
		letterSpacing: '0.9px',
		color: lightBlack
	},
	text: {
		marginTop: '1.5rem',
		fontFamily: roboto,
		fontSize: '1.25rem',
		lineHeight: '1.43',
		letterSpacing: '0.52px',
		color: fontGrey75,
		width: '25rem',
		textAlign: 'center'
	},
	points: {
		fontFamily: poppins,
		fontSize: '2.6rem',
		fontWeight: 'bold',
		letterSpacing: '1.2px',
		color: lightBlack
	}
}));

interface IProps {
	goBack?: () => void;
	poiId?: number;
	game?: IGame;
}

const NotRatedGame: FunctionComponent<IProps> = ({ goBack, poiId, game }): ReactElement => {
	const [isGameFinished, setGameFinished] = useState<boolean>(false);
	const [showInstruction, setShowInstruction] = useState<boolean>(true);
	const [gamePoints, setGamePoints] = useState<number>(0);
	const [loading, setLoading] = useState<boolean>(true);
	const [poi, setPoi] = useState<ITeacherPoi | undefined>();
	const { setSubheader } = useContext(SubheaderContext);
	const classes = useStyles();

	const handleGoBack = useCallback(() => {
		if (goBack) goBack();
	}, [goBack]);

	useEffect(() => {
		setSubheader({
			left: (
				<Button variant="text" className={classes.backButton} onClick={handleGoBack}>
					<ArrowBack style={{ fontSize: 12, marginRight: '0.25rem' }} />
					Wróć do zestawu
				</Button>
			),
			title: 'Gra'
		});
	}, [classes.backButton, handleGoBack, setSubheader]);

	useEffect(() => {
		if (poiId) {
			getPoi({ params: { id: poiId } })
				.then((resp: AxiosResponse<ITeacherPoi>) => {
					setPoi(resp.data);
					setLoading(false);
				})
				.catch(e => {
					setLoading(false);
					console.log('get game error');
				});
		} else {
			setLoading(false);
		}
	}, [poiId]);

	const closeInstructionModal = useCallback(() => {
		setShowInstruction(false);
	}, []);

	const finishGame = (gameId: number, points: number) => {
		setGameFinished(true);
		setGamePoints(points);
	};

	const findGame = useCallback((): IGame => {
		if (poiId && poi) return poi.game;
		if (game) return game;

		return {} as IGame;
	}, [game, poi, poiId]);

	if (loading) return <Loader />;
	if (poiId && !poi) return <></>;
	return (
		<>
			<GameDialog
				isOpened={Boolean(findGame().instructions?.length) && showInstruction}
				close={closeInstructionModal}
				game={findGame()}
			/>
			{/*<div className={classes.gameName}>{poi.name}</div>*/}
			{isGameFinished ? (
				<div className={classes.results}>
					<div className={classes.title}>Gra ukończona!</div>
					<div className={classes.text}>Zdobyte punkty</div>
					<div className={classes.points}>{gamePoints}</div>
				</div>
			) : (
				renderGame(findGame(), finishGame)
			)}
		</>
	);
};

export default memo(NotRatedGame);
