import React, { FunctionComponent, memo, ReactElement, useContext } from 'react';
import { LinearProgress, makeStyles } from '@material-ui/core';
import { poppins, white } from '../../styles/variables';
import { SelectionContext } from '../../context/SelectionContext';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		fontWeight: 500
	},
	text: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	value: {
		fontFamily: poppins,
		fontWeight: 'bold',
		fontSize: '1.2rem',
		letterSpacing: '0.5px',
		color: white
	},
	progressBar: {
		width: '100%',
		height: '0.5rem',
		borderRadius: '4px',
		marginBottom: '1.5rem'
	}
}));

const TrackProgress: FunctionComponent = (): ReactElement => {
	const classes = useStyles();
	const { selection } = useContext(SelectionContext);

	if (!selection.selectedTrack) return <></>;
	return (
		<div className={classes.root}>
			<div className={classes.text}>
				Poziom ukończenia trasy:{' '}
				<div className={classes.value}>{selection.selectedTrack.percent_complete}%</div>
			</div>
			<LinearProgress
				variant="determinate"
				value={selection.selectedTrack.percent_complete || 0}
				classes={{ root: classes.progressBar }}
			/>
		</div>
	);
};

export default memo(TrackProgress);
