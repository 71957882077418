import 'fontsource-roboto';
import 'fontsource-poppins/100.css';
import 'fontsource-poppins/200.css';
import 'fontsource-poppins/300.css';
import 'fontsource-poppins/400.css';
import 'fontsource-poppins/500.css';
import 'fontsource-poppins/600.css';
import 'fontsource-poppins/700.css';
import 'fontsource-poppins/800.css';
import 'fontsource-poppins/900.css';
import { fade } from '@material-ui/core';

const fallbackFonts = ['-apple-system', '"Segoe UI"', '"Ubuntu"', '"Helvetica Neue"', 'sans-serif'];
export const roboto = ['Roboto', 'Poppins', ...fallbackFonts].join(',');
export const poppins = ['Poppins', 'Roboto', ...fallbackFonts].join(',');

export const HEADER_PADDING = '5%';

export const NAVBAR_HEIGHT = '6.5rem';
export const FOOTER_HEIGHT = '2.5rem';
export const SUBHEADER_HEIGHT = '5.5rem';
export const MAP_DRAWER_WIDTH = '30rem';
export const CITY_DRAWER_HEIGHT = '13rem';

export const white = '#ffffff';
export const black = '#000000';
export const black007 = fade(black, 0.07);
export const lightBlack = '#263137';
export const footerGrey = '#f1f1f1';
export const footerFont = '#393745';
export const backgroundGrey = '#f9f9f9';
export const fontGrey = '#2f5266';
export const fontGrey75 = fade(fontGrey, 0.75);
// export const fontGrey75 = fade(fontGrey, 0.5);
export const fontGrey10 = fade(fontGrey, 0.1);
export const lightGrey = '#F5f7f7';
export const searchGrey = '#f4f5f5';
export const disabledBackground = '#E4E7E8';
export const disabledFont = '#9ea9af';
export const successGreen = '#34d54e';
export const failRed = '#f7682c';
export const subheaderGrey = '#1e333f';
export const mainRed = '#d13337';
export const mainRed50 = fade(mainRed, 0.5);
export const lineRed = '#F90618';

export const cardBackgroundBlue = '#2a4a5c';
export const cardBackgroundCircle = 'linear-gradient(to bottom, #1e333f 0%, #07212d 98%)';

export const border = (color: string, borderSize: string | number = 'thin') => {
	return `${borderSize} solid ${color}`;
};
