import { makeStyles } from '@material-ui/core';
import {
	border,
	fontGrey,
	fontGrey10,
	fontGrey75,
	mainRed,
	poppins,
	roboto,
	white
} from '../../styles/variables';

export const useStyles = makeStyles({
	root: {
		zIndex: 12,
		backgroundColor: white,
		width: '310px',
		height: '280px',
		borderRadius: '5px',
		position: 'absolute',
		paddingTop: '1.5rem',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'space-between',
		boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.24)',
		top: (props: { top: number; left: number }) => props.top,
		left: (props: { top: number; left: number }) => props.left
	},
	toTop: {
		'&::after': {
			top: '100%',
			left: '50%',
			content: "' '",
			height: '0',
			width: '0',
			position: 'absolute',
			display: 'block',
			pointerEvents: 'none',
			border: '6px solid transparent',
			borderBottom: 0,
			borderTop: '10px solid #ffffff'
		}
	},
	toBottom: {
		'&::after': {
			top: '-10px',
			left: '50px',
			content: "' '",
			height: '0',
			width: '0',
			position: 'absolute',
			display: 'block',
			pointerEvents: 'none',
			border: '6px solid transparent',
			borderTop: 0,
			borderBottom: '10px solid #ffffff'
		}
	},
	textContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	header: {
		height: '1.25rem',
		fontFamily: poppins,
		fontSize: '1.25rem',
		fontWeight: 'bold',
		letterSpacing: '0.6px',
		color: fontGrey,
		display: 'flex',
		alignItems: 'center'
	},
	logo: {
		height: '3.75rem',
		position: 'absolute',
		top: '-30px'
	},
	description: {
		paddingLeft: '1.25rem',
		paddingRight: '1.25rem',
		marginTop: '1rem',
		marginBottom: '0.25rem',
		fontFamily: roboto,
		fontSize: '1rem',
		lineHeight: 1.33,
		letterSpacing: '0.45px',
		textAlign: 'center',
		color: fontGrey75,
		height: '90px'
	},
	button: {
		marginTop: '0',
		fontWeight: 600,
		fontSize: '1rem',
		fontFamily: poppins,
		letterSpacing: '0.45px',
		textAlign: 'center',
		textTransform: 'none'
	},
	trackButtons: {
		height: '120px',
		marginTop: '0',
		display: 'flex',
		flexDirection: 'column'
	},
	trackButton: {
		overflow: 'auto',
		whiteSpace: 'nowrap',
		marginTop: '0',
		marginBottom: '2px',
		padding: '6px 15px',
		fontWeight: 600,
		fontSize: '1rem',
		fontFamily: poppins,
		letterSpacing: '0.45px',
		textAlign: 'center',
		textTransform: 'none'
	},
	bottomButton: {
		cursor: 'pointer',
		borderTop: border(fontGrey10),
		width: '100%',
		height: '40px',
		display: 'flex',
		alignItems: 'center',
		fontSize: '1rem',
		fontFamily: poppins,
		fontWeight: 'bold',
		color: mainRed,
		justifyContent: 'space-evenly'
	},
	closeButton: {
		position: 'absolute',
		right: '20px',
		padding: '1px'
	},
	soundButton: {
		color: mainRed,
		padding: '2px',
		marginLeft: '4px'
	}
});
