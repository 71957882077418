import React, { FunctionComponent, memo, ReactElement } from 'react';
import { CircularProgress, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
	root: {
		marginTop: '40vh',
		display: 'flex',
		justifyContent: 'center'
	},
	loader: {
		width: '10rem'
	}
}));

const Loader: FunctionComponent = (): ReactElement => {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<CircularProgress className={classes.loader} />
		</div>
	);
};
export default memo(Loader);
