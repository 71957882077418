import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useContext,
	useEffect,
	useMemo,
	useState
} from 'react';
import { useStyles } from './Auth.style';
import { Link, useLocation } from 'react-router-dom';
import SwitchButton from '../shared/buttons/SwitchButton';
import { Paper, Typography } from '@material-ui/core';
import { ROUTES } from '../../routes/routes';
import { ArrowForward } from '@material-ui/icons';
import StudentSignUpForm from './StudentSignUpForm';
import TeacherSignUpForm from './TeacherSignUpForm';
import { SubheaderContext } from '../../context/SubheaderContext';

const SignUp: FunctionComponent = (): ReactElement => {
	const classes = useStyles();
	const [isStudent, setIsStudent] = useState<boolean>(true);
	const location = useLocation();
	const { setSubheader } = useContext(SubheaderContext);
	useEffect(() => {
		setSubheader({
			hidden: true
		});
	}, [setSubheader]);

	const param = useMemo(() => new URLSearchParams(location.search), [location.search]);

	useEffect(() => {
		if (param.get('invitation_code')) {
			setIsStudent(false);
		}
	}, [param]);

	return (
		<div className={classes.root}>
			<Paper className={classes.paper} elevation={3}>
				<Typography className={classes.header}>Rejestracja</Typography>
				<SwitchButton
					value={isStudent}
					leftAction={() => setIsStudent(true)}
					rightAction={() => setIsStudent(false)}
					texts={{ left: 'Uczeń', right: 'Nauczyciel' }}
				/>
				{isStudent ? <StudentSignUpForm /> : <TeacherSignUpForm />}
				<Typography className={classes.additionalText}>Masz konto?</Typography>
				<Link className={classes.link} to={ROUTES.login}>
					Zaloguj się <ArrowForward style={{ fontSize: 12, marginLeft: '0.25rem' }} />
				</Link>
			</Paper>
		</div>
	);
};

export default memo(SignUp);
