import React, { FunctionComponent, memo, ReactElement, useCallback, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { Button } from '@material-ui/core';
import { useStyles } from './Pairs.style';
import PairCard from './PairCard';
import Separator from './Separator';
import { IConnectingGameData } from '../../../../constants/types';
import { shuffle } from '../utils';
import produce from 'immer';
import { arraysEqual } from '../../../../utils/arraysEqual';
import { arrayDiff } from '../../../../utils/arrayDiff';

interface IProps {
	gameData: IConnectingGameData;
	gameId: number;
	finishGame: (gameId: number, points: number) => void;
}

export interface IPairCard {
	id: string;
	value: string;
}

const Pairs: FunctionComponent<IProps> = ({ gameData, gameId, finishGame }): ReactElement => {
	const [points, setPoints] = useState<number>(0);
	const [showResult, setShowResult] = useState<boolean>(false);
	const buildGameState = (data: IConnectingGameData): IPairCard[][] => {
		const convertedData = [];
		for (let x: number = 0; x < data.columns[0].length; x++) {
			let row: IPairCard[] = [];
			data.columns.map((column: string[], idx: number) => {
				row.push({
					id: `${idx}-${x}`,
					value: column[x]
				});
			});
			convertedData.push(row);
		}
		return convertedData.map((column: IPairCard[], idx: number) => {
			return shuffle([...column]) as IPairCard[];
		});
	};

	const buildResultState = (data: IPairCard[][]): string[][] => {
		const convertedData = [];
		for (let x: number = 0; x < data[0].length; x++) {
			let row: string[] = [];
			data.map((column: IPairCard[], idx: number) => {
				row.push(column[x].value);
			});
			convertedData.push(row);
		}
		return convertedData;
	};
	const [state, setState] = useState<IPairCard[][]>(buildGameState(gameData));
	const [resultState, setResultState] = useState<string[][]>([]);

	const onDragEnd = (result: DropResult, rowId: number) => {
		const { source, destination } = result;
		if (!destination) return;
		if (source.index === destination.index) return;
		setState((prevState: IPairCard[][]) =>
			produce(prevState, draft => {
				const draggedItem = draft[rowId][source.index];
				draft[rowId].splice(source.index, 1);
				draft[rowId].splice(destination.index, 0, draggedItem);
				return draft;
			})
		);
	};

	const checkSelectedAnswer = useCallback(() => {
		setShowResult(true);
		const resState = buildResultState(state);
		setResultState(resState);
		if (resState[0].length === 2) {
			//	test pairs
			for (let i = 0; i < resState.length; i++) {
				if (gameData.columns.find(arr => arraysEqual(arr, resState[i]))) {
					setPoints((prevState: number) => prevState + 2);
				}
			}
		} else {
			for (let i = 0; i < resState.length; i++) {
				if (gameData.columns.find(arr => arraysEqual(arr, resState[i]))) {
					setPoints((prevState: number) => prevState + 3);
				} else {
					gameData.columns.map((col: string[]) => {
						// this will return symmetric difference so for one mistake length should be 2
						if (arrayDiff(col, resState[i]).length === 2) {
							setPoints((prevState: number) => prevState + 1);
						}
					});
				}
			}
		}
	}, [gameData, state]);

	const isCardCorrect = useCallback(
		(card: string) => {
			const correctArr = gameData.columns.filter(arr => arr.includes(card))[0];
			const resultArr = resultState.filter(arr => arr.includes(card))[0];
			return arraysEqual(correctArr, resultArr);
		},
		[resultState, gameData]
	);

	const handleFinishGame = () => finishGame(gameId, points);

	const classes = useStyles({ cardCount: state[0].length });
	return (
		<div className={classes.container}>
			<div className={classes.pairLabels}>
				{state[0].map((row: IPairCard, i: number) => (
					<div key={i} className={classes.pairLabel}>{`Zestaw ${i + 1}`}</div>
				))}
			</div>
			{state.map((row: IPairCard[], rowIdx: number) => (
				<div className={classes.row} key={rowIdx}>
					<DragDropContext onDragEnd={dropRes => onDragEnd(dropRes, rowIdx)}>
						<Droppable droppableId="droppable" direction="horizontal">
							{provided => (
								<>
									<div
										ref={provided.innerRef}
										className={classes.list}
										{...provided.droppableProps}
									>
										{row.map((card: IPairCard, index: number) => (
											<Draggable
												key={card.id}
												draggableId={card.id}
												index={index}
											>
												{provided => (
													<div
														ref={provided.innerRef}
														{...provided.draggableProps}
														{...provided.dragHandleProps}
														className={classes.card}
													>
														<PairCard
															card={card}
															showResult={showResult}
															isCorrect={isCardCorrect(card.value)}
														/>
													</div>
												)}
											</Draggable>
										))}
										{provided.placeholder}
									</div>
									{rowIdx < state.length - 1 && (
										<div className={classes.separators}>
											{row.map((card: IPairCard, index: number) => (
												<div key={index} className={classes.separator}>
													<Separator />
												</div>
											))}
										</div>
									)}
								</>
							)}
						</Droppable>
					</DragDropContext>
				</div>
			))}

			{showResult ? (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={handleFinishGame}
				>
					{'Zakończ grę'}
				</Button>
			) : (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={checkSelectedAnswer}
				>
					{'Sprawdź odpowiedź'}
				</Button>
			)}
		</div>
	);
};

export default memo(Pairs);
