import { useContext, useEffect, useState } from 'react';
import { getUser } from '../api';
import { AxiosResponse } from 'axios';
import { IUser, UserContext } from '../context/UserContext';
import { TOKEN, USER } from '../constants/session';
import { ROUTES } from '../routes/routes';
import { useHistory } from 'react-router-dom';

const useSessionValidation = (): { loading: boolean } => {
	const [loading, setIsLoading] = useState<boolean>(true);
	const history = useHistory();
	const { setUser } = useContext(UserContext);
	useEffect(() => {
		const token = window.sessionStorage.getItem(TOKEN);
		if (token) {
			getUser()
				.then((resp: AxiosResponse<IUser>) => {
					setIsLoading(false);
					setUser(resp.data);
					window.sessionStorage.setItem(TOKEN, resp.data.authentication_token);
					window.sessionStorage.setItem(USER, resp.data.email);
				})
				.catch(e => {
					console.log(e);
					setIsLoading(false);
					setUser(undefined);
					window.sessionStorage.removeItem(TOKEN);
					window.sessionStorage.removeItem(USER);
					history.replace(ROUTES.main);
				});
		} else {
			console.log('no token');
			setIsLoading(false);
			setUser(undefined);
			history.replace(ROUTES.main);
		}
	}, [history, setUser]);

	return { loading };
};

export default useSessionValidation;
