import React, { FunctionComponent, memo, ReactElement } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { border, failRed, successGreen } from '../../../../styles/variables';
import { IGroupCard } from './Groups';

export const CARD_SIZE = '100px';

const useStyles = makeStyles(() => ({
	card: {
		cursor: 'pointer',
		width: CARD_SIZE,
		height: CARD_SIZE,
		borderRadius: '8px',
		boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.33)',
		boxSizing: 'border-box',
		marginRight: '10px' // important value for smooth dnd
	},
	correct: {
		border: border(successGreen, '3px')
	},
	incorrect: {
		border: border(failRed, '3px')
	}
}));

interface IProps {
	card: IGroupCard;
	showResult: boolean;
	isCorrect: boolean;
}

const Card: FunctionComponent<IProps> = ({ card, showResult, isCorrect }): ReactElement => {
	const classes = useStyles();

	return (
		<img
			className={clsx(
				classes.card,
				showResult && isCorrect && classes.correct,
				showResult && !isCorrect && classes.incorrect
			)}
			src={card.value}
			alt={`card-${card.id}`}
		/>
	);
};

export default memo(Card);
