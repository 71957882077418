import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useEffect,
	useState
} from 'react';
import produce from 'immer';
import { useStyles } from './Order.style';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import { IOrderingGameData } from '../../../../constants/types';
import { shuffle } from '../utils';
import { Button } from '@material-ui/core';
import OrderCard from './OrderCard';

interface IProps {
	gameData: IOrderingGameData;
	gameId: number;
	finishGame: (gameId: number, points: number) => void;
}

export interface IOrderCard {
	id: string;
	value: string;
	type: 'image' | 'text';
}

const Order: FunctionComponent<IProps> = ({ gameData, gameId, finishGame }): ReactElement => {
	const [points, setPoints] = useState<number>(0);
	const [round, setRound] = useState<number>(0);
	const [showNext, setShowNext] = useState<boolean>(false);
	const [showResult, setShowResult] = useState<boolean>(false);
	const [roundState, setRoundState] = useState<IOrderCard[]>(
		(shuffle([...gameData.tasks[round].items]) as string[]).map((item: string, i: number) => ({
			id: `${round}-${i}`,
			value: item,
			type: gameData.tasks[round].type
		}))
	);

	useEffect(() => {
		if (round < gameData.tasks.length) {
			setRoundState(
				(shuffle([...gameData.tasks[round].items]) as string[]).map(
					(item: string, i: number) => ({
						id: `${round}-${i}`,
						value: item,
						type: gameData.tasks[round].type
					})
				)
			);
		}
	}, [round, gameData.tasks]);

	const labels = (): number[] => {
		const items = [];
		for (let i = 0; i < roundState.length; i++) {
			items.push(i + 1);
		}
		return items;
	};

	const onDragEnd = (result: DropResult) => {
		const { source, destination } = result;
		if (!destination) return;
		if (source.index === destination.index) return;
		setRoundState((prevState: IOrderCard[]) =>
			produce(prevState, draft => {
				const draggedItem = draft[source.index];
				draft.splice(source.index, 1);
				draft.splice(destination.index, 0, draggedItem);
				return draft;
			})
		);
	};

	const checkSelectedAnswer = useCallback(() => {
		setShowNext(true);
		setShowResult(true);
		for (let i = 0; i < roundState.length; i++) {
			if (roundState[i].value === gameData.tasks[round].items[i]) {
				setPoints((prevState: number) => prevState + 1);
			}
		}
	}, [setShowNext, setShowResult, roundState, round, gameData.tasks]);

	const showNextRound = useCallback(() => {
		setShowNext(false);
		setShowResult(false);
		setRound((prevState: number) => prevState + 1);
	}, [setShowNext, setShowResult, setRound]);

	const handleFinishGame = () => finishGame(gameId, points);

	const classes = useStyles({ cardCount: roundState.length });
	return (
		<div className={classes.container}>
			<div className={classes.labels}>
				{labels().map((label: number) => (
					<div key={label} className={classes.labelItem}>
						{label}
					</div>
				))}
			</div>
			<DragDropContext onDragEnd={onDragEnd}>
				<Droppable droppableId="droppable" direction="horizontal">
					{provided => (
						<div
							ref={provided.innerRef}
							className={classes.list}
							{...provided.droppableProps}
						>
							{roundState.map((card: IOrderCard, index: number) => (
								<Draggable key={card.id} draggableId={card.id} index={index}>
									{provided => (
										<div
											ref={provided.innerRef}
											{...provided.draggableProps}
											{...provided.dragHandleProps}
											className={classes.card}
										>
											<OrderCard
												card={card}
												showResult={showResult}
												initialState={gameData.tasks[round]}
												roundState={roundState}
											/>
										</div>
									)}
								</Draggable>
							))}
							{provided.placeholder}
						</div>
					)}
				</Droppable>
			</DragDropContext>
			{showNext && (
				<div className={classes.list}>
					{gameData.tasks[round].items.map((item: string, i: number) => (
						<div className={classes.card} key={i}>
							<OrderCard
								card={
									{
										value: item,
										id: item,
										type: gameData.tasks[round].type
									} as IOrderCard
								}
								showResult={false}
								initialState={gameData.tasks[round]}
								roundState={roundState}
							/>
						</div>
					))}
				</div>
			)}
			<div className={classes.instruction}>{gameData.tasks[round].instruction}</div>

			{showNext ? (
				<>
					{gameData.tasks[round + 1] ? (
						<Button
							type="button"
							fullWidth
							variant="outlined"
							className={classes.button}
							onClick={showNextRound}
						>
							{'Następna runda'}
						</Button>
					) : (
						<Button
							type="button"
							fullWidth
							variant="outlined"
							className={classes.button}
							onClick={handleFinishGame}
						>
							{'Zakończ grę'}
						</Button>
					)}
				</>
			) : (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={checkSelectedAnswer}
				>
					{'Sprawdź odpowiedź'}
				</Button>
			)}
		</div>
	);
};

export default memo(Order);
