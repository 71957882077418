import React, { FunctionComponent, memo, ReactElement, useMemo } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';
import { border, failRed, successGreen, white } from '../../../../styles/variables';
import { IOrderCard } from './Order';
import { CARD_SIZE } from './Order.style';

const useStyles = makeStyles(() => ({
	image: {
		cursor: 'pointer',
		width: CARD_SIZE,
		height: CARD_SIZE,
		borderRadius: '8px',
		boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.33)',
		boxSizing: 'border-box'
	},
	text: {
		cursor: 'pointer',
		width: CARD_SIZE,
		height: CARD_SIZE,
		borderRadius: '8px',
		boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.33)',
		backgroundImage: 'linear-gradient(to bottom, #1e333f 2%, #07212d 92%)',
		color: white,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		boxSizing: 'border-box'
	},
	correct: {
		border: border(successGreen, '3px')
	},
	incorrect: {
		border: border(failRed, '3px')
	}
}));

interface IProps {
	card: IOrderCard;
	showResult: boolean;
	initialState: any;
	roundState: IOrderCard[];
}

const OrderCard: FunctionComponent<IProps> = ({
	card,
	showResult,
	initialState,
	roundState
}): ReactElement => {
	const classes = useStyles();

	const isCorrect = useMemo(() => {
		const currentIndex = roundState.findIndex((c: IOrderCard) => c.value === card.value);
		return initialState.items[currentIndex] === card.value;
	}, [initialState, card.value, roundState]);

	if (card.type === 'image') {
		return (
			<img
				className={clsx(
					classes.image,
					showResult && isCorrect && classes.correct,
					showResult && !isCorrect && classes.incorrect
				)}
				src={card.value}
				alt={`ans-${card.value}`}
			/>
		);
	}
	return (
		<div
			className={clsx(
				classes.text,
				showResult && isCorrect && classes.correct,
				showResult && !isCorrect && classes.incorrect
			)}
		>
			{card.value}
		</div>
	);
};

export default memo(OrderCard);
