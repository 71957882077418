import { makeStyles } from '@material-ui/core';
import {
	fontGrey,
	lightBlack,
	mainRed,
	mainRed50,
	poppins,
	roboto,
	white
} from '../../../../styles/variables';

export const CARD_SIZE = '140px';
const SPACE_BETWEEN_CARDS = '25px';

export const useStyles = makeStyles(() => ({
	container: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	},
	row: { display: 'flex', flexDirection: 'column' },
	list: {
		display: 'flex',
		justifyContent: 'space-between',
		width: (props: { cardCount: number }) =>
			`calc(${props.cardCount} * ${CARD_SIZE} + ${
				props.cardCount - 1
			} * ${SPACE_BETWEEN_CARDS})`,
		marginTop: '0.25rem'
	},
	pairLabels: {
		width: (props: { cardCount: number }) =>
			`calc(${props.cardCount} * ${CARD_SIZE} + ${
				props.cardCount - 1
			} * ${SPACE_BETWEEN_CARDS})`,
		display: 'flex',
		justifyContent: 'space-between'
	},
	pairLabel: {
		fontFamily: poppins,
		fontSize: '1.2rem',
		fontWeight: 'bold',
		color: lightBlack,
		width: CARD_SIZE,
		textAlign: 'center'
	},
	card: {
		width: CARD_SIZE,
		userSelect: 'none',
		height: CARD_SIZE,
		marginRight: SPACE_BETWEEN_CARDS,
		textAlign: 'center'
	},
	separators: {
		width: (props: { cardCount: number }) =>
			`calc(${props.cardCount} * ${CARD_SIZE} + ${
				props.cardCount - 1
			} * ${SPACE_BETWEEN_CARDS})`,
		display: 'flex',
		justifyContent: 'space-between'
	},
	separator: {
		marginBottom: '5px',
		marginTop: '5px',
		width: CARD_SIZE,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	instruction: {
		marginTop: '2rem',
		textAlign: 'center',
		fontFamily: roboto,
		fontSize: '1.2rem',
		lineHeight: '1.25',
		letterSpacing: '0.5px',
		color: fontGrey
	},
	points: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		marginTop: '3.3rem'
	},
	pointsLabel: {
		fontFamily: roboto,
		fontSize: '1.2rem',
		lineHeight: '1.25',
		letterSpacing: '0.5px',
		color: fontGrey
	},
	pointsValue: {
		marginTop: '0.8rem',
		fontFamily: poppins,
		fontSize: '2rem',
		fontWeight: 'bold',
		color: lightBlack
	},
	button: {
		marginTop: '2rem',
		width: '21rem',
		height: '3rem',
		fontFamily: poppins,
		fontWeight: 600,
		letterSpacing: '0.45px',
		color: white,
		backgroundColor: mainRed,
		'&:hover': {
			backgroundColor: mainRed50,
			color: white
		}
	}
}));
