import React, { FunctionComponent, memo, ReactElement } from 'react';
import { makeStyles, Toolbar } from '@material-ui/core';
import {
	HEADER_PADDING,
	poppins,
	SUBHEADER_HEIGHT,
	subheaderGrey,
	white
} from '../../styles/variables';

const useStyles = makeStyles(() => ({
	root: {
		zIndex: 10,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: HEADER_PADDING,
		paddingRight: HEADER_PADDING,
		backgroundColor: subheaderGrey,
		color: white,
		fontFamily: poppins,
		height: SUBHEADER_HEIGHT
	},
	sides: {
		width: '18rem'
	},
	title: {
		textTransform: 'uppercase',
		fontWeight: 'bold',
		fontSize: '2rem',
		letterSpacing: '0.9px'
	},
	exTitleRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	exTitle: {
		fontSize: '2rem',
		fontWeight: 900
	},
	exSubTitle: {
		fontSize: '1.5rem',
		fontWeight: 'bold'
	}
}));

interface IProps {
	left?: ReactElement;
	title: string;
	subtitle?: string;
	right?: ReactElement;
}

const Subheader: FunctionComponent<IProps> = ({ left, title, right, subtitle }): ReactElement => {
	const classes = useStyles();
	if (subtitle) {
		return (
			<Toolbar className={classes.root}>
				<div className={classes.sides}>{left}</div>
				<div className={classes.exTitleRoot}>
					<div className={classes.exTitle}>{title}</div>
					<div className={classes.exSubTitle}>{subtitle}</div>
				</div>
				<div className={classes.sides}>{right}</div>
			</Toolbar>
		);
	}

	return (
		<Toolbar className={classes.root}>
			<div className={classes.sides}>{left}</div>
			<div className={classes.title}>{title}</div>
			<div className={classes.sides}>{right}</div>
		</Toolbar>
	);
};

export default memo(Subheader);
