export enum VIEW_MODE {
	CITY = 'CITY',
	TRACK = 'TRACK'
}

export type IAgeGroup = 'elementary' | 'middle' | 'high';
export const ageGroups = [
	{ v: 'elementary', n: '5-9 lat' },
	{ v: 'middle', n: '10-14 lat' },
	{ v: 'high', n: '14+ lat' }
];

export const renderAgeGroup = (group: string): string => {
	return ageGroups.filter((g: { v: string; n: string }) => g.v === group)[0].n;
};

export interface ICountry {
	name_pl: string;
	name_en: string;
	code: string;
}

export interface ICity {
	id: number;
	name: string;
	x: number;
	y: number;
	text_x: number;
	text_y: number;
	description: string;
	emblem_url: string;
	image_url: string;
	video_url: string;
}

export interface ITrack {
	id: number;
	name: string;
	percent_complete: number;
	age_group: IAgeGroup;
	waypoints: IWayPoint[];
	badge_image_url: string;
}

export interface IWayPoint {
	id: number;
	position: number;
	waypoint_stats: string;
	locked: boolean;
	city: ICity;
}

export interface IWayPointFull extends IWayPoint {
	track: ITrack;
	pois: IPoi[];
}

export interface IPoi {
	id: number;
	name: string;
	tooltip_message: string;
	x_pos: number;
	y_pos: number;
	game: IGame;
}

export interface IRanking {
	id: number;
	country: string;
	nickname: string;
	age_group: string;
	total_score: number;
	position: number;
}

export interface IGame {
	already_played?: boolean;
	user_points?: string;
	id: number;
	game_type: GAME;
	instructions?: string;
	max_points: number;
	game_data:
		| IMemoryGameData
		| IOrderingGameData
		| IQuizGameData
		| IHotOrNotGameData
		| IGroupsGameData
		| IConnectingGameData
		| ITextFillGameData
		| IWordcloudGameData
		| IImageFillGameData;
}

export enum GAME {
	MEMORY = 'memory',
	ORDER = 'ordering',
	QUIZ = 'quiz',
	COLLECTIONS = 'collections',
	CONNECTING = 'connecting',
	TEXTFILL = 'text_filling',
	IMGFILL = 'image_filling',
	WORDCLOUD = 'wordcloud',
	HOTORNOT = 'true_or_false',
	EMPTY = 'empty'
}

export const games = [
	{ v: GAME.MEMORY, n: 'Memory' },
	{ v: GAME.ORDER, n: 'Kolejność' },
	{ v: GAME.QUIZ, n: 'Quiz' },
	{ v: GAME.COLLECTIONS, n: 'Zbiory' },
	{ v: GAME.CONNECTING, n: 'Łączenie' },
	{ v: GAME.TEXTFILL, n: 'Uzupełnianie tekstem' },
	{ v: GAME.IMGFILL, n: 'Uzupełnianie obrazkami' },
	{ v: GAME.WORDCLOUD, n: 'Chmura słów' },
	{ v: GAME.HOTORNOT, n: 'Prawda / fałsz' },
	{ v: GAME.EMPTY, n: 'Pusta' }
];

export const renderGameType = (game: GAME): string => {
	return games.filter((g: { v: GAME; n: string }) => g.v === game)[0].n;
};

export interface IMemoryGameData {
	pairs: IMemoryPair[];
	number_of_pairs: number;
}

export interface IMemoryPair {
	id: number;
	value: string;
}

export interface IOrderingGameData {
	tasks: { type: 'image' | 'text'; items: string[]; instruction: string }[];
}

export interface IQuizGameData {
	questions: IQuestion[];
}

export interface IAnswer {
	type: 'image' | 'text';
	answer: string;
}

export interface IQuestion {
	question: string;
	question_image_url: string;
	answers: IAnswer[];
	good_answer: IAnswer;
}

export interface IHotOrNotGameData {
	questions: ITrueFalseQuestion[];
}

export interface ITrueFalseQuestion {
	answer: boolean;
	question: string;
	question_image_url: string;
}

export interface IGroupsGameData {
	tasks: IGroupingTask[];
	all_items: string[];
}

export interface IGroupingTask {
	collections: ICollection[];
	task_instruction: string;
}

export interface ICollection {
	name: string;
	items: string[];
}
export interface IConnectingGameData {
	columns: IColumn[];
	items_type: string;
}

export type IColumn = string[];

export interface IImageFillGameData {
	text: string;
	all_images: string[];
	answer_images: string[];
}

export interface ITextFillGameData {
	text: string;
	all_words: string[];
	answer_words: string[];
}

export interface IWordcloudGameData {
	all_words: string[];
	good_words: string[];
}

export interface IEmptyGameData {}

export enum TEACHER_VIEW_MODE {
	INDEX = 'INDEX',
	SHOW = 'SHOW',
	CREATE = 'CREATE'
}

export interface ISet {
	id: number;
	name: string;
	exchange_code: string;
	age_groups: string[];
}

export interface ISetPoi {
	poi_id: number;
	position: number;
}

export interface ISetPoiData {
	age_group: string;
	city_name: string;
	game_type: string;
	id: number;
	name: string;
	tooltip_message: string;
	track_name: string;
}

export interface IPoisResponse {
	age_groups: IAgeGroup[];
	cities: { id: number; name: string }[];
	game_types: string[];
	tracks: { id: number; name: string }[];
	pois: ISetPoiData[];
}

export interface ISetResponse {
	age_groups: IAgeGroup[];
	exchange_code: string;
	id: number;
	name: string;
	excercises: IExercise[];
	pagination_meta: IPaginationMeta;
}

export interface IExercise {
	id: number;
	city: { id: number; name: string };
	game: IGame;
	position: number;
	track: { id: number; name: string; age_group: string };
	poi: { id: number; name: string; tooltip_message: string };
}

export interface ITeacherPoi {
	game: IGame;
	id: number;
	name: string;
	tootlip_message: string;
}

export interface IPaginationMeta {
	total_count: number;
	total_pages: number;
}
