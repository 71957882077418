import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useMemo,
	useState
} from 'react';
import { Button, makeStyles, TextField } from '@material-ui/core';
import { ITextFillGameData } from '../../../../constants/types';
import {
	border,
	failRed,
	fontGrey,
	fontGrey10,
	lightBlack,
	mainRed,
	mainRed50,
	poppins,
	roboto,
	successGreen,
	white
} from '../../../../styles/variables';
import { shuffle } from '../utils';
import { useFormik } from 'formik';
import clsx from 'clsx';
import { Close, Done } from '@material-ui/icons';
import produce from 'immer';

interface IProps {
	gameData: ITextFillGameData;
	gameId: number;
	finishGame: (gameId: number, points: number) => void;
}

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '1rem',
		flexDirection: 'column'
	},
	counter: {
		fontFamily: poppins,
		fontSize: '2rem',
		fontWeight: 'bold',
		lineHeight: '1.25',
		letterSpacing: '1.2px',
		color: lightBlack,
		paddingBottom: '1rem'
	},
	text: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		fontFamily: poppins,
		fontSize: '1.25rem',
		fontWeight: 'bold',
		maxWidth: '70rem',
		paddingTop: '1rem',
		paddingBottom: '1rem',
		lineHeight: '3rem',
		alignItems: 'flex-end'
	},
	part: {
		minHeight: '5rem',
		display: 'flex'
	},
	partText: {
		marginLeft: '0.25rem',
		marginRight: '0.25rem',
		display: 'inline-block',
		alignSelf: 'flex-end'
	},
	allWords: {
		borderRadius: '5px',
		border: border(fontGrey10),
		padding: '2rem',
		display: 'flex',
		justifyContent: 'center',
		maxWidth: '40rem',
		flexWrap: 'wrap'
	},
	word: {
		color: mainRed,
		fontFamily: poppins,
		fontSize: '1.25rem',
		fontWeight: 'bold',
		padding: '1rem'
	},
	button: {
		marginTop: '2rem',
		width: '21rem',
		height: '3rem',
		fontFamily: poppins,
		fontWeight: 600,
		letterSpacing: '0.45px',
		color: white,
		backgroundColor: mainRed,
		'&:hover': {
			backgroundColor: mainRed50,
			color: white
		}
	},
	instruction: {
		color: fontGrey,
		fontSize: '1.2rem',
		fontFamily: roboto
	},
	textFillInput: {
		width: '8rem'
	},
	input: {
		color: fontGrey,
		fontFamily: poppins,
		padding: '4px 0 4px'
	},
	inputCorrect: {
		lineHeight: '1rem',
		color: successGreen,
		fontFamily: poppins,
		fontWeight: 600,
		fontSize: '1rem'
	},
	inputIncorrect: {
		lineHeight: '1rem',
		color: failRed,
		fontFamily: poppins,
		fontWeight: 600,
		fontSize: '1rem'
	},
	textFillInputRoot: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginLeft: '0.5rem',
		marginRight: '0.5rem'
	}
}));

const TextFill: FunctionComponent<IProps> = ({ gameData, gameId, finishGame }): ReactElement => {
	const classes = useStyles();
	const [tryCount, setTryCount] = useState<number>(1);
	const [points, setPoints] = useState<number>(0);
	const [finishedGame, setFinishedGame] = useState<boolean>(false);
	const [answers, setAnswers] = useState<boolean[]>([]);

	const shuffledWords = useMemo(() => shuffle(gameData.all_words) as string[], [gameData]);

	const handleGameFinish = useCallback(() => {
		finishGame(gameId, points);
	}, [finishGame, points, gameId]);

	const getInitialValues = () => {
		const values: Record<string, string> = {};
		gameData.answer_words.map((v: string, i: number) => (values[`input-${i}`] = ''));
		return values;
	};

	const formik = useFormik({
		initialValues: getInitialValues(),
		onSubmit: () => {}
	});

	const checkFirstTry = () => {
		for (let i = 0; i < gameData.answer_words.length; i++) {
			if (formik.values[`input-${i}`] === gameData.answer_words[i]) {
				setPoints(prev => prev + 2);
				setAnswers(prev => [...prev, true]);
			} else {
				setAnswers(prev => [...prev, false]);
			}
		}
		// give a second chance
		setTryCount(2);
	};

	const checkSecondTry = () => {
		for (let i = 0; i < gameData.answer_words.length; i++) {
			if (!answers[i]) {
				if (formik.values[`input-${i}`] === gameData.answer_words[i]) {
					// add points
					setAnswers((prevState: boolean[]) =>
						produce(prevState, draft => {
							draft[i] = true;
							return draft;
						})
					);
					setPoints(prev => prev + 1);
				}
			}
		}

		setFinishedGame(true);
	};

	const allInputsFilled = useMemo(
		() => Object.values(formik.values).every((v: string) => v !== ''),
		[formik.values]
	);

	return (
		<div className={classes.root}>
			<div className={classes.counter}>{`Próba ${tryCount}/2`}</div>
			<div className={classes.instruction}>Uzupełnij tekst frazami</div>
			<div className={classes.text}>
				{gameData.text.split('$$$$$').map((part: string, idx: number) => (
					<React.Fragment key={idx}>
						{part.split(' ').map((word: string, i: number) => (
							<span key={i} className={classes.partText}>
								{` ${word}`}
							</span>
						))}
						{idx < gameData.answer_words.length && (
							<TextFillInput
								inputId={idx}
								disabled={answers[idx] && tryCount === 2}
								showResult={tryCount === 2}
								isCorrect={answers[idx]}
								value={formik.values[`input-${idx}`]}
								onChange={formik.handleChange}
							/>
						)}
					</React.Fragment>
				))}
			</div>
			<div className={classes.allWords}>
				{shuffledWords.map((word: string, i: number) => (
					<div key={i} className={classes.word}>
						{word}
					</div>
				))}
			</div>
			{tryCount === 1 && (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={checkFirstTry}
					disabled={!allInputsFilled}
				>
					{'Sprawdź'}
				</Button>
			)}
			{tryCount === 2 && !finishedGame && (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={checkSecondTry}
					disabled={!allInputsFilled}
				>
					{'Sprawdź'}
				</Button>
			)}
			{finishedGame && (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={handleGameFinish}
				>
					{'Zakończ'}
				</Button>
			)}
		</div>
	);
};

interface ITextFillProps {
	inputId: number;
	disabled: boolean;
	showResult: boolean;
	value: string;
	onChange: any;
	isCorrect: boolean;
	inputProps?: any;
}

const TextFillInput: FunctionComponent<ITextFillProps> = ({
	inputId,
	disabled,
	showResult,
	isCorrect,
	value,
	onChange,
	inputProps
}): ReactElement => {
	const classes = useStyles();

	return (
		<div className={classes.textFillInputRoot}>
			{showResult && isCorrect && (
				<div className={classes.inputCorrect}>
					Dobrze
					<Done
						style={{
							color: successGreen,
							fontSize: 12,
							marginRight: '0.25rem'
						}}
					/>
				</div>
			)}
			{showResult && !isCorrect && (
				<div className={classes.inputIncorrect}>
					Błąd{' '}
					<Close
						style={{
							color: failRed,
							fontSize: 12,
							marginRight: '0.25rem'
						}}
					/>
				</div>
			)}
			<TextField
				autoComplete={'off'}
				autoFocus={false}
				className={classes.textFillInput}
				variant="standard"
				id={`input-${inputId}`}
				name={`input-${inputId}`}
				onChange={onChange}
				value={value}
				disabled={disabled}
				inputProps={{ style: { textAlign: 'center' } }}
				InputProps={{
					...inputProps,
					classes: {
						input: clsx(
							classes.input,
							showResult && isCorrect && classes.inputCorrect,
							showResult && !isCorrect && classes.inputIncorrect
						)
					}
				}}
			/>
		</div>
	);
};

export default memo(TextFill);
