import React, { FunctionComponent, memo, ReactElement } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { fontGrey, poppins, subheaderGrey, white } from '../../styles/variables';

export const useStyles = makeStyles(() => ({
	title: {
		backgroundColor: subheaderGrey,
		color: white,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	titleText: {
		fontWeight: 900,
		fontSize: '2rem',
		fontFamily: poppins,
		letterSpacing: '0.45px',
		textAlign: 'center',
		textTransform: 'uppercase'
	},
	close: {
		color: white,
		fontSize: '1rem',
		position: 'absolute',
		right: '1rem'
	},
	content: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		width: '55rem'
	},
	contentRoot: { display: 'flex', flexDirection: 'column', width: '50%' },
	text: {
		fontWeight: 900,
		fontSize: '1.5rem',
		fontFamily: poppins,
		letterSpacing: '0.45px',
		textAlign: 'center',
		textTransform: 'unset',
		paddingTop: '2rem',
		paddingBottom: '4rem',
		color: fontGrey
	}
}));

interface IProps {
	isOpened: boolean;
	close: () => void;
}

const PasswordDialog: FunctionComponent<IProps> = ({ isOpened, close }): ReactElement => {
	const classes = useStyles();
	return (
		<Dialog maxWidth={'lg'} open={isOpened} onClose={close} aria-labelledby="pass-modal">
			<DialogTitle className={classes.title} id="pass-modal" disableTypography>
				<div>
					<div className={classes.titleText}>Zmień Hasło</div>
				</div>
				<IconButton className={classes.close} onClick={close}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent className={classes.content}>
				<div className={classes.contentRoot}>
					<div className={classes.text}>
						Link do zmiany hasła został wysłany na adres email
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};

export default memo(PasswordDialog);
