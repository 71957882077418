import React, {
	ChangeEvent,
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useContext,
	useMemo,
	useState
} from 'react';
import {
	Button,
	LinearProgress,
	List,
	ListItem,
	makeStyles,
	MenuItem,
	TextField
} from '@material-ui/core';
import { SelectionContext } from '../../../context/SelectionContext';

import clsx from 'clsx';
import { ArrowForward, PlayArrow } from '@material-ui/icons';
import { ageGroups, IAgeGroup, ITrack, IWayPoint, renderAgeGroup } from '../../../constants/types';
import { useStyles } from '../MapDrawer.style';
import {
	fontGrey,
	fontGrey75,
	mainRed,
	mainRed50,
	poppins,
	roboto
} from '../../../styles/variables';
import { DataContext } from '../../../context/DataContext';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '../../../routes/routes';
import cityDone from './miasto_done.svg';
import cityBlocked from './miasto_blocked.svg';
import cityInProgress from './miasto_in_progress.svg';
import { waypointStatsAsValue } from '../../../utils/waypointStatsAsValue';
import { USER_ROLE, UserContext } from '../../../context/UserContext';
import SelectInput from '../../shared/SelectInput';

const useStepperStyles = makeStyles({
	stepper: {
		display: 'flex',
		flexDirection: 'column'
	},
	step: {
		display: 'flex'
	},
	iconAndLine: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	line: {
		borderLeft: `2px solid ${mainRed}`,
		height: '30px',
		marginTop: '-1px',
		marginBottom: '-1px'
	},
	lockedLine: {
		borderLeft: `2px solid ${fontGrey75}`
	},
	labels: {
		display: 'flex',
		flexDirection: 'column',
		marginLeft: '1rem',
		marginTop: '0.25rem'
	},
	cityName: {
		fontFamily: poppins,
		fontSize: '1.20rem',
		fontWeight: 500,
		lineHeight: 1.29,
		letterSpacing: '0.52px',
		color: mainRed,
		marginBottom: '0.5rem'
	},
	cityPoints: {
		fontSize: '1rem',
		fontFamily: roboto,
		letterSpacing: '0.45px',
		color: mainRed50
	},
	cityLocked: {
		color: fontGrey
	},
	trackButton: {
		overflow: 'auto',
		whiteSpace: 'nowrap',
		marginTop: '2rem',
		padding: '8px 15px',
		fontWeight: 600,
		fontSize: '1rem',
		fontFamily: poppins,
		letterSpacing: '0.45px',
		textAlign: 'center',
		textTransform: 'none'
	}
});

const TrackList: FunctionComponent = (): ReactElement => {
	const classes = useStyles();
	const stepperClasses = useStepperStyles();
	const { selection, selectTrack, selectCity } = useContext(SelectionContext);
	const [filter, setFilter] = useState<string>('');
	const [ageGroup, setAgeGroup] = useState<IAgeGroup | undefined>();
	const { data } = useContext(DataContext);
	const { user } = useContext(UserContext);

	const filteredTracks: ITrack[] = useMemo(() => {
		if (ageGroup) {
			return data.tracks.filter(
				(t: ITrack) =>
					t.name.toLowerCase().includes(filter.toLowerCase()) && t.age_group === ageGroup
			);
		}
		return data.tracks.filter((t: ITrack) =>
			t.name.toLowerCase().includes(filter.toLowerCase())
		);
	}, [filter, data.tracks, ageGroup]);

	const handleTrackSelected = useCallback(
		(event: React.MouseEvent<HTMLDivElement, MouseEvent>, track: ITrack) => {
			selectTrack(track);
		},
		[selectTrack]
	);

	const content = useCallback(
		(trackName: string) => {
			if (trackName === selection.selectedTrack?.name)
				return (
					<>
						<PlayArrow className={classes.selectedIcon} />
						{trackName}
					</>
				);
			return trackName;
		},
		[selection, classes.selectedIcon]
	);

	const status = useCallback(
		(progress: number): ReactElement => {
			if (progress === 0) return <div className={classes.status}>{'Nie rozpoczęta'}</div>;
			if (progress === 100)
				return <div className={classes.statusFinished}>{'Ukończono w 100%'}</div>;
			return <div className={classes.status}>{`Ukończono w ${progress}%`}</div>;
		},
		[classes.status, classes.statusFinished]
	);

	const isSelected = useCallback(
		(track: ITrack): boolean => {
			return selection.selectedTrack?.name === track.name;
		},
		[selection]
	);

	const getIcon = useCallback((waypoint: IWayPoint) => {
		if (waypoint.locked) return <img src={cityBlocked} alt={''} />;
		if (waypointStatsAsValue(waypoint) === 100) return <img src={cityDone} alt={''} />;
		return <img src={cityInProgress} alt={''} />;
	}, []);

	const handleSelectTrack = (track: ITrack) => {
		const waypoint: IWayPoint =
			track.waypoints.find((point: IWayPoint) => point.position === 0) || track.waypoints[0];
		selectCity(waypoint.city);
		selectTrack(track);
	};

	return (
		<>
			{user?.role === USER_ROLE.TEACHER && (
				<SelectInput
					name={'age'}
					id={'age'}
					value={ageGroup ? ageGroup : ''}
					className={classes.select}
					handleChange={(evt: ChangeEvent<HTMLInputElement>) => {
						setAgeGroup(evt.target.value as IAgeGroup);
					}}
					placeholder={'grupa wiekowa'}
					options={ageGroups.map((group: { v: string; n: string }) => {
						return (
							<MenuItem key={group.v} value={group.v}>
								{group.n}
							</MenuItem>
						);
					})}
				/>
			)}
			<TextField
				className={classes.searchInput}
				margin="normal"
				fullWidth
				id="search"
				placeholder="Wyszukaj trasę..."
				name="search"
				autoFocus
				value={filter}
				onChange={evt => setFilter(evt.target.value)}
				InputProps={{ classes: { root: classes.input } }}
			/>
			<List style={{ paddingTop: 0 }}>
				{filteredTracks.map((track: ITrack, index: number) => (
					<ListItem
						button
						key={index}
						selected={isSelected(track)}
						onClick={event => handleTrackSelected(event, track)}
						className={clsx(isSelected(track) && classes.trackItemSelected)}
						classes={{
							root: classes.listItem
						}}
					>
						<div
							className={clsx(
								classes.listItemText,
								isSelected(track) && classes.listItemSelected
							)}
						>
							{content(track.name)}
						</div>
						{user?.role === USER_ROLE.TEACHER && (
							<div className={classes.ageGroup}>
								{renderAgeGroup(track.age_group)}
							</div>
						)}
						<div>{status(track.percent_complete)}</div>

						{isSelected(track) && (
							<>
								<LinearProgress
									variant="determinate"
									value={selection.selectedTrack?.percent_complete}
									classes={{ root: classes.progressBar }}
								/>
								<div className={stepperClasses.stepper}>
									{track.waypoints &&
										track.waypoints.map(
											(waypoint: IWayPoint, index: number) => (
												<div key={index} className={stepperClasses.step}>
													<div className={stepperClasses.iconAndLine}>
														{getIcon(waypoint)}
														{track.waypoints[index + 1] && (
															<div
																className={clsx(
																	stepperClasses.line,
																	waypoint.locked &&
																		stepperClasses.lockedLine
																)}
															/>
														)}
													</div>
													<div className={stepperClasses.labels}>
														<div
															className={clsx(
																stepperClasses.cityName,
																waypoint.locked &&
																	stepperClasses.cityLocked
															)}
														>
															{waypoint.city.name}
														</div>
														<div
															className={clsx(
																stepperClasses.cityPoints,
																waypoint.locked &&
																	stepperClasses.cityLocked
															)}
														>
															{waypoint.waypoint_stats}
														</div>
													</div>
												</div>
											)
										)}
								</div>
								<Button
									key={track.name}
									component={RouterLink}
									to={ROUTES.track}
									className={stepperClasses.trackButton}
									color="primary"
									variant={'outlined'}
									onClick={() => handleSelectTrack(track)}
								>
									{track.waypoints.every(wp => waypointStatsAsValue(wp) === 0)
										? 'Rozpocznij trasę'
										: 'Kontynuuj trasę'}
									<ArrowForward style={{ fontSize: 12, marginLeft: '0.25rem' }} />
								</Button>
							</>
						)}
					</ListItem>
				))}
			</List>
		</>
	);
};

export default memo(TrackList);
