import { createContext } from 'react';
import { ISet, TEACHER_VIEW_MODE } from '../constants/types';

export const TeacherContext = createContext({} as ITeacherProvider);

export interface ITeacherContext {
	selectedSet?: ISet;
	viewMode: TEACHER_VIEW_MODE;
}

export interface ITeacherProvider {
	setsSelection: ITeacherContext;
	selectSet: (set: ISet) => void;
	unselectSet: () => void;
	setViewMode: (viewMode: TEACHER_VIEW_MODE) => void;
}
