import React, { FunctionComponent, memo, ReactElement, useContext, useState } from 'react';
import { useFormik } from 'formik';
import { MenuItem, TextField } from '@material-ui/core';
import { useStyles } from './Auth.style';
import { SubmitButton } from '../shared/buttons';
import { IUpdateProfilePayload, updateProfile } from '../../api';
import { AxiosResponse } from 'axios';
import { IUser, UserContext } from '../../context/UserContext';
import Loader from '../shared/Loader';
import { ageGroups, IAgeGroup, ICountry } from '../../constants/types';
import { TOKEN, USER } from '../../constants/session';
import SelectInput from '../shared/SelectInput';
import { ROUTES } from '../../routes/routes';
import { useHistory } from 'react-router-dom';
import useCountries from '../../hooks/useCountries';
import secondSignUpValidator from './secondSignUpValidator';

interface IProps {
	userData: IUser;
}

const SecondStepSignUpForm: FunctionComponent<IProps> = ({ userData }): ReactElement => {
	const classes = useStyles();
	const history = useHistory();
	const { setUser } = useContext(UserContext);
	const { countries, countriesLoading } = useCountries();
	const [error, setError] = useState<string | undefined>(undefined);

	const formik = useFormik({
		initialValues: {
			country: '',
			nickname: '',
			age_group: '' as IAgeGroup
		} as IUpdateProfilePayload,
		onSubmit: (values: IUpdateProfilePayload) => {
			setError(undefined);
			window.sessionStorage.setItem(TOKEN, userData.authentication_token);
			window.sessionStorage.setItem(USER, userData.email);
			updateProfile({ params: values })
				.then((resp: AxiosResponse<{ user: IUser }>) => {
					setUser(resp.data.user);
					history.replace(ROUTES.main);
				})
				.catch(e => {
					console.log('error', e.response);
					if (e.response.data.error) {
						setError(e.response.data.error);
					}
					if (e.response.data.errors) {
						setError(e.response.data.errors);
					}
				});
		},
		validationSchema: secondSignUpValidator()
	});

	if (countriesLoading) return <Loader />;
	return (
		<form className={classes.form} onSubmit={formik.handleSubmit}>
			{error && <div className={classes.error}>{error}</div>}
			<TextField
				variant="outlined"
				margin="normal"
				required
				fullWidth
				name="nickname"
				label="Nick"
				type="nickname"
				id="nickname"
				onChange={formik.handleChange}
				value={formik.values.nickname}
			/>
			<SelectInput
				name={'age_group'}
				id={'age_group'}
				value={formik.values.age_group}
				className={classes.select}
				customInputClass={classes.selectInput}
				handleChange={formik.handleChange}
				placeholder={'grupa wiekowa'}
				options={ageGroups.map((group: { v: string; n: string }) => {
					return (
						<MenuItem key={group.v} value={group.v}>
							{group.n}
						</MenuItem>
					);
				})}
			/>
			{Boolean(formik.errors.age_group && formik.touched.age_group) && (
				<div className={classes.error}>{formik.errors.age_group}</div>
			)}
			<SelectInput
				name={'country'}
				id={'country'}
				value={formik.values.country}
				className={classes.select}
				customInputClass={classes.selectInput}
				handleChange={formik.handleChange}
				placeholder={'kraj'}
				options={countries.map((country: ICountry) => {
					return (
						<MenuItem key={country.code} value={country.name_pl}>
							{country.name_pl}
						</MenuItem>
					);
				})}
			/>
			{Boolean(formik.errors.country && formik.touched.country) && (
				<div className={classes.error}>{formik.errors.country}</div>
			)}

			<SubmitButton text={'Zarejestruj się'} />
		</form>
	);
};

export default memo(SecondStepSignUpForm);
