import React, { FunctionComponent, memo, ReactElement } from 'react';
import clsx from 'clsx';
import { IPairCard } from './Pairs';
import { makeStyles } from '@material-ui/core';
import { border, failRed, successGreen, white } from '../../../../styles/variables';
import { CARD_SIZE } from './Pairs.style';

const useStyles = makeStyles(() => ({
	image: {
		cursor: 'pointer',
		width: CARD_SIZE,
		height: CARD_SIZE,
		borderRadius: '8px',
		boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.33)',
		boxSizing: 'border-box'
	},
	text: {
		cursor: 'pointer',
		width: CARD_SIZE,
		height: CARD_SIZE,
		borderRadius: '8px',
		boxShadow: '0 2px 15px 0 rgba(0, 0, 0, 0.33)',
		backgroundImage: 'linear-gradient(to bottom, #1e333f 2%, #07212d 92%)',
		color: white,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		boxSizing: 'border-box'
	},
	correct: {
		border: border(successGreen, '3px')
	},
	incorrect: {
		border: border(failRed, '3px')
	}
}));

interface IProps {
	card: IPairCard;
	showResult: boolean;
	isCorrect: boolean;
}

const PairCard: FunctionComponent<IProps> = ({ card, showResult, isCorrect }): ReactElement => {
	const classes = useStyles();

	return (
		<img
			className={clsx(
				classes.image,
				showResult && isCorrect && classes.correct,
				showResult && !isCorrect && classes.incorrect
			)}
			src={card.value}
			alt={`ans-${card.value}`}
		/>
	);
};

export default memo(PairCard);
