import React, { FunctionComponent, memo, ReactElement, useContext, useState } from 'react';
import { useFormik } from 'formik';
import { SubmitButton } from '../shared/buttons';
import { useStyles } from './Auth.style';
import { facebookLogin, IRegisterPayload, register } from '../../api';
import { AxiosResponse } from 'axios';
import { IUser, UserContext } from '../../context/UserContext';
import SecondStepSignUpForm from './SecondStepSignUpForm';
import studentSignUpValidator from './studentSignUpValidator';
import TextInput from '../shared/TextInput';
import { Button, Checkbox } from '@material-ui/core';
// @ts-ignore
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { IFacebookResponse, TOKEN, USER } from '../../constants/session';
import { ROUTES } from '../../routes/routes';
import { useHistory } from 'react-router-dom';
import { Facebook } from '@material-ui/icons';
import { mainRed } from '../../styles/variables';
import { openInNewTab } from '../../utils/openInNewTab';

const StudentSignUpForm: FunctionComponent = (): ReactElement => {
	const { setUser } = useContext(UserContext);
	const [secondStepDisplayed, setSecondStepDisplayed] = useState<boolean>(false);
	const [userData, setUserData] = useState<IUser | undefined>(undefined);
	const [error, setError] = useState<string | undefined>(undefined);
	const [orpeg, setOrpeg] = useState<boolean>(false);
	const classes = useStyles();
	const history = useHistory();

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			password_confirmation: ''
		} as IRegisterPayload,
		onSubmit: (values: IRegisterPayload) => {
			setError(undefined);
			register({ params: values })
				.then((resp: AxiosResponse<{ user: IUser }>) => {
					setUserData(resp.data.user);
					setSecondStepDisplayed(true);
				})
				.catch(e => {
					console.log('error', e.response);
					setError(e.response.data.errors);
				});
		},
		validationSchema: studentSignUpValidator()
	});

	const handleToggleCheckbox = () => {
		setOrpeg(prev => !prev);
	};

	const responseFacebook = (response: IFacebookResponse) => {
		if (response.accessToken) {
			facebookLogin({ params: { token: response.accessToken } })
				.then((resp: AxiosResponse<{ user: IUser }>) => {
					if (resp.data.user.age_group && resp.data.user.country) {
						setUser(resp.data.user);
						window.sessionStorage.setItem(TOKEN, resp.data.user.authentication_token);
						window.sessionStorage.setItem(USER, resp.data.user.email);
						history.replace(ROUTES.main);
					} else {
						setUserData(resp.data.user);
						setSecondStepDisplayed(true);
					}
				})
				.catch(e => {
					console.log('error', e.response);
					if (e.response.data.error) {
						setError(e.response.data.error);
					}
					if (e.response.data.errors) {
						setError(e.response.data.errors);
					}
				});
		}
	};

	if (secondStepDisplayed) return <SecondStepSignUpForm userData={userData as IUser} />;
	return (
		<form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
			{error && <div className={classes.error}>{error}</div>}
			<TextInput
				label="E-mail"
				name="email"
				onChange={formik.handleChange}
				value={formik.values.email}
				touched={formik.touched.email}
				error={formik.errors.email}
			/>
			<TextInput
				label="Hasło"
				name="password"
				type="password"
				onChange={formik.handleChange}
				value={formik.values.password}
				touched={formik.touched.password}
				error={formik.errors.password}
			/>
			<TextInput
				label="Powtórz Hasło"
				name="password_confirmation"
				type="password"
				onChange={formik.handleChange}
				value={formik.values.password_confirmation}
				touched={formik.touched.password_confirmation}
				error={formik.errors.password_confirmation}
			/>
			<div className={classes.orpeg}>
				<Checkbox checked={orpeg} onChange={handleToggleCheckbox} color="primary" />
				<div
					className={classes.orpegInfo}
					onClick={() =>
						openInNewTab('https://www.orpeg.pl/o-nas/ochrona-danych-osobowych/')
					}
				>
					Oświadczam, że zapoznałem się z informacją o administratorze oraz zasadach
					przetwarzania danych osobowych
				</div>
			</div>
			<SubmitButton text={'Zarejestruj się'} disabled={!orpeg} />
			<FacebookLogin
				appId={process.env.REACT_APP_FB}
				callback={responseFacebook}
				render={(renderProps: any) => (
					<Button
						fullWidth
						variant="outlined"
						className={classes.social}
						onClick={renderProps.onClick}
					>
						<Facebook
							style={{
								color: mainRed,
								fontSize: 16,
								marginRight: '0.25rem'
							}}
						/>{' '}
						Facebook
					</Button>
				)}
			/>
		</form>
	);
};

export default memo(StudentSignUpForm);
