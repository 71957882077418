import { Button, makeStyles } from '@material-ui/core';
import { fontGrey75, lightBlack, poppins, roboto, white } from '../../styles/variables';
import React, { FunctionComponent, memo, ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTES } from '../../routes/routes';
import useUpdate from '../../hooks/useUpdate';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		marginTop: '5.4rem'
	},
	title: {
		fontFamily: poppins,
		fontSize: '2rem',
		fontWeight: 'bold',
		letterSpacing: '0.9px',
		color: lightBlack
	},
	text: {
		marginTop: '1.5rem',
		fontFamily: roboto,
		fontSize: '1.25rem',
		lineHeight: '1.43',
		letterSpacing: '0.52px',
		color: fontGrey75,
		width: '25rem',
		textAlign: 'center'
	},
	points: {
		fontFamily: poppins,
		fontSize: '2.6rem',
		fontWeight: 'bold',
		letterSpacing: '1.2px',
		color: lightBlack
	},
	button: {
		fontFamily: poppins,
		fontSize: '1rem',
		textTransform: 'unset',
		marginTop: '2rem',
		color: white,
		letterSpacing: '0.45px',
		fontWeight: 600,
		width: '20rem',
		height: '3.6rem'
	}
}));

interface IProps {
	gamePoints: number;
}

const Result: FunctionComponent<IProps> = ({ gamePoints }): ReactElement => {
	const classes = useStyles();
	const { updateAll } = useUpdate();

	return (
		<div className={classes.root}>
			<div className={classes.title}>Gra ukończona!</div>
			<div className={classes.text}>Zdobyte punkty</div>
			<div className={classes.points}>{gamePoints}</div>
			<Button
				component={RouterLink}
				to={ROUTES.track}
				variant="contained"
				className={classes.button}
				color={'primary'}
				onClick={updateAll}
			>
				Wróć do miasta
			</Button>
		</div>
	);
};

export default memo(Result);
