import { makeStyles } from '@material-ui/core';
import {
	fontGrey75,
	mainRed,
	NAVBAR_HEIGHT,
	poppins,
	lightBlack,
	white,
	HEADER_PADDING
} from '../../styles/variables';

export const useStyles = makeStyles(() => ({
	appBar: {
		backgroundColor: white,
		paddingLeft: HEADER_PADDING,
		paddingRight: HEADER_PADDING,
		boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.07)'
	},
	toolbar: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',
		alignItems: 'center',
		height: NAVBAR_HEIGHT,
		padding: 0
	},
	logo: {
		color: mainRed,
		height: '4rem',
		'&:hover': {
			backgroundColor: white
		}
	},
	sides: {
		width: '18rem',
		display: 'flex'
	},
	left: {
		justifyContent: 'flex-start'
	},
	right: {
		justifyContent: 'flex-end'
	},
	navLink: {
		color: fontGrey75,
		fontFamily: poppins,
		margin: '1rem 1.5rem',
		textTransform: 'unset',
		textDecoration: 'none',
		fontWeight: 600
	},
	loginButton: {
		display: 'flex',
		alignItems: 'center',
		fontFamily: poppins,
		padding: '1.5rem 2.5rem',
		color: mainRed,
		borderColor: mainRed,
		textTransform: 'unset',
		letterSpacing: '0.45px',
		fontWeight: 600,
		fontSize: '1rem'
	},
	logoutButton: {
		fontFamily: poppins,
		fontSize: '0.75rem',
		fontWeight: 600,
		letterSpacing: '0.40px',
		color: mainRed,
		textTransform: 'capitalize'
	},
	profile: {
		fontFamily: poppins,
		fontSize: '1rem',
		fontWeight: 'bold',
		letterSpacing: '0.45px',
		color: lightBlack,
		textTransform: 'unset'
	},
	user: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		fontFamily: poppins,
		fontSize: '1rem',
		fontWeight: 'bold',
		letterSpacing: '0.45px',
		color: lightBlack
	}
}));
