import React, {
	FunctionComponent,
	memo,
	ReactElement,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { IImageFillGameData } from '../../../../constants/types';
import {
	border,
	failRed,
	fontGrey,
	fontGrey10,
	fontGrey75,
	lightBlack,
	mainRed,
	mainRed50,
	poppins,
	roboto,
	successGreen,
	white
} from '../../../../styles/variables';
import { shuffle } from '../utils';
import clsx from 'clsx';
import produce from 'immer';

interface IProps {
	gameData: IImageFillGameData;
	gameId: number;
	finishGame: (gameId: number, points: number) => void;
}

const IMG_SIZE = '80px';

const useStyles = makeStyles(() => ({
	root: {
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '1rem',
		flexDirection: 'column'
	},
	counter: {
		fontFamily: poppins,
		fontSize: '2rem',
		fontWeight: 'bold',
		lineHeight: '1.25',
		letterSpacing: '1.2px',
		color: lightBlack,
		paddingBottom: '1rem'
	},
	text: {
		display: 'flex',
		justifyContent: 'center',
		flexWrap: 'wrap',
		fontFamily: poppins,
		fontSize: '1.25rem',
		fontWeight: 'bold',
		maxWidth: '60rem',
		paddingTop: '1rem',
		paddingBottom: '1rem',
		alignItems: 'flex-end'
	},
	part: {
		height: IMG_SIZE,
		display: 'flex'
	},
	partText: {
		display: 'inline-block',
		alignSelf: 'center'
	},
	allImages: {
		borderRadius: '5px',
		border: border(fontGrey10),
		padding: '1rem',
		display: 'flex',
		justifyContent: 'center',
		maxWidth: '90%',
		flexWrap: 'wrap'
	},
	image: {
		width: IMG_SIZE,
		height: IMG_SIZE,
		boxSizing: 'border-box',
		margin: '0.5rem',
		borderRadius: '1rem'
	},
	button: {
		marginTop: '2rem',
		width: '21rem',
		height: '3rem',
		fontFamily: poppins,
		fontWeight: 600,
		letterSpacing: '0.45px',
		color: white,
		backgroundColor: mainRed,
		'&:hover': {
			backgroundColor: mainRed50,
			color: white
		}
	},
	instruction: {
		color: fontGrey,
		fontSize: '1.2rem',
		fontFamily: roboto
	},
	imageInput: {
		width: IMG_SIZE,
		height: IMG_SIZE,
		boxSizing: 'border-box',
		margin: '0.5rem',
		backgroundColor: white,
		border: border(fontGrey75),
		borderRadius: '1rem'
	},
	correctAnswer: {
		marginLeft: '0.5rem',
		border: border(successGreen, '3px')
	},
	selectedImage: {
		border: border(fontGrey75, '3px')
	},
	correctImage: {
		border: border(successGreen, '3px')
	},
	failedImage: {
		border: border(failRed, '3px')
	}
}));

const ImageFill: FunctionComponent<IProps> = ({ gameData, gameId, finishGame }): ReactElement => {
	const classes = useStyles();
	const [points, setPoints] = useState<number>(0);
	const [finishedGame, setFinishedGame] = useState<boolean>(false);
	const [showResults, setShowResults] = useState<boolean>(false);
	const [selectedDrop, setSelectedDrop] = useState<number | undefined>();
	const [selectedImage, setSelectedImage] = useState<string | undefined>();
	const [resultImages, setResultImages] = useState<string[]>([]);
	const [shuffledImages, setShuffledImages] = useState<string[]>(
		shuffle([...gameData.all_images]) as string[]
	);

	const putBackToImages = (img: string) => {
		if (!showResults) {
			setResultImages((prevState: string[]) =>
				produce(prevState, draft => {
					const index = draft.findIndex((v: string) => v === img);
					draft[index] = '';
					return draft;
				})
			);
			setShuffledImages((prevState: string[]) => [...prevState, img]);
		}
	};

	const checkSelectedAnswer = () => {
		for (let i = 0; i < gameData.answer_images.length; i++) {
			if (resultImages[i] === gameData.answer_images[i]) {
				setPoints(prev => prev + 1);
			}
		}
		setShowResults(true);
		setFinishedGame(true);
	};

	const selectImage = (img: string) => {
		if (selectedImage === img) {
			setSelectedImage(undefined);
		} else {
			setSelectedImage(img);
		}
	};

	const selectDrop = (i: number) => {
		if (selectedDrop === i) {
			setSelectedDrop(undefined);
		} else {
			setSelectedDrop(i);
		}
	};

	useEffect(() => {
		if (selectedImage && selectedDrop !== undefined) {
			setShuffledImages((prevState: string[]) =>
				produce(prevState, draft => {
					const index = draft.findIndex((v: string) => v === selectedImage);
					draft.splice(index, 1);
					return draft;
				})
			);
			setResultImages((prevState: string[]) =>
				produce(prevState, draft => {
					draft[selectedDrop] = selectedImage;
					return draft;
				})
			);
			setSelectedImage(undefined);
			setSelectedDrop(undefined);
		}
	}, [selectedImage, selectedDrop]);

	const handleGameFinish = useCallback(() => {
		finishGame(gameId, points);
	}, [finishGame, points, gameId]);

	const selectedEveryImage = useMemo(() => {
		return Object.values(resultImages).length === gameData.answer_images.length;
	}, [gameData.answer_images.length, resultImages]);

	return (
		<div className={classes.root}>
			<div className={classes.instruction}>Uzupełnij tekst obrazkami</div>
			<div className={classes.text}>
				{gameData.text.split('$$$$$').map((part: string, idx: number) => (
					<React.Fragment key={idx}>
						<div className={classes.part}>
							<div className={classes.partText}>{part}</div>
						</div>
						{idx < gameData.answer_images.length && (
							<>
								{resultImages[idx] ? (
									<>
										<img
											className={clsx(
												classes.image,
												showResults &&
													resultImages[idx] ===
														gameData.answer_images[idx] &&
													classes.correctImage,
												showResults &&
													resultImages[idx] !==
														gameData.answer_images[idx] &&
													classes.failedImage
											)}
											src={resultImages[idx]}
											alt={`${idx}`}
											onClick={() => putBackToImages(resultImages[idx])}
										/>
										<>
											{showResults &&
												resultImages[idx] !==
													gameData.answer_images[idx] && (
													<img
														className={clsx(
															classes.image,
															classes.correctAnswer
														)}
														src={gameData.answer_images[idx]}
														alt={`${idx}`}
													/>
												)}
										</>
									</>
								) : (
									<div
										className={clsx(
											classes.imageInput,
											selectedDrop === idx && classes.selectedImage
										)}
										onClick={() => selectDrop(idx)}
									/>
								)}
							</>
						)}
					</React.Fragment>
				))}
			</div>
			<div className={classes.allImages}>
				{shuffledImages.map((img: string, i: number) => (
					<img
						key={i}
						className={clsx(
							classes.image,
							selectedImage === img && classes.selectedImage
						)}
						src={img}
						alt={`${i}`}
						onClick={() => selectImage(img)}
					/>
				))}
			</div>
			{finishedGame && (
				<Button
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={handleGameFinish}
				>
					{'Zakończ'}
				</Button>
			)}
			{!finishedGame && (
				<Button
					disabled={!selectedEveryImage}
					type="button"
					fullWidth
					variant="outlined"
					className={classes.button}
					onClick={checkSelectedAnswer}
				>
					{'Sprawdź'}
				</Button>
			)}
		</div>
	);
};

export default memo(ImageFill);
