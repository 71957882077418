import { createContext } from 'react';
import { ICity, ITrack } from '../constants/types';

export const DataContext = createContext({} as IDataProvider);

export interface IDataContext {
	cities: ICity[];
	tracks: ITrack[];
}

export interface IDataProvider {
	data: IDataContext;
	updateCities: (cities: ICity[]) => void;
	updateTracks: (tracks: ITrack[]) => void;
}
