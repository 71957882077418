import React, { memo } from 'react';
import { ROUTES } from '../../routes/routes';
import { RedirectButton } from '../shared/tooltip';
import { IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { fontGrey, fontGrey75, mainRed, poppins, roboto, white } from '../../styles/variables';
import { GAME, IPoi } from '../../constants/types';
import { speakText } from '../../utils/speak';
import { VolumeUpOutlined } from '@material-ui/icons';
import clsx from 'clsx';

export const TOOLTIP_HEIGHT = 216;

const useStyles = makeStyles({
	root: {
		zIndex: 12,
		backgroundColor: white,
		width: '42rem',
		height: TOOLTIP_HEIGHT,
		borderRadius: '5px',
		position: 'absolute',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingLeft: '2rem',
		paddingRight: '2rem',
		boxShadow: '0 10px 20px 0 rgba(0, 0, 0, 0.24)',
		top: (props: { top: number; left: number }) => props.top,
		left: (props: { top: number; left: number }) => props.left
	},
	toTop: {
		'&::after': {
			top: '100%',
			left: '50%',
			content: "' '",
			height: '0',
			width: '0',
			position: 'absolute',
			display: 'block',
			pointerEvents: 'none',
			border: '6px solid transparent',
			borderBottom: 0,
			borderTop: '10px solid #ffffff'
		}
	},
	toBottom: {
		'&::after': {
			top: '-10px',
			left: '50%',
			content: "' '",
			height: '0',
			width: '0',
			position: 'absolute',
			display: 'block',
			pointerEvents: 'none',
			border: '6px solid transparent',
			borderTop: 0,
			borderBottom: '10px solid #ffffff'
		}
	},
	content: {
		marginTop: '1rem',
		marginBottom: '0.5rem',
		opacity: 0.5,
		fontFamily: roboto,
		fontSize: '1rem',
		lineHeight: 1.33,
		letterSpacing: '0.45px',
		textAlign: 'center',
		color: fontGrey
	},
	header: {
		height: '1.25rem',
		fontFamily: poppins,
		fontSize: '1.25rem',
		fontWeight: 'bold',
		letterSpacing: '0.6px',
		color: fontGrey,
		marginTop: '20px',
		display: 'flex',
		alignItems: 'center'
	},
	scoreContainer: {
		display: 'flex',
		marginTop: '0.25rem',
		marginBottom: '1rem',
		alignItems: 'center'
	},
	score: {
		fontFamily: poppins,
		fontSize: '1.25rem',
		fontWeight: 'bold',
		letterSpacing: '0.6px',
		color: fontGrey
	},
	scoreLabel: {
		marginLeft: '1rem',
		fontFamily: poppins,
		fontSize: '1rem',
		fontWeight: 600,
		letterSpacing: '0.45px',
		color: fontGrey75
	},
	placeholder: {
		height: '25px'
	},
	soundButton: {
		color: mainRed,
		padding: '2px',
		marginLeft: '4px'
	},
	playButton: {
		marginBottom: 0
	},
	tooltip: {
		fontSize: '1rem'
	}
});

interface IProps {
	top: number;
	left: number;
	header: string;
	content: string;
	poi: IPoi;
	toBottom: boolean;
}

const TrackCityTooltip: React.FunctionComponent<IProps> = ({
	top,
	left,
	header,
	content,
	poi,
	toBottom
}) => {
	const classes = useStyles({ top, left });
	return (
		<div className={clsx(classes.root, toBottom ? classes.toBottom : classes.toTop)}>
			<div className={classes.header}>
				{header}
				<IconButton className={classes.soundButton} onClick={() => speakText(content)}>
					<VolumeUpOutlined />
				</IconButton>
			</div>
			<div className={classes.content}>{content}</div>
			{poi.game.game_type === GAME.EMPTY ? (
				<div className={classes.placeholder} />
			) : (
				<>
					{poi.game.already_played ? (
						<div className={classes.scoreContainer}>
							<div className={classes.score}>{poi.game.user_points} pkt</div>
							<div className={classes.scoreLabel}>Twój wynik gry</div>
							<Tooltip
								title={
									'Gra już została rozegrana, punkty z obecnej rozgrywki nie zapiszą się w rankingu.'
								}
								enterDelay={100}
								placement={'bottom'}
								classes={{ tooltip: classes.tooltip }}
							>
								<div>
									<RedirectButton
										text={'Zagraj'}
										to={ROUTES.game}
										className={classes.playButton}
									/>
								</div>
							</Tooltip>
						</div>
					) : (
						<RedirectButton text={'Zagraj'} to={ROUTES.game} />
					)}
				</>
			)}
		</div>
	);
};

export default memo(TrackCityTooltip);
